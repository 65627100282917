export default function getProfessionsFilter(data) {
  const result = data?.filter(
    professions =>
      professions?.attributes?.profession !== "psychiatrist" &&
      professions?.attributes?.profession !== "neuropediatrician" &&
      professions?.attributes?.profession !== "psychopedagogue" &&
      professions?.attributes?.profession !== "professor",
  );
  return result;
}

export function getProfessions(data) {
  const result = data?.filter(
    professions =>
      professions?.name !== "psychiatrist" &&
      professions?.name !== "neuropediatrician" &&
      professions?.name !== "psychopedagogue" &&
      professions?.name !== "professor",
  );
  return result;
}
