/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import api from '../services/api';

export default function useAxiosGet() {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const controller = new AbortController();

  const fetchData = async (url, options) => {
    setErrors(null);
    try {
      const { data } = await api.get(url, {
        ...options,
        signal: controller.signal,
      });
      if (data.data) setData(data.data);
      else throw new Error('Não foi possível carregar os dados');
    } catch (error) {
      if (!controller.signal.aborted) {
        setErrors(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    controller,
    data,
    errors,
    fetchData,
    loading,
  };
}
