import styled from "styled-components";
import { BREAKPOINT_LG_PX } from "../../../styles/globalStyles";

export const Container = styled.div`
  display: flex;
  width: 500px;
  padding: 50px 0;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    width: 250px;
  }

  .-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
    color: ${({ theme }) => theme.secundary};
  }
`;
