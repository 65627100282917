import { Maximize2 } from 'react-feather';
import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import { ReactComponent as Empty } from '../../../images/diary-empty.svg';
import {
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ModalBoxWrapper,
  ScrollCardStyle,
  SmallFont,
} from '../../../styles/globalStyles';

export const ListContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const MessageContainer = styled.section`
  align-items: center;
  align-self: flex-start;
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.color10};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  min-height: 70vh;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    min-height: 450px;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    min-height: 350px;
  }
`;

export const WrapperDiary = styled.section`
  align-items: flex-start;
  column-gap: 3rem;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const EmptyImage = styled(Empty)`
  height: 60%;
  padding: 2rem 0;
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyTitle = styled(BigFont)`
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;

export const EmptyText = styled(SmallFont)`
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.darkGray};
`;

export const ButtonWrapper = styled.button`
  display: flex;
  width: 100%;
  height: 3rem;
  border-radius: 2rem;
  font-weight: 700;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  margin: 1rem 0;
`;

// ELETRONIC DIARY

export const EletronicContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  width: 100%;
`;

export const DiaryContainerScreen = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  width: 100%;
  height: 100%;
`;

export const DiaryHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
`;

export const HeaderColumn = styled.section`
  display: flex;
  padding: 10px 0;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const DiaryButton = styled.button`
  flex: 1;
  width: 50%;
  display: flex;
  border: none;
  padding: 10px 0;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
`;
export const DiaryDate = styled(SmallFont)`
  color: ${({ theme }) => theme.darkGray};
`;

export const DiaryBody = styled.section`
  flex: 1;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const InputContainer = styled.section`
  flex: 0.1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const NotesContainer = styled(ScrollCardStyle)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: flex-start;
  overflow-y: scroll;
  padding-top: 1rem;
  padding-right: 1rem;
  width: 100%;
`;

export const NotesText = styled(SmallFont)`
  max-width: 400px;
  max-height: 250px;
  overflow-y: scroll;
  white-space: pre-line;
  word-wrap: break-word;
  color: ${({ theme }) => theme.darkGray};
`;

export const ButtonContainer = styled.section`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
  padding: 15px 20px;
  width: 100%;
`;
export const CancelButton = styled(SmallButton)`
  height: 40px;
  width: 48%;
  border: none;
  border-radius: 10px;
`;

export const ContinueButton = styled(SmallButton)`
  height: 40px;
  width: 48%;
  border: none;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.offWhite};
`;

export const MaximizeIcon = styled(Maximize2)`
  color: ${({ theme }) => theme.primary};
`;

export const ModalContainer = styled(ModalBoxWrapper)`
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;
