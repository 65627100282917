import { toast } from "react-toastify";
import api from "../services/api";

export const getToken = async user => {
  try {
    const { data } = await api.get(
      `/${user?.type}s/schedules/generate_video_sdk_token`,
    );
    if (data?.data) {
      return data?.data.attributes.videoSdkToken;
    }
  } catch (error) {
    toast.error("Erro ao carregar token da reunião");
  }
};
