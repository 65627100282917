import styled from 'styled-components';
import { BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const MainWrapper = styled.section`
  width: 100%;
`;

export const SubscribeForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SubscribeCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 1rem;
    width: 100%;
  }
`;
