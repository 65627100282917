import React from 'react';
import { useHistory } from 'react-router-dom';
import { StaticButton } from '../../../../components/Button';
import { BigHeaderElement, CheckIcon, Container, Main } from './styles';

export default function SuccessPaymentScreen() {
  const history = useHistory();

  return (
    <Main>
      <BigHeaderElement>
        <Container>
          <CheckIcon />
          <h1 className="--title">Pedido Enviado</h1>
        </Container>
      </BigHeaderElement>
      <Container>
        <h1 className="-text">
          O especialista responderá a sua solicitação até 1 dia útil.
        </h1>
        <StaticButton
          title="OK"
          onClick={() => history.push('/client/dashboard')}
        />
      </Container>
    </Main>
  );
}
