import styled from "styled-components";
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  SmallFont,
} from "../../styles/globalStyles";

export const Main = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerPlans = styled.div`
  align-self: center;
  background-color: ${({ theme, active }) =>
    active === "true" ? theme.primary_blue : theme.newPrimary};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  height: ${({ clinic }) => (clinic ? "20rem" : "12rem")};
  padding: 1rem 0;
  width: 100%;
`;

export const UserImage = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${({ search }) => (search ? "1rem" : "50%")};
  cursor: pointer;
  height: ${({ size }) => (size === "large" ? "150px" : "100px")};
  overflow: hidden;
  width: ${({ size }) => (size === "large" ? "150px" : "100px")};
`;

export const ColumnItem = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
  }
`;

export const BFontBold = styled(BigFont)`
  font-size: 1.5rem;
  max-width: 300px;
  color: ${({ theme }) => theme.secondary};

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.8rem;
  }
`;

export const MdFontBold = styled(BFontBold)`
  font-size: 1.2rem;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.8rem;
  }
`;

export const SFontDark = styled(SmallFont)`
  color: ${({ theme, date }) => (date ? theme.primary_blue : theme.black)};
  font-weight: ${({ date }) => (date ? "900" : "normal")};
  font-size: ${({ date }) => date && "0.7rem"};
  max-width: 300px;
  overflow-y: hidden;
  white-space: pre-line;
  word-wrap: break-word;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.6rem;
    padding: 0 1rem;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme, active }) =>
    active === "true" ? theme.primary : theme.white};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme, active }) =>
    active === "true" ? theme.white : theme.primary};
  cursor: pointer;
  font-weight: 700;
  height: 2.5rem;
  width: 80%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      active === "true" ? theme.primary : theme.newPrimaryAlt};
    color: ${({ theme, active }) =>
      active === "true" ? theme.white : theme.secondary};
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 0.6rem;
    padding: 0 1rem;
  }
`;

export const PlanDetails = styled.div`
  align-items: center;
  background-color: ${({ theme, active }) =>
    active === "true" ? theme.primary_blue : theme.newPrimary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: center;
  margin-left: 1rem;
  padding: 1rem 0;
  width: 40%;
`;
