/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Typography } from "@material-ui/core";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FooterButtons } from "../../../components/FooterButtons";
import FormikControl from "../../../components/FormikControl";
import { InputWrapper } from "../../../components/FormikElements/styles";
import ImagePicker, { NewImageBanner } from "../../../components/ImageInput";
import {
  CheckCircleIcon,
  CropButton,
} from "../../../components/ImageInput/styles";
import { NewSelect } from "../../../components/SelectList";
import ImageCamera from "../../../images/icon-cam.svg";
import BannerClinc from "../../../images/banner-clinic.png";
import api from "../../../services/api";
import { PtBr } from "../../../services/pt_br";
import { ModalBoxWrapper, TextError } from "../../../styles/globalStyles";
import theme from "../../../theme";
import handleErrors from "../../../utils/getValuesErrors";
import { getPreferencialPayments } from "../../../utils/preferencialPayments";
import {
  ConfigWrapper,
  RowLine,
  Titleh1,
} from "../../Professional/Config/styles";
import {
  LoginLink,
  LoginParagraph,
  LoginTitle,
} from "../../Public/Login/styles";
import { FieldLabel, ImagePickerContainer, ImagePickerWrapper } from "./styles";

const basicRegistrationValues = [
  "confirm_password",
  "documentNumber",
  "email",
  "name",
  "password",
  "phone",
];

const typesReceipts = [
  "externalPayment",
  "postPaid",
  "postPaidDay",
  "prePaid",
  "preferentialPayment",
];

export default function BasicRegistrationClinicScreen({ form, next }) {
  const { values, errors, touched, handleChange, handleBlur, validateForm } =
    form;
  const history = useHistory();
  const [documentNumberType, setDocumentNumberType] = useState("CNPJ");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const cropperRef = useRef(null);

  const handleCrop = () => {
    if (cropperRef.current) {
      const imageElement = cropperRef?.current?.cropper;
      const canvas = imageElement.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(blob => {
          const file = new File([blob], "image.png", {
            type: "image/png",
            lastModified: Date.now(),
          });
          storeImageAsBase64(file, "avatar");
          setUploadedImage(null);
          setIsModalOpen(false);
        }, "image/png");
      }
    }
  };

  useEffect(() => {
    validateForm();
  }, []);

  const storeImageAsBase64 = (file, fieldName) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue(fieldName, reader.result);
    };
    reader.readAsDataURL(file);
  };

  const checkEmail = async email => {
    try {
      await api.post("/clinics/check_email", {
        email: email,
      });
      return true;
    } catch (error) {
      if (error.response) {
        if (error.response.data.available === false) {
          toast.error("Email já está em uso!");
        }
      } else {
        toast.error(
          "Ocorreu um erro no formulário, verifique os dados inseridos!",
        );
      }
      return false;
    }
  };

  const handleContinue = async () => {
    if (!handleErrors(errors, basicRegistrationValues)) {
      const validEmail = await checkEmail(values.email);
      if (validEmail) {
        next();
      } else
        toast.error(
          "Ocorreu um erro no formulário, verifique os dados inseridos!",
        );
    }
  };

  return (
    <InputWrapper>
      <LoginTitle>Dados Básicos</LoginTitle>
      <ImagePickerWrapper>
        <ImagePickerContainer>
          <FieldLabel htmlFor="avatar" style={{ marginBottom: "0" }}>
            {`Logomarca (Opcional) `}
            <Tooltip title="Banner e logomarca poderão ser adicionados após o cadastro. Recomendamos que preencham esses campos para ter um perfil mais atraente para clientes">
              <span style={{ color: theme.pink }}>?</span>
            </Tooltip>
          </FieldLabel>
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "400",
              marginBottom: "1rem",
            }}
          >
            Recomendado 150px x 150px
          </span>
          <ImagePicker
            size="large"
            id="avatar"
            src={values.avatar || ImageCamera}
            name="avatar"
            onChange={({ target }) => {
              const fileInput = target;
              const file = fileInput.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = e => {
                  setUploadedImage(e.target.result);
                };
                reader.readAsDataURL(file);
                setIsModalOpen(true);
                fileInput.value = "";
              }
            }}
          />
          <TextError>{errors.avatar}</TextError>
        </ImagePickerContainer>

        <ImagePickerContainer>
          <FieldLabel htmlFor="banner" style={{ marginBottom: "0" }}>
            {`Banner (Opcional) `}
            <Tooltip title="Banner e logomarca poderão ser adicionados após o cadastro. Recomendamos que preencham esses campos para ter um perfil mais atraente para clientes">
              <span style={{ color: theme.pink }}>?</span>
            </Tooltip>
          </FieldLabel>
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "400",
              marginBottom: "1rem",
            }}
          >
            Recomendado 1366px x 170px (Tamanho máximo: 2MB)
          </span>
          <NewImageBanner
            id="banner"
            src={values.banner || BannerClinc}
            name="banner"
            onChange={({ target }) => {
              const file = target.files[0];
              const maxSize = 2 * 1024 * 1024;
              if (file && file.size > maxSize) {
                toast.error(
                  "Tamanho máximo permitido: 2MB! Verifique o arquivo selecionado para que seja possível realizar o upload.",
                );
                return;
              }
              storeImageAsBase64(file, "banner");
            }}
          />
          <TextError>{errors.banner}</TextError>
        </ImagePickerContainer>
      </ImagePickerWrapper>

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.name}
        label={PtBr.register_input_firstname}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.name}
        upLabel
        value={values.name}
      />

      <FormikControl
        bgColor="#fff"
        control="textarea"
        errors={errors.description}
        label="Descrição da clínica"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="description"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.description}
        upLabel
        value={values.description}
      />

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.email}
        label={PtBr.register_input_email}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.email}
        upLabel
        value={values.email}
      />

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.phone}
        label="Número de telefone"
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        mask="(99)99999-9999"
        name="phone"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.phone}
        upLabel
        value={values.phone}
      />

      <div
        style={{
          margin: "0.5rem 0",
          width: "100%",
        }}
      >
        <FormikControl
          bgColor="#fff"
          control="select"
          label="Tipo de documento"
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          name="documentNumberType"
          options={["CNPJ", "CPF"]}
          onChange={({ target }) => {
            setDocumentNumberType(target.value);
          }}
          upLabel
          value={values.documentNumberType}
          defaultValue="CNPJ"
        />
      </div>

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.documentNumber}
        label={documentNumberType === "CNPJ" ? "CNPJ" : "CPF"}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        mask={
          documentNumberType === "CNPJ"
            ? "99.999.999/9999-99"
            : "999.999.999-99"
        }
        name="documentNumber"
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.documentNumber}
        upLabel
        value={values.documentNumber}
      />

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.password}
        label={PtBr.register_input_password}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="password"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.password}
        type="password"
        upLabel
        value={values.password}
      />

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.confirm_password}
        label={PtBr.register_input_confirm_password}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="confirm_password"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.confirm_password}
        type="password"
        upLabel
        value={values.confirm_password}
      />

      <FooterButtons disableGoBack={true} next={handleContinue} />

      <LoginParagraph style={{ marginTop: "2rem" }}>
        Já possui uma conta?{" "}
        <LoginLink
          type="button"
          onClick={() => history.push("/clinica/conectar-se")}
        >
          Entrar
        </LoginLink>
      </LoginParagraph>

      {uploadedImage && (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalBoxWrapper
            style={{
              maxWhidth: "90vw",
            }}
          >
            <Cropper
              aspectRatio={1} // Garante que a seleção seja quadrada
              guides={false}
              initialAspectRatio={1}
              ref={cropperRef}
              src={uploadedImage}
              style={{
                height: "100%",
                width: "100%",
                background: "#333",
                maxWidth: "500px",
                maxHeight: "400px",
              }}
              viewMode={1} // Restringe a área de corte ao contêiner
            />
            <CropButton type="button" onClick={handleCrop}>
              <CheckCircleIcon />
            </CropButton>
          </ModalBoxWrapper>
        </Modal>
      )}
    </InputWrapper>
  );
}

export function TypesReceipts({ form, next, goBack }) {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    form;
  const preferentialPaymentTypes = ["pix", "credit_card", "all_payment"];

  const getPymentMetrod = type => {
    switch (type) {
      case "pix": {
        setFieldValue("acceptPix", true);
        setFieldValue("acceptCredit", false);
        return;
      }
      case "credit_card": {
        setFieldValue("acceptCredit", true);
        setFieldValue("acceptPix", false);
        return;
      }
      case "all_payment": {
        setFieldValue("acceptPix", true);
        setFieldValue("acceptCredit", true);
        return;
      }
      default: {
        setFieldValue("acceptPix", false);
        setFieldValue("acceptCredit", false);
      }
    }
  };

  useEffect(() => {
    getPymentMetrod(values.preferentialPayment);
  }, [values.preferentialPayment]);

  const handleContinue = async () => {
    if (!handleErrors(errors, typesReceipts)) {
      next();
    } else
      toast.error(
        "Ocorreu um erro no formulário, verifique os dados inseridos!",
      );
  };

  return (
    <ConfigWrapper>
      <div style={{ padding: "0 10px 20px 0" }}>
        <Titleh1>Formas de Recebimento</Titleh1>
        <RowLine />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "0 10px 20px 0",
        }}
      >
        <FormControlLabel
          name="prePaid"
          control={<Checkbox checked={values.prePaid} />}
          label={
            <Typography style={{ fontWeight: "bold" }}>
              Aceitar pagamento PRÉ-PAGO (Padrão)
            </Typography>
          }
          onChange={handleChange}
        />
        <FormControlLabel
          name="postPaid"
          control={<Checkbox checked={values.postPaid} />}
          label={
            <Typography style={{ fontWeight: "bold" }}>
              Aceitar pagamento PÓS-PAGO
            </Typography>
          }
          onChange={handleChange}
        />
        <FormControlLabel
          name="externalPayment"
          control={<Checkbox checked={values.externalPayment} />}
          label={
            <Typography style={{ fontWeight: "bold" }}>
              Aceitar pagamento EXTERNO
            </Typography>
          }
          onChange={handleChange}
        />
      </div>

      {values.postPaid && (
        <FormikControl
          control="input"
          errors={errors.postPaidDay}
          label="Dia de cobrança para agendamentos pós-pagos"
          name="postPaidDay"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.postPaidDay}
          type="number"
          upLabel={true}
          value={values?.postPaidDay}
        />
      )}

      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <NewSelect
          bgColor={theme.white}
          defaultValue={values.preferentialPayment}
          errors={errors.preferentialPayment}
          label="Formas de recebimentos aceitas pela clínica"
          name="preferentialPayment"
          onChange={({ value }) => {
            setFieldValue("preferentialPayment", value);
          }}
          options={preferentialPaymentTypes?.map(type => {
            return { label: getPreferencialPayments(type), value: type };
          })}
          placeholder="Selecione a forma de recebimento"
          touched={touched.preferentialPayment}
        />
      </div>

      <FooterButtons goBack={goBack} next={handleContinue} />
    </ConfigWrapper>
  );
}
