import React from "react";
import { FooterButtons } from "../../../components/FooterButtons";
import { AddressFormik } from "../../../components/FormikElements/AddressForm";
import { InputWrapper } from "../../../components/FormikElements/styles";
import handleErrors from "../../../utils/getValuesErrors";
import { LoginTitle } from "../../Public/Login/styles";

export default function ComercialAddress({ form, next, goBack }) {
  const { values, setFieldValue, errors } = form;
  const ValuesField = [
    "cep",
    "street",
    "street_number",
    "complement",
    "country",
    "city",
    "neighborhood",
    "state",
  ];

  const handleTypes = async () => {
    validateComplement();
    if (!handleErrors(errors, ValuesField)) {
      next();
    }
  };

  const validateComplement = () => {
    if (values.complement === "") {
      setFieldValue("complement", "N/A");
    }
  };

  return (
    <InputWrapper>
      <LoginTitle>Endereço Comercial</LoginTitle>
      <AddressFormik form={form} />
      <FooterButtons goBack={goBack} next={() => handleTypes()} />
    </InputWrapper>
  );
}
