/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { PtBr } from '../../services/pt_br';
import {
  Buttons,
  ButtonsContainer,
  Container,
  DefaultContainer,
  DefaultInputContainer,
  InputContainer,
  InputText,
  SearchIcon,
} from './styles';

export function DashBoardSearchBar({ ...props }) {
  const { selected, onClickButton } = props;

  return (
    <Container>
      <InputContainer>
        <InputText {...props} />
        <SearchIcon />
      </InputContainer>
      <ButtonsContainer>
        {PtBr.dashboard_filter_buttons?.map((item) => (
          <Buttons
            key={item}
            type="button"
            checked={item === selected}
            onClick={() => onClickButton(item)}
          >
            {item}
          </Buttons>
        ))}
      </ButtonsContainer>
    </Container>
  );
}

export function DefaultSearchBar({ ...props }) {
  const { bgColor, ...rest } = props;
  return (
    <DefaultContainer bdcolor={bgColor}>
      <DefaultInputContainer>
        <SearchIcon bdcolor={bgColor} />
        <InputText {...rest} />
      </DefaultInputContainer>
    </DefaultContainer>
  );
}
