function getConvertFrequency(frequency) {
  switch (frequency) {
    case "weekly":
      return "Semanal";
    case "biweekly":
      return "Quinzenal";
    case "every_three_weeks":
      return "A cada 3 semanas";
    case "monthly":
      return "Mensal";
    default:
      return "Sem especificar";
  }
}

export default getConvertFrequency;
