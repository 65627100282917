import { Button, Tooltip } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import convertCurrencyPtBr from "../../utils/convertCurrencyPtBr";
import { DeleteIcon, EditIcon } from "../ListItens/styles";

function createData(
  id,
  description,
  amount,
  classification,
  createdAt,
  paidAt,
  additionalInfo,
  transactionType,
) {
  return {
    id,
    description,
    amount,
    paidAt,
    createdAt,
    additionalInfo: { ...additionalInfo, classification, transactionType },
  };
}

function convertTransactionType(transactionType) {
  switch (transactionType) {
    case "appointment":
      return "Agendamento";
    case "subscription":
      return "Assinatura";
    default:
      return "Personalizado";
  }
}

function Row(props) {
  const { row } = props;
  const { onEdit, onDelete } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.description}
        </TableCell>
        <TableCell align="right">{row.createdAt}</TableCell>
        <TableCell
          align="right"
          style={{
            color:
              row.additionalInfo.classification !== "expense" ? "green" : "red",
          }}
        >
          {convertCurrencyPtBr(row.amount)}
        </TableCell>
        <TableCell align="right">{row.paidAt}</TableCell>
        <TableCell align="right">
          {row.id && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Tooltip title="Editar" arrow>
                <Button
                  onClick={() => onEdit(row.id)}
                  startIcon={<EditIcon />}
                />
              </Tooltip>
              <Tooltip title="Deletar" arrow>
                <Button
                  onClick={() => onDelete(row.id)}
                  startIcon={<DeleteIcon />}
                  color="secondary"
                />
              </Tooltip>
            </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Informações Adicionais
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {row.additionalInfo.client && (
                      <TableCell component="th" scope="row">
                        Cliente
                      </TableCell>
                    )}
                    {row.additionalInfo.professional && (
                      <TableCell>Profissional</TableCell>
                    )}
                    <TableCell>Classificação</TableCell>
                    <TableCell>Tipo de Transação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {row.additionalInfo.client && (
                      <TableCell component="th" scope="row">
                        {row.additionalInfo.client}
                      </TableCell>
                    )}
                    {row.additionalInfo.professional && (
                      <TableCell>{row.additionalInfo.professional}</TableCell>
                    )}
                    <TableCell>
                      {row.additionalInfo.classification === "income"
                        ? "Receita"
                        : "Despesa"}
                    </TableCell>
                    <TableCell>
                      {convertTransactionType(
                        row.additionalInfo.transactionType,
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    additionalInfo: PropTypes.shape({
      client: PropTypes.string,
      professional: PropTypes.string,
      classification: PropTypes.string,
      transactionType: PropTypes.string,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    paidAt: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default function TableHistory({ data, modalControl }) {
  const rows = data.map(item => {
    return createData(
      item.additionalEntryId,
      item.description,
      item.amount,
      item.classification,
      item.createdAt,
      item.paidAt,
      item.additionalInfo,
      item.transactionType,
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontSize: "1.2rem", fontWeight: "700 " }}>
              Descrição
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontSize: "1.2rem", fontWeight: "700 " }}
            >
              Data de Criação
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontSize: "1.2rem", fontWeight: "700 " }}
            >
              Valor
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontSize: "1.2rem", fontWeight: "700 " }}
            >
              Data de Pagamento
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row
              key={`${row.additionalEntryId}${index}`}
              row={row}
              onEdit={modalControl.onEdit}
              onDelete={modalControl.onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
