import { Modal } from '@material-ui/core';
import React from 'react';
import { CloseButton, IconArea } from '../../Courses/styles';
import { BoxWrapperLesson, Button, Main, Text, Title } from './styles';

export default function ModalUnlockWebinar({ ...props }) {
  const { handleClick, handleClose, unlockWebinar, creditAvailability } = props;

  return (
    <Modal open>
      <BoxWrapperLesson>
        <IconArea>
          <CloseButton
            sx={{ fontSize: '2.5rem', mr: 1 }}
            onClick={() => handleClose(null)}
          />
        </IconArea>
        <Main>
          {creditAvailability < unlockWebinar?.credits ? (
            <Text error>
              Você não tem pontos Allminds suficientes para liberar esse
              conteúdo
            </Text>
          ) : (
            <Text>
              Esse conteúdo está bloqueado! Faça uma sessão para ganhar pontos
              ou troque seus pontos para fazer a liberação.
            </Text>
          )}
          <Title>{`Pontos diponiveis ${creditAvailability}`}</Title>
          <Title webinar>Título: {unlockWebinar?.title}</Title>
          <Text webinar>Descrição: {unlockWebinar?.description}</Text>
          <Button
            onClick={handleClick}
            disabled={
              creditAvailability < unlockWebinar?.credits ||
              creditAvailability === 0
            }
          >
            Liberar Mini Curso
          </Button>
        </Main>
      </BoxWrapperLesson>
    </Modal>
  );
}
