/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import LogoMySelf from "../../images/logo-blue.png";
import LoaderSpinner from "../LoaderSpinner";

const WaitingToJoinScreen = () => {
  const waitingMessages = [
    { index: 0, text: "Iniciando a sessão..." },
    { index: 1, text: "Quase lá..." },
  ];
  const [message, setMessage] = useState(waitingMessages[0]);

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMessage(s =>
        s.index === waitingMessages.length - 1
          ? s
          : waitingMessages[s.index + 1],
      );
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="h-screen md:h-[85vh] flex flex-col flex-1 items-center justify-center pt-5 pb-5">
      <img
        src={LogoMySelf}
        className="rounded-[10px] h-[20vh] w-full md:h-[40vh] md:w-[40vw] object-cover flex items-center justify-center flip"
        alt="Logo"
      />
      <LoaderSpinner />
      <h1 className="text-[#426AFF] text-center font-bold mt-1 text-2xl">
        {message.text}
      </h1>
    </div>
  );
};

export default WaitingToJoinScreen;
