import React from 'react';
import { useHistory } from 'react-router-dom';
import { PostList } from '../../../components/List';
import useAuth from '../../../hooks/auth';
import { MainContainer } from './styles';

export default function ContentListScreen() {
  const history = useHistory();

  const { user } = useAuth();
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;
  return (
    <MainContainer>
      <PostList onClick={(id) => history.push(`/${type}/content_list/${id}`)} />
    </MainContainer>
  );
}
