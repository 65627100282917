import React from "react";
import useEvent from "../../hooks/event";
import defaultAvatar from "../../images/noAvatar.png";
import convertCurrencyPtBr from "../../utils/convertCurrencyPtBr";
import {
  BFontBold,
  Button,
  ColumnItem,
  ContainerPlans,
  Main,
  MdFontBold,
  PlanDetails,
  SFontDark,
  UserImage,
} from "./styles";
import useAuth from "../../hooks/auth";
import getPlanName from "../../utils/getPlanName";

export function handleUserNames(user) {
  const social = user?.socialName || user?.social_name;
  const firstName = user?.firstName || user?.first_name;
  const lastName = user?.lastName || user?.last_name;
  const fullName = `${firstName} ${
    lastName?.split(" ")[lastName?.split(" ")?.length - 1]
  }`;
  return social || fullName;
}

export function CardsPlans({ ...props }) {
  const { infoCardCredit } = useEvent();
  const {
    active,
    clinic,
    isSubscription,
    message,
    handleModal,
    planName,
    planPrice,
    setFieldValue,
    submitPlan,
    tPlanName,
    values,
  } = props;
  const setActive = values === planName ? active : !clinic && active;

  return (
    <ContainerPlans clinic>
      <ColumnItem>
        <BFontBold>
          {tPlanName === "Grátis"
            ? "Plano gratuito"
            : clinic
            ? `${tPlanName}`
            : `Mind ${tPlanName}`}
        </BFontBold>
        <SFontDark>{message}</SFontDark>
        {clinic && planPrice > 0 && (
          <BFontBold>{convertCurrencyPtBr(planPrice)}</BFontBold>
        )}
        {!active ? (
          <Button
            disabled={clinic ? !active : isSubscription ? true : setActive}
            active={
              values?.planName === planName ? "true" : active ? "true" : "false"
            }
            name={tPlanName}
            value={planName}
            onClick={({ target }) => {
              if (!clinic) {
                submitPlan && setFieldValue(planName, infoCardCredit);
                setFieldValue("planName", target.value);
                handleModal(true);
              }
            }}
          >
            {clinic ? "Seleção Automática" : "Selecionar Plano"}
          </Button>
        ) : (
          <div />
        )}
      </ColumnItem>
    </ContainerPlans>
  );
}

export function UserSubscribe({ ...props }) {
  const {
    clinic,
    isSubscription,
    handleModal,
    plans,
    setFieldValue,
    submitPlan,
    values,
  } = props;
  const { user } = useAuth();
  const planName = user?.subscription?.pagarmePlan;

  const selectedPlan = (num, min, max) => {
    if (num >= min && num <= max) {
      return true;
    }
  };

  return (
    <Main>
      <CardsPlans
        active={
          clinic
            ? selectedPlan(values, plans?.minProf, plans?.maxProf)
            : planName === plans?.planName
        }
        clinic={clinic}
        isSubscription={isSubscription}
        message={plans?.message}
        handleModal={handleModal}
        planName={plans?.planName}
        planPrice={plans?.planPrice}
        setFieldValue={setFieldValue}
        submitPlan={submitPlan}
        tPlanName={getPlanName(plans?.planName)}
        values={values}
      />
      {plans?.detail && (
        <PlanDetails>
          <MdFontBold>Valor por profissional</MdFontBold>
          <MdFontBold>{convertCurrencyPtBr(plans?.detail)}</MdFontBold>
        </PlanDetails>
      )}
    </Main>
  );
}

export function UserAvatar({ src, size, search }) {
  return <UserImage src={src || defaultAvatar} size={size} search={search} />;
}
