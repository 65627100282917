import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Certifique-se de importar o CSS padrão do DatePicker

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;

  .react-datepicker__input-container input {
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Inclui padding e border na largura total */
    font-size: 16px;
    outline: none;
    padding: 8px;
    width: 100%; /* Defina uma largura fixa */
  }

  .react-datepicker__header {
    background-color: #f0f0f0;
  }

  .react-datepicker__day--selected {
    background-color: #007bff;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: #fff;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  padding: 5px 0;
`;
