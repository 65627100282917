/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import valid from 'card-validator';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import Logo from '../../../images/logo-mind-white.svg';
import api, { apiPublic } from '../../../services/api';
import { BREAKPOINT_MD, InstagramIcon } from '../../../styles/globalStyles';
import useWindowSize from '../../../utils/WindowSize';
import camalize from '../../../utils/camalize';
import validateCPF from '../../../utils/validateCPF';
import validateInfoBank from '../../../utils/validateInfoBank';
import {
  BackgroundImg,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  LogoImg,
} from '../../Public/Login/styles';
import {
  IconAreaInstagran,
  SocialIconsWrapper,
} from '../../Public/Profile/styles';
import AcceptanceTerm from '../../Register/AcceptanceTerm';
import AttendanceConfig from '../../Register/AttendanceConfig';
import Availability from '../../Register/Availability';
import BankAccount from '../../Register/BankAccount';
import BasicRegistration from '../../Register/BasicRegistrationProfessional';
import ComercialAddress from '../../Register/ComercialAddress';
import PublicProfile from '../../Register/PublicProfile';
import SubscribeScreen from '../../Register/Subscribe';
import { LoginWrapper, Main, ProfessionalForm } from './styles';
import getProfessionName from '../../../utils/getProfessionName';
import { getProfessions } from '../../../utils/getProfessionsFilter';

export default function ProfessionalSignUp() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [validateClinic, setValidateClinic] = useState(false);
  const { backgroundImg } = useEvent();
  const { founder } = useParams();
  const { signUpProfessional } = useAuth();
  const { width } = useWindowSize();

  const professorPrice = 50;
  const psychologistPrice = 80;
  const professionalAdvisorPrice = 70;
  const psychopedagoguePrice = 70;
  const durationAppointment = 60;
  const minDurationAppointment = 30;

  const clinic = useMemo(() => {
    const clinicNameUrl = new URLSearchParams(location.search)?.get(
      'clinicName'
    );
    if (clinicNameUrl) {
      const clinicNameArray = clinicNameUrl.split('-');
      const clinicId = clinicNameArray[0];
      clinicNameArray.shift(); // Remove a primeira posição do array

      return {
        clinicId,
        clinicName: camalize(clinicNameArray.join(' ')),
        url: clinicNameUrl,
      };
    }
    return null;
  }, [location.search]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessions = async () => {
      try {
        const { data } = await api.get('/professions', {
          signal: controller.signal,
        });
        const professions = getProfessions(
          data.data.attributes.professions
        )?.map((prof) => ({
          ...prof,
          name: getProfessionName(prof.name, 'toPt'),
        }));
        form.setFieldValue('jobs', professions);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possivel carregar profissões');
      } finally {
        setLoading(false);
      }
    };
    fetchProfessions();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const getCheckClinic = async () => {
      try {
        const { data } = await apiPublic.get(
          `/public/check_clinic_username?username=${clinic.url}`
        );
        if (data.data) {
          form.setFieldValue('acceptPix', data.data.attributes.acceptPix);
          form.setFieldValue('acceptCredit', data.data.attributes.acceptCredit);
          form.setFieldValue('postPaid', data.data.attributes.postPaid);
          form.setFieldValue('postPaidDay', data.data.attributes.postPaidDay);
          form.setFieldValue(
            'externalPayment',
            data.data.attributes.externalPayment
          );
          setValidateClinic(true);
        }
      } catch (error) {
        toast.error('Clinica não encontrada, entre em contato com Allminds', {
          autoClose: false,
        });
      }
    };

    if (clinic?.clinicId) {
      getCheckClinic();
    }
  }, [clinic]);

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const success = await signUpProfessional(values);
      if (success) {
        history.push('/profissional/sucesso');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error(
        'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
      );
    } finally {
      setLoading(false);
    }
  };

  const schema = Yup.object().shape({
    // basic registration
    firstName: Yup.string().required('Nome é obrigatório!'),
    lastName: Yup.string().required('Sobrenome é obrigatório!'),
    email: Yup.string()
      .email('Email invalido')
      .required('Email é obrigatório!'),
    birthday: Yup.string('Formato de data inválido')
      .required('Data de nascimento é obrigatorio')
      .test('maioridade', 'É necessário ter 18 anos ou mais', function (value) {
        const age = moment().diff(moment(value), 'years');
        return age >= 18;
      }),
    phone: Yup.string().required('Telefone é obrigatório!'),
    documentNumber: Yup.string().test({
      name: 'isValid',
      exclusive: false,
      params: {},
      message: 'CPF inválido',
      test(value) {
        return validateCPF(value || '');
      },
    }),
    password: Yup.string()
      .required('Senha é Requerida')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
    confirm_password: Yup.string()
      .required('Repita a senha')
      .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais.'),

    // comercial address
    cep: Yup.string().required('CEP é obrigatório!'),
    street: Yup.string().required('Endereço é obrigatório!'),
    street_number: Yup.string().required('Número é obrigatório!'),
    complement: Yup.string().optional(),
    neighborhood: Yup.string().required('Bairro é obrigatório!'),
    city: Yup.string().required('Cidade é obrigatório!'),
    state: Yup.string().required('Estado é obrigatório!'),
    country: Yup.string().required('Pais é obrigatório!'),

    // public profile
    avatar: Yup.string().required('Foto de perfil é obrigatório!'),
    profession: Yup.string().required('Selecione uma profissão para continuar'),
    job_register: Yup.string().when('profession', {
      is: (job) => job === 'Psicólogo(a)',
      then: Yup.string()
        .required('Número de Registro é obrigatório')
        .nullable(),
    }),
    certificate: Yup.string().when('profession', {
      is: (job) =>
        ['Psicopedagogo(a)', 'Orientador(a) Profissional'].includes(job),
      then: Yup.string().required('Certificação é obrigatório').nullable(),
    }),
    educationCertificate: Yup.string().when('profession', {
      is: (job) =>
        [
          'Psicopedagogo(a)',
          'Orientador(a) Profissional',
          'Professor(a)',
        ].includes(job),
      then: Yup.string().required('Diploma é obrigatório').nullable(),
    }),
    academicBackground: Yup.array()
      .min(1, 'Apresente ao menos uma formação')
      .required('Formações são obrigatórias'),
    specialities: Yup.array()
      .when('profession', {
        is: (job) =>
          [
            'Psicólogo(a)',
            'Psicopedagogo(a)',
            'Orientador(a) Profissional',
          ].includes(job),
        then: Yup.array()
          .min(1, 'Escolha no mínimo uma especialidade')
          .max(8, 'Escolha no máximo oito especialidades')
          .required('Especialidades são obrigatórias'),
      })
      .when('profession', {
        is: (job) => job === 'Professor(a)',
        then: Yup.array()
          .min(1, 'Escolha no mínimo uma especialidade')
          .required('Especialidades são obrigatórias'),
      }),
    reasons: Yup.array()
      .when('profession', {
        is: (job) =>
          [
            'Psicólogo(a)',
            'Psicopedagogo(a)',
            'Orientador(a) Profissional',
          ].includes(job),
        then: Yup.array()
          .min(1, 'Escolha no mínimo um motivo')
          .max(8, 'Escolha no máximo oito motivos')
          .required('Motivos são obrigatórias'),
      })
      .when('profession', {
        is: (job) => job === 'Professor(a)',
        then: Yup.array()
          .min(1, 'Escolha no mínimo uma matéria')
          .required('Matérias são obrigatórias'),
      }),
    approaches: Yup.array().when('profession', {
      is: (job) => ['Psicólogo(a)', 'Orientador(a) Profissional'].includes(job),
      then: Yup.array()
        .min(1, 'Escolha no mínimo uma abordagem')
        .max(8, 'Escolha no máximo oito abordagens')
        .required('Abordagens são obrigatórias'),
    }),
    bio: Yup.string().required('Biografia é obrigatória!'),
    age_group: Yup.array()
      .min(1, 'Escolha no mínimo uma faixa etária')
      .required('Faixa etária é obrigatório'),
    experience_time: Yup.number()
      .min(0, 'Tempo de experiência não pode ser menor que 0')
      .required('Tempo de experiencia é obrigatório'),
    appointmentTypes: Yup.array()
      .min(1, 'Escolha no mínimo um tipo de agendamento')
      .required('Tipo de agendamento é obrigatório'),
    presentationVideo: Yup.string(),

    // bank account
    clinicId: Yup.string().nullable(),
    bankCode: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string().required('Banco é obrigatório'),
    }),
    bankAg: Yup.string()
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().required('Agência da conta é obrigatório'),
      })
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'Informe apenas números',
          test(value) {
            if (!value) return true;
            return validateInfoBank(value);
          },
        }),
      }),
    agenciaDv: Yup.string().optional(),
    bankCc: Yup.string()
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().required('Número da conta é obrigatório'),
      })
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'Informe apenas números',
          test(value) {
            if (!value) return true;
            return validateInfoBank(value);
          },
        }),
      }),
    contaDv: Yup.string()
      .typeError('Informe apenas números')
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().required('Dígito da conta é obrigatório'),
      })
      .when('clinicId', {
        is: (clinicId) => clinicId === null,
        then: Yup.string().test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'Informe apenas números',
          test(value) {
            if (!value) return true;
            return validateInfoBank(value);
          },
        }),
      }),
    accountType: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string().required('Tipo da conta é obrigatório'),
    }),
    name: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string().required('Nome é obrigatório'),
    }),

    // session price
    acceptCredit: Yup.boolean().optional(),
    acceptPix: Yup.boolean().optional(),
    prePaid: Yup.boolean().optional(),
    postPaid: Yup.boolean().optional(),
    postPaidDay: Yup.number().when('postPaid', {
      is: true,
      then: Yup.number()
        .min(1, 'Dia do pagamento deve ser maior que 1')
        .max(31, 'Dia do pagamento deve ser menor que 31')
        .required('Dia do pagamento é obrigatório'),
    }),
    externalPayment: Yup.boolean().optional(),
    allmindsBenefit: Yup.boolean().optional(),
    sessionPrice: Yup.number('Valor deve ser numérico')
      .when('profession', {
        is: (job) => job === 'Psicólogo(a)',
        then: Yup.number('Valor deve ser numérico').min(
          psychologistPrice,
          'Valor da consulta não pode ser inferior a R$ 80,00'
        ),
      })
      .when('profession', {
        is: (job) => job === 'Professor(a)',
        then: Yup.number('Valor deve ser numérico').min(
          professorPrice,
          'Valor da consulta não pode ser inferior a R$ 50,00'
        ),
      })
      .when('profession', {
        is: (job) => job === 'Orientador(a) Profissional',
        then: Yup.number('Valor deve ser numérico').min(
          professionalAdvisorPrice,
          'Valor da consulta  não pode ser inferior a R$ 70,00'
        ),
      })
      .when('profession', {
        is: (job) => job === 'Psicopedagogo(a)',
        then: Yup.number('Valor deve ser numérico').min(
          psychopedagoguePrice,
          'Valor da consulta  não pode ser inferior a R$ 70,00'
        ),
      }),

    // availability
    breakTime: Yup.number()
      .min(0, 'Dempo de descanço não pode ser menor que 0')
      .optional(),
    workdays: Yup.array()
      .min(1, 'Selecione pelo menos um dia da semana')
      .required('Requerido'),
    appointmentDuration: Yup.number()
      .min(
        minDurationAppointment,
        `Duração de atendimento não pode ser menor que ${minDurationAppointment} minutos`
      )
      .max(120, 'Duração do atendimento não pode ser maior que 120 minutos')
      .required('Duração do atendimento é obrigatória')
      .required('Requerido'),
    // subscription
    planName: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string().required('Plano é obrigatório'),
    }),
    cardNumber: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string()
        .required('Número do cartão é obrigatório')
        .test(
          'test-number',
          'Cartão inválido',
          (value) => valid.number(value).isValid
        ),
    }),
    cardHolderName: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string().required('Nome do titular é obrigatório'),
    }),
    cardCvv: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string()
        .required('Código de segurança é obrigatório')
        .test(
          'test-cvv',
          'Código de segurança inválido',
          (value) => valid.cvv(value).isValid
        ),
    }),
    cardExpiration_date: Yup.string().when('clinicId', {
      is: (clinicId) => clinicId === null,
      then: Yup.string()
        .required('Data de validade é obrigatório')
        .test(
          'test-date',
          'Data inválida',
          (value) => valid.expirationDate(value).isValid
        ),
    }),

    // accept term
    termId: Yup.string().required('Termo é obrigatório'),
    accepted: Yup.boolean().required('Aceitar o termo é obrigatório!'),
    read: Yup.boolean().required('Ler o termo é obrigatório!'),
  });

  const form = useFormik({
    initialValues: {
      academicBackground: [],
      acceptCredit: false,
      acceptPix: false,
      accepted: false,
      accountType: '',
      age_group: [],
      agenciaDv: '',
      allmindsBenefit: false,
      appointmentDuration: durationAppointment,
      appointmentTimeBlocks: {},
      appointmentTypes: [],
      approaches: [],
      avatar: '',
      bankAg: '',
      bankCc: '',
      bankCode: '',
      bio: '',
      birthday: '',
      blockedTimes: {},
      breakTime: 0,
      cardCvv: '',
      cardExpiration_date: '',
      cardHolderName: '',
      cardNumber: '',
      cep: '',
      certificate: '',
      certificateFileName: '',
      city: '',
      clinicId: clinic?.clinicId || null,
      clinicName: clinic?.clinicName || null,
      complement: 'N/A',
      configTimes: {},
      confirm_password: '',
      contaDv: '',
      country: '',
      documentNumber: '',
      educationCertificate: '',
      educationCertificateFileName: '',
      email: '',
      experience_time: 0,
      firstName: '',
      formations: '',
      free: false,
      hasSocialName: false,
      jobApproaches: [],
      jobReasons: [],
      jobSpecialities: [],
      job_register: '',
      jobs: [],
      lastName: '',
      name: '',
      neighborhood: '',
      password: '',
      phone: '',
      planName: 'platinum',
      externalPayment: false,
      postPaid: false,
      postPaidDay: 10,
      prePaid: true,
      preferentialPayment: '',
      presentationVideo: '',
      profession: '',
      read: false,
      reasons: [],
      sessionPrice: 0,
      setDefault: true,
      socialName: '',
      specialities: [],
      state: '',
      street: '',
      street_number: '',
      termId: '',
      workdays: [],
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => submitForm(values),
  });

  useEffect(() => {
    const toogleModal = () => {
      setLoading(false);
    };
    toogleModal();
  }, [step]);

  function next() {
    setStep((prevStep) => (prevStep += 1));
  }

  function goBack() {
    setStep((prevStep) => (prevStep -= 1));
  }

  return (
    backgroundImg && (
      <FormikProvider value={form}>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <Main step={step}>
            <ProfessionalForm>
              <LoginWrapper step={step}>
                <ImageContainer>
                  <LogoImg src={Logo} alt="Logo" />
                </ImageContainer>
                {step === 0 && !loading && (
                  <BasicRegistration
                    next={next}
                    form={form}
                    clinic={validateClinic}
                  />
                )}
                {step === 1 && !loading && (
                  <ComercialAddress next={next} goBack={goBack} form={form} />
                )}
                {step === 2 && !loading && (
                  <PublicProfile next={next} goBack={goBack} form={form} />
                )}
                {step === 3 && !loading && (
                  <BankAccount next={next} goBack={goBack} form={form} />
                )}
                {step === 4 && !loading && (
                  <AttendanceConfig next={next} goBack={goBack} form={form} />
                )}
                {step === 5 && !loading && (
                  <Availability next={next} goBack={goBack} form={form} />
                )}
                {step === 6 && !loading && (
                  <SubscribeScreen
                    next={next}
                    goBack={goBack}
                    form={form}
                    founder={founder === 'founderPromotion' ? true : false}
                  />
                )}
                {step === 7 && !loading && (
                  <AcceptanceTerm goBack={goBack} form={form} />
                )}
              </LoginWrapper>
            </ProfessionalForm>
            <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
            {width > BREAKPOINT_MD && ![5, 7].includes(step) && (
              <Fragment>
                <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
                <BackgroundImg style={{ zIndex: 0 }} src={backgroundImg} />
              </Fragment>
            )}
            <SocialIconsWrapper align={[5, 7].includes(step)}>
              {/* <Tooltip title="Fale com a gente">
                <IconAreaWhatsapp
                  href="https://wa.me/5571999960614"
                  target="_blank"
                >
                  <WhatsappIcon fontSize="medium" sx={{ color: '#fff' }} />
                </IconAreaWhatsapp>
              </Tooltip> */}
              <Tooltip title="Siga-nos no Instagram">
                <IconAreaInstagran
                  href="https://www.instagram.com/allminds.app/"
                  target="_blank"
                >
                  <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
                </IconAreaInstagran>
              </Tooltip>
            </SocialIconsWrapper>
          </Main>
        )}
      </FormikProvider>
    )
  );
}
