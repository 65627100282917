/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FooterButtons } from '../../../components/FooterButtons';
import BankAccountForm from '../../../components/FormikElements/BankAccount';
import { InputWrapper } from '../../../components/FormikElements/styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { PtBr } from '../../../services/pt_br';
import handleErrors from '../../../utils/getValuesErrors';
import { LoginParagraph, LoginTitle } from '../../Public/Login/styles';
import { BankCardContainer } from './styles';

export default function BankAccount({ form, next, goBack, clinic }) {
  const { errors, setFieldValue } = form;
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const FieldValues = [
    'name',
    'bankAg',
    'accountType',
    'bankCc',
    'bankCode',
    'agenciaDv',
    'contaDv',
  ];

  function onSubmit() {
    if (!handleErrors(errors, FieldValues)) {
      next();
    }
  }

  function changeEditingState() {
    if (editing) {
      setFieldValue('bankCode', '');
    }
    setEditing(!editing);
  }

  function validateValues() {
    if (!handleErrors(errors, FieldValues)) {
      setEditing(!editing);
      onSubmit();
    }
  }

  return (
    <InputWrapper>
      <LoginTitle>{PtBr.pages_text.new_bank_account_header}</LoginTitle>
      <LoginParagraph style={{ textAlign: 'left' }}>
        {PtBr.pages_text.new_bank_account_subheader}
      </LoginParagraph>
      <BankCardContainer>
        {loading ? (
          <LoaderSpinner setLoading={setLoading} />
        ) : (
          <BankAccountForm form={form} clinic={clinic} />
        )}
        <FooterButtons
          next={validateValues}
          goBack={editing ? changeEditingState : goBack}
        />
      </BankCardContainer>
    </InputWrapper>
  );
}
