import styled from 'styled-components';
import {
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  SmallFont,
} from '../../../styles/globalStyles';

export const TermContainer = styled.main`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
  }
`;

export const TermContent = styled.section`
  align-items: center;
  border-radius: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding: 3rem 0;
  width: 70%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 80%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    background-image: none;
    border-radius: 1rem;
    width: 100%;
  }
`;

export const TermDocument = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: flex-start;
  margin-bottom: 2rem;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    background-color: #ced0db;
    border-radius: 5px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
  }
`;

export const TermTitle = styled(SmallFont)`
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;

export const ContinueButton = styled.button`
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 0.25rem;
  box-shadow: 3px 3px 3px ${({ theme }) => theme.newPrimaryAlt};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: 600;
  height: 2.5rem;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ButtonWrapper = styled.section`
  align-items: flex-start;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;
