import axios from "axios";

export const baseURL = () => {
  switch (process.env.REACT_APP_HOST) {
    case "production":
      return process.env.REACT_APP_PRODUCTION_URL;
    case "development":
      return process.env.REACT_APP_DEV_URL;
    case "development.local":
      return process.env.REACT_APP_DEV_LOCAL_URL;
    case "development.ngrok":
      return process.env.REACT_APP_DEV_NGROK_URL;
    default:
      return null;
  }
};

export const baseURLPublic = () => {
  switch (process.env.REACT_APP_HOST) {
    case "production":
      return process.env.REACT_APP_PRODUCTION_URL_PUBLIC;
    case "development":
      return process.env.REACT_APP_DEV_URL_PUBLIC;
    case "development.local":
      return process.env.REACT_APP_DEV_LOCAL_URL_PUBLIC;
    case "development.ngrok":
      return process.env.REACT_APP_DEV_NGROK_URL_PUBLIC;
    default:
      return null;
  }
};

const api = axios.create({
  baseURL: baseURL(),
});

export const apiPublic = axios.create({
  baseURL: baseURLPublic(),
});

export const viaCepApi = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

export const brasilApi = axios.create({
  baseURL: "https://brasilapi.com.br/api/banks/v1",
});

export default api;
