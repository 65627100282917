import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDatePicker, MainContainer } from './styles';

// Registrar a localização em português
registerLocale('pt-BR', ptBR);

export function DateRangeSelector({ ...props }) {
  const { handleCustomDate } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (startDate && endDate) {
      handleCustomDate(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );
    }
  }, [startDate, endDate, handleCustomDate]);

  return (
    <MainContainer>
      <CustomDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Data Inicial"
        locale="pt-BR"
        dateFormat="dd/MM/yyyy"
      />
      <CustomDatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Data Final"
        locale="pt-BR"
        dateFormat="dd/MM/yyyy"
      />
    </MainContainer>
  );
}
