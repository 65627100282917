import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import imgCadastrarContaBancaria from '../../../images/img cadastrar conta bancaria.svg';
import imgContaBancaria from '../../../images/img conta bancaria.svg';
import imgLogo from '../../../images/logo-mind-white.svg';
import imgProximoEventoFoto from '../../../images/img proximo evento foto.svg';
import imgProximoEvento from '../../../images/img proximos eventos.svg';
import useWindowSize from '../../../utils/WindowSize';
import './styles.css';

export default function OnboardingProfessional({ handleModal }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [currentPage, setCurrentPage] = useState('0');
  const { width } = useWindowSize();

  return (
    <Modal open onClose={() => null}>
      <Box
        style={{
          background: 'linear-gradient(180deg, #C0CCF9 0%, #FFFFFF 100%)',
          borderRadius: '20px',
        }}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width > 1024 ? 515 : 350,
          height: 600,
          p: 2.5,
        }}
      >
        <Swiper
          cssMode={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          onSlideChange={(swiper) => setCurrentPage(swiper.activeIndex)}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgProximoEvento}
                style={{
                  height: 'auto',
                  width: '60%',
                  zIndex: 2,
                  marginBottom: '-100px',
                  marginRight: '145px',
                }}
                alt="imgProximoEvento"
              />
              <img
                src={imgProximoEventoFoto}
                style={{
                  height: 'auto',
                  width: '60%',
                  zIndex: 1,
                  marginRight: '-150px',
                  marginTop: '-40px',
                }}
                alt="imgProximoEventoFoto"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Atendimento
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  Você receberá notificações sempre que um paciente/cliente
                  agendar uma consulta com você. Para realizar o atendimento,
                  basta acessar o seu perfil nos dias e horas marcadas e clicar
                  na opção “iniciar atendimento”. Caso você precise desmarcar ou
                  reagendar a consulta, pode solicitar esta opção através do
                  ícone “x”, ao qual seu paciente será notificado e aceitará ou
                  não a nova data/horário.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgContaBancaria}
                style={{
                  height: 'auto',
                  width: '70%',
                  zIndex: 2,
                }}
                alt="imgContaBancaria"
              />
              <img
                src={imgCadastrarContaBancaria}
                style={{
                  height: 'auto',
                  width: '50%',
                  zIndex: 1,
                  marginRight: '0px',
                  marginTop: '-90px',
                }}
                alt="imgCadastrarContaBancaria"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Dados Bancários
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  Você precisará cadastrar uma conta bancária para receber os
                  seus pagamentos. É importante estar atento ao cadastro de uma
                  conta válida e ativa.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgLogo}
                style={{
                  height: 'auto',
                  width: '80%',
                  marginTop: '40px',
                }}
                alt="imgLogo"
              />
              <section style={{ alignSelf: 'flex-start' }}>
                <Typography
                  style={{ alignSelf: 'flex-start', marginBottom: '50px' }}
                  variant="h5"
                >
                  {'Seja bem-vindo(a) a Allminds.'}
                </Typography>
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  container
                >
                  <Button
                    style={{
                      marginTop: '-25px',
                      width: '195px',
                      height: 'auto',
                      fontSize: '18px',
                    }}
                    sx={{ m: 1 }}
                    onClick={handleModal}
                    size="small"
                    variant="contained"
                  >
                    Começar
                  </Button>
                </Grid>
              </section>
            </Grid>
          </SwiperSlide>
        </Swiper>

        <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
        >
          <Button
            disabled={currentPage > 0 ? false : true}
            sx={{ m: 1 }}
            onClick={null}
            size="small"
            variant="outlined"
            ref={navigationPrevRef}
          >
            Anterior
          </Button>
          <Button
            disabled={currentPage !== 2 ? false : true}
            sx={{ m: 1 }}
            onClick={null}
            size="small"
            variant="contained"
            ref={navigationNextRef}
          >
            Próximo
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
