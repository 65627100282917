/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Modal, Typography } from '@mui/material';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import { BLOCK_TYPE, INLINE_STYLE } from 'draftail';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  BoxWrapper,
  BoxWrapperScroll,
  CloseButton,
  IconArea,
  ImgContainer,
  LessonImg,
} from '../../Courses/styles';
import { StyledDraftailEditor, StyledPage } from '../styles';
import './styles.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ModalArticle({ ...props }) {
  const { setModal, modal, article } = props;
  const [file64, setFile64] = useState(article.file64);
  const [isFilePicked, setIsFilePicked] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState('');

  useEffect(() => {
    if (!file64 && article?.body !== '') {
      setIsFilePicked(false);
      setEditorContent(article?.body);
    } else {
      setIsFilePicked(true);
    }
  }, [article]);

  useEffect(() => {
    if (isFilePicked === true) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setFile64(e.target.result);
      };
    }
  }, [isFilePicked]);

  useEffect(() => {
    if (editorContent) {
      try {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(editorContent))
          )
        );
      } catch (error) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(editorContent)
          )
        );
      }
    }
  }, [editorContent]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Modal open>
      <BoxWrapper>
        <IconArea>
          <CloseButton
            sx={{ fontSize: '2.5rem' }}
            onClick={() =>
              setModal({
                ...modal,
                open: false,
              })
            }
          />
        </IconArea>
        <BoxWrapperScroll>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            sx={{ width: '80%' }}
          >
            <Grid item xs>
              <Typography variant="h4" align="center" sx={{ mb: 3 }}>
                {article?.title}
              </Typography>
            </Grid>

            <Grid item xs>
              <ImgContainer>
                <LessonImg src={article?.image} alt="article" />
              </ImgContainer>
            </Grid>

            <Grid item xs>
              <Typography variant="h6" align="left" sx={{ mb: 3, mt: 3 }}>
                {article?.description}
              </Typography>
            </Grid>

            <Grid item xs>
              {isFilePicked === true && (
                <StyledPage>
                  <Grid container item xs={12} justifyContent="space-between">
                    <Button onClick={previousPage}>{'<<<'}</Button>
                    <p>
                      Página {pageNumber} de {numPages}
                    </p>
                    <Button onClick={nextPage}>{'>>>'}</Button>
                  </Grid>
                  <Document file={file64} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                      size="A4"
                      renderTextLayer={false}
                      pageNumber={pageNumber}
                      width={window.innerWidth} // Defina a largura da página
                      height={window.innerHeight} // Defina a altura da página
                    />
                  </Document>
                  <Grid container item xs={12} justifyContent="space-between">
                    <Button onClick={previousPage}>{'<<<'}</Button>
                    <p>
                      Página {pageNumber} de {numPages}
                    </p>
                    <Button onClick={nextPage}>{'>>>'}</Button>
                  </Grid>
                </StyledPage>
              )}
              {isFilePicked !== null && isFilePicked === false && (
                <Typography variant="h6" align="center" sx={{ mb: 3, mt: 3 }}>
                  <StyledDraftailEditor
                    readOnly={true}
                    editorState={editorState}
                    blockTypes={[
                      { type: BLOCK_TYPE.HEADER_TWO },
                      { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
                      { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
                      { type: BLOCK_TYPE.BLOCKQUOTE },
                    ]}
                    inlineStyles={[
                      { type: INLINE_STYLE.BOLD },
                      { type: INLINE_STYLE.ITALIC },
                      { type: INLINE_STYLE.UNDERLINE },
                      { type: INLINE_STYLE.STRIKETHROUGH },
                      { type: INLINE_STYLE.SUBSCRIPT },
                      { type: INLINE_STYLE.SUPERSCRIPT },
                      { type: INLINE_STYLE.QUOTATION },
                    ]}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </BoxWrapperScroll>
      </BoxWrapper>
    </Modal>
  );
}
