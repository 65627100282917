import { ChevronLeft } from 'react-feather';
import { Page } from 'react-pdf';
import styled from 'styled-components';
import { BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const StyledPage = styled(Page)`
  min-height: 900px;
  max-height: 100%;
`;

export const Dashboard = styled.section`
  min-height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-size: cover;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const MainContainer = styled.section`
  flex: 1;
  width: 100;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BodyWrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 10px 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ImageWrapper = styled.section`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const PostImage = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  border-radius: 0% 0% 50% 50%/ 0% 0% 5% 5%;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TextWrapper = styled.section`
  flex: 2;
  height: 100%;
  display: flex;
  padding-left: 20px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const PostTitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.secondary};
`;

export const PostRegularFont = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  padding-top: 20px;
  color: ${({ theme }) => theme.secondary};
`;

export const PostSmallFont = styled.h3`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.secondary};
`;

export const PostLightFont = styled(PostSmallFont)`
  color: ${({ theme }) => theme.ExtralightGray};
`;

export const SituationContent = styled(PostRegularFont)`
  flex: 1;
  width: 100%;
  padding: 5px;
  display: flex;
  margin: 20px 0;
  margin-left: 50px;
  border-radius: 0 5px 5px 0;
  background-color: ${({ theme }) => theme.offWhite};
  border-left: 5px solid ${({ theme }) => theme.ExtralightGray};
`;

export const Button = styled.button`
  display: flex;
  padding: 5px;
  margin: 50px 50px 0 50px;
  border-radius: 5px;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.darkGray};
  background-color: ${({ theme }) => theme.color10};
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.darkGray};
`;
