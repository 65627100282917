import styled from 'styled-components';
import { SmallButton } from '../../../components/Button';
import {
  AnimationWrapper,
  BREAKPOINT_SM_PX,
  MediumBigFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const MainContainer = styled(AnimationWrapper)`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const RowItem = styled.section`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ListItemsContainer = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerItem = styled.section`
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 100%;
    padding: 0 10px;
  }
`;

export const PayOutContainer = styled.div`
  width: 120px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
`;

export const SMFont = styled(SmallFont)`
  color: ${({ theme }) => theme.lightGray};
`;

export const MDFont = styled(MediumBigFont)`
  font-weight: 300;
  padding: 5px 0;
  color: ${({ theme }) => theme.secondary};
`;

export const Button = styled(SmallButton)`
  width: 100%;
  height: 35px;
`;
