export function getConvertTheme(theme) {
  switch (theme) {
    case 'anxiety':
      return 'Ansiedade';
    case 'self-esteem':
      return 'Autoestima';
    case 'self-knowledge':
      return 'Autoconhecimento';
    case 'depression':
      return 'Depressão';
    default:
      return theme;
  }
}
