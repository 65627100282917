/* eslint-disable react-hooks/exhaustive-deps */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import { toast } from "react-toastify";
import getPlanName from "../../../../utils/getPlanName";
import { UserSubscribe } from "../../../UserElements";

function createData(name, col1) {
  return { name, col1 };
}

const rows = [
  createData("Valor mensal (compra trimestral)", "R$ 99,00"),
  createData("Adquira clientes de qualquer lugar do país", <CheckCircle />),
  createData("Agendamentos e pagamentos online", <CheckCircle />),
  createData("Posicionamento do seu perfil na busca", "Alto"),
  createData("Taxas pagas à allminds", <CheckCircle />),
  createData("Primeiro atendimento", "1% a 3,99%*"),
  createData("Demais atendimentos", "1% a 3,99%*"),
];

export default function TablePlan({ ...props }) {
  const { handleModal, currentPlan, setFieldValue, values } = props;
  const [plan, setPlan] = useState(null);

  const dataCardsPlans = [
    // {
    //   tPlanName: "Gratuito",
    //   planName: "free",
    //   message: "Para quem quer testar a plataforma",
    // },
    {
      tPlanName: "Gestão",
      planName: "platinum",
      message: "Para profissionais experientes",
    },
  ];

  useEffect(() => {
    const handlePlanName = () => {
      const getPlan = dataCardsPlans?.find(
        item => item.planName === currentPlan?.attributes?.planName,
      )?.planName;

      const handlePlan = getPlan || currentPlan?.attributes?.planName || "free";

      if (["free", "platinum"].includes(handlePlan)) {
        setPlan(getPlanName(handlePlan));
        setFieldValue("planName", getPlanName(handlePlan));
      } else {
        toast.warning(
          "Seu plano não está mais disponível! Por gentileza atualize seu plano de assinatura.",
          {
            autoClose: false,
          },
        );
      }
    };
    handlePlanName();
  }, []);

  return (
    <TableContainer
      style={{
        boxShadow: "none",
      }}
      component={Paper}
    >
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            {dataCardsPlans?.map(plans => (
              <TableCell key={plans.planName} align="center">
                <UserSubscribe
                  plans={plans}
                  plan={plan}
                  handleModal={handleModal}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TableRow
              key={row.name}
              sx={{
                borderTop:
                  row.name.includes("compra trimestral") && "2px solid #000",
              }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell
                align="center"
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "700",
                  justifyContent: "center",
                  minWidth: "20rem",
                }}
              >
                {row.col1}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
