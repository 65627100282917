export default function getProfessionName(value, targetLang) {
  if (targetLang === "toPt") {
    switch (value?.toLowerCase()) {
      case "psychologist":
        return "Psicólogo(a)";
      case "psychiatrist":
        return "Psiquiatra";
      case "psychopedagogue":
        return "Psicopedagogo(a)";
      case "neuropediatrician":
        return "Neuropediatra";
      case "professor":
        return "Professor(a)";
      case "professional_advisor":
        return "Orientador(a) Profissional";
      default:
        return value;
    }
  } else if (targetLang === "toEng") {
    switch (value?.toLowerCase()) {
      case "psicólogo(a)":
        return "psychologist";
      case "psiquiatra":
        return "psychiatrist";
      case "psicopedagogo(a)":
        return "psychopedagogue";
      case "neuropediatra":
        return "neuropediatrician";
      case "professor(a)":
        return "professor";
      case "orientador(a) profissional":
        return "professional_advisor";
      default:
        return value;
    }
  }
}
