/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button as MUIButton, Modal, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { BLOCK_TYPE, DraftailEditor, INLINE_STYLE } from 'draftail';
import React, { useEffect, useState } from 'react';
import { Document, pdfjs } from 'react-pdf';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/auth';
import img from '../../../images/postImg.png';
import api from '../../../services/api';
import { DashboardProfileBanner } from '../Dashboard';
import './styles.css';
import {
  BodyWrapper,
  Button,
  Dashboard,
  ImageWrapper,
  MainContainer,
  PostImage,
  PostLightFont,
  PostRegularFont,
  PostSmallFont,
  PostTitle,
  StyledPage,
  TextWrapper,
} from './styles.js';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ContentShow() {
  const { user } = useAuth();
  const history = useHistory();
  const { id } = useParams() || null;
  const [schedules, setSchedules] = useState([]);
  const [post, setPost] = useState({});
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file64, setFile64] = useState(null);
  const [modal, setModal] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  const handleModal = (num) => {
    if (num === '1') {
      history.goBack();
      setModal(true);
    } else if (num === '2') {
      history.push('/professional/subscription');
      setModal(true);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    const controllerSchedule = new AbortController();
    const controllerPost = new AbortController();

    const fetchSchedules = async () => {
      try {
        const { data } = await api.get(`/${type}/schedules`, {
          signal: controllerSchedule.signal,
        });
        setSchedules(data.data);
      } catch (erro) {
        if (!controllerSchedule.signal.aborted)
          toast.error('Não foi possível carregar os agendamentos');
      }
    };

    const fetchPost = async () => {
      try {
        const { data } = await api.get(`/professionals/posts/${id}`, {
          signal: controllerPost.signal,
        });
        setPost(data.data.attributes);
        setIsPremium(data.data.attributes.isPremium);
        if (
          String(data.data.attributes.body).startsWith(
            'data:application/pdf;base64,'
          )
        ) {
          setFile64(data.data.attributes.body);
          setIsFile(true);
        } else {
          setEditorContent(data.data.attributes.body);
          setIsFile(false);
        }
      } catch (erro) {
        if (!controllerPost.signal.aborted)
          toast.error('Não foi possível carregar o conteúdo');
      }
    };
    fetchSchedules();
    fetchPost();

    return () => {
      controllerSchedule.abort();
      controllerPost.abort();
    };
  }, [user, id]);

  useEffect(() => {
    if (editorContent) {
      try {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(editorContent))
          )
        );
      } catch (error) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(editorContent)
          )
        );
      }
    }
  }, [editorContent]);

  useEffect(() => {
    if (isPremium && user?.subscription?.pagarmePlan === 'free') {
      setModal(true);
    }
  }, [isPremium]);

  return (
    <div style={{ width: '100%' }}>
      <Dashboard>
        <DashboardProfileBanner
          user={user}
          schedules={schedules}
          profileBannerStyle={{ borderRadius: '0% 0% 50% 50%/ 0% 0% 0% 0%' }}
        />
      </Dashboard>
      <ImageWrapper>
        <PostImage image={img} />
      </ImageWrapper>
      <MainContainer>
        {!modal && user?.subscription?.pagarmePlan === 'free' && isPremium && (
          <BodyWrapper>
            <TextWrapper style={{ alignItems: 'center' }}>
              <PostTitle>{post.title}</PostTitle>
              <PostSmallFont>{post.title}</PostSmallFont>
              <PostLightFont>{post.time || '5 minutos'}</PostLightFont>
              <PostRegularFont>
                {'Conteúdo exclusivo para Assinantes'}
              </PostRegularFont>
              {/* <SituationContent></SituationContent> */}
              {/* <PostRegularFont></PostRegularFont> */}
            </TextWrapper>
          </BodyWrapper>
        )}
        {((modal && user?.subscription?.pagarmePlan === 'free' && isPremium) ||
          (!modal && user?.subscription?.pagarmePlan !== 'free') ||
          (!modal &&
            user?.subscription?.pagarmePlan === 'free' &&
            !isPremium)) && (
          <BodyWrapper>
            <TextWrapper style={{ alignItems: 'center' }}>
              <PostTitle>{post.title}</PostTitle>
              <PostSmallFont>{post.title}</PostSmallFont>
              <PostLightFont>{post.time || '5 minutos'}</PostLightFont>
              {isFile === false && (
                <DraftailEditor
                  editorState={editorState}
                  readOnly={true}
                  blockTypes={[
                    { type: BLOCK_TYPE.HEADER_TWO },
                    { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
                    { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
                    { type: BLOCK_TYPE.BLOCKQUOTE },
                  ]}
                  inlineStyles={[
                    { type: INLINE_STYLE.BOLD },
                    { type: INLINE_STYLE.ITALIC },
                    { type: INLINE_STYLE.UNDERLINE },
                    { type: INLINE_STYLE.STRIKETHROUGH },
                    { type: INLINE_STYLE.SUBSCRIPT },
                    { type: INLINE_STYLE.SUPERSCRIPT },
                    { type: INLINE_STYLE.QUOTATION },
                  ]}
                />
              )}
              {isFile === true && (
                <div style={{ alignContent: 'center', width: '100%' }}>
                  <Document file={file64} onLoadSuccess={onDocumentLoadSuccess}>
                    <Grid container item xs={12} justifyContent="space-between">
                      <Button onClick={previousPage}>{'<<<'}</Button>
                      <p>
                        Página {pageNumber} de {numPages}
                      </p>
                      <Button onClick={nextPage}>{'>>>'}</Button>
                    </Grid>

                    <StyledPage
                      scale={5}
                      renderTextLayer={false}
                      pageNumber={pageNumber}
                    />

                    <Grid container item xs={12} justifyContent="space-between">
                      <Button onClick={previousPage}>{'<<<'}</Button>
                      <p>
                        Página {pageNumber} de {numPages}
                      </p>
                      <Button onClick={nextPage}>{'>>>'}</Button>
                    </Grid>
                  </Document>
                </div>
              )}
              {/* <PostRegularFont>{post.body}</PostRegularFont> */}
              {/* <SituationContent></SituationContent> */}
              {/* <PostRegularFont></PostRegularFont> */}
            </TextWrapper>
          </BodyWrapper>
        )}
        {modal && (
          <Modal open onClose={() => setModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '80%',
                maxHeight: '90%',
                width: '300px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2.5,
              }}
            >
              <Typography variant="h6" sx={{ mt: 2 }}>
                {' '}
                O conteúdo deste página é exclusivo para assinantes{' '}
              </Typography>

              <Grid
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <MUIButton
                  sx={{ m: 1 }}
                  onClick={() => {
                    handleModal('1');
                  }}
                  size="small"
                  variant="contained"
                >
                  Retornar
                </MUIButton>
                <MUIButton
                  sx={{ m: 1 }}
                  onClick={() => handleModal('2')}
                  size="small"
                  variant="contained"
                >
                  Atualizar assinatura
                </MUIButton>
              </Grid>
            </Box>
          </Modal>
        )}
      </MainContainer>
    </div>
  );
}
