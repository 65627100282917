import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .-title {
    margin: 1em 0;
    text-align: center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.pink};
    font-weight: 400;
    line-height: 27px;
    color: ${({ theme }) => theme.pink};
  }
  img {
    align-self: center;
    margin-bottom: 20px;
    height: 46px;
    width: 141px;
  }
`;
