export const getPreferencialPayments = (type) => {
  switch (type) {
    case 'pix':
      return 'Pix';
    case 'credit_card':
      return 'Cartão de Crédito';
    case 'all_payment':
      return 'Todos os Pagamentos';
    default:
      return 'Não informado';
  }
};
