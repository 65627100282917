/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { DefaultContentCard } from '../../../components/ContentsCards';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useAxiosGet from '../../../hooks/useAxiosGet';
import EmptyContainer from '../EmptyContainer';
import { DefaultContainer, GridLayout } from '../styles';
import ModalArticle from './ModalArticle/indes';

export default function ContentsArticles({ search }) {
  const { user } = useAuth();
  const [articles, setArticles] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    item: {},
  });
  const getArticlesApi = useAxiosGet();

  const filteredArticles = articles?.filter((item) =>
    search === ''
      ? item
      : item?.attributes?.title?.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    const getArticles = async () => {
      const { data, fetchData, errors } = getArticlesApi;
      await fetchData(`/${user?.type}s/posts/articles`);

      if (errors) toast.error('Erro ao carregar artigos');

      if (data) setArticles(data);
    };
    getArticles();

    return () => getArticlesApi.controller.abort();
  }, [getArticlesApi.loading]);

  return (
    <DefaultContainer>
      {getArticlesApi.loading ? (
        <LoaderSpinner logo />
      ) : filteredArticles?.length ? (
        <GridLayout>
          {filteredArticles?.map((article) => {
            const item = {
              id: article.id,
              ...article.attributes,
              file64: String(article.attributes.body).startsWith(
                'data:application/pdf;base64,'
              )
                ? article.attributes.body
                : null,
            };
            return (
              <DefaultContentCard
                key={item.id}
                item={item}
                label="Abrir Artigo"
                onClick={() => setModal({ open: true, item })}
              />
            );
          })}
        </GridLayout>
      ) : (
        <EmptyContainer title="Você não tem artigos diponíveis no momento." />
      )}

      {modal.open && (
        <ModalArticle setModal={setModal} modal={modal} article={modal.item} />
      )}
    </DefaultContainer>
  );
}
