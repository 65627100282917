/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormikControl from '../../../../components/FormikControl';
import { SubscribeFormik } from '../../../../components/FormikElements/SubscribeForm';
import { NewSelect } from '../../../../components/SelectList';
import useAuth from '../../../../hooks/auth';
import api from '../../../../services/api';
import theme from '../../../../theme';
import { getPreferencialPayments } from '../../../../utils/preferencialPayments';
import { ConfigWrapper, ContentContainer, RowLine, Titleh1 } from '../styles';

export function UpdateTypesReceipts({ form, user, validateInfos }) {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    form;

  const preferentialPaymentTypes = useMemo(() => {
    if (values?.clinicId) {
      switch (values?.preferentialPayment) {
        case 'pix':
          return ['pix'];
        case 'credit_card':
          return ['credit_card'];
        default:
          return ['pix', 'credit_card', 'all_payment'];
      }
    }
    return ['pix', 'credit_card', 'all_payment'];
  }, [values]);

  const getPymentMethod = (type) => {
    switch (type) {
      case 'pix': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', false);
        return;
      }
      case 'credit_card': {
        setFieldValue('acceptCredit', true);
        setFieldValue('acceptPix', false);
        return;
      }
      case 'all_payment': {
        setFieldValue('acceptPix', true);
        setFieldValue('acceptCredit', true);
        return;
      }
      default: {
        setFieldValue('acceptPix', false);
        setFieldValue('acceptCredit', false);
      }
    }
  };

  const isPlatinum = user?.subscription?.pagarmePlan === 'platinum';

  useEffect(() => {
    const validateInfo =
      user?.acceptCredit === values?.acceptCredit &&
      user?.acceptPix === values?.acceptPix &&
      user?.prePaid === values?.prePaid &&
      user?.postPaid === values?.postPaid &&
      user?.externalPayment === values?.externalPayment &&
      user?.postPaidDay === values?.postPaidDay;
    validateInfos(validateInfo);
    getPymentMethod(values?.preferentialPayment);
  }, [values]);

  return (
    <ConfigWrapper>
      <div style={{ padding: '0 10px 20px 0' }}>
        <Titleh1>Formas de Recebimento</Titleh1>
        <RowLine />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0 10px 20px 0',
        }}
      >
        {(isPlatinum || values?.clinicPrePaid) && (
          <FormControlLabel
            name="prePaid"
            control={<Checkbox checked={values.prePaid} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento PRÉ-PAGO (Padrão)
              </Typography>
            }
            onChange={handleChange}
          />
        )}
        {(isPlatinum || values?.clinicPostPaid) && (
          <FormControlLabel
            name="postPaid"
            control={<Checkbox checked={values.postPaid} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento PÓS-PAGO
              </Typography>
            }
            onChange={handleChange}
          />
        )}
        {/* {(isPlatinum || values?.clinicExternalPayment) && (
          <FormControlLabel
            name="externalPayment"
            control={<Checkbox checked={values.externalPayment} />}
            label={
              <Typography style={{ fontWeight: 'bold' }}>
                Aceitar pagamento EXTERNO
              </Typography>
            }
            onChange={handleChange}
          />
        )} */}
      </div>

      {values.postPaid && (
        <FormikControl
          control="input"
          disabled={values?.clinicId}
          errors={errors.postPaidDay}
          label="Dia de cobrança para agendamentos pós-pagos"
          name="postPaidDay"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.postPaidDay}
          type="number"
          upLabel
          value={values?.postPaidDay}
        />
      )}

      <div
        style={{
          marginTop: '2rem',
        }}
      >
        <NewSelect
          bgColor={theme.white}
          defaultValue={values.preferentialPayment}
          errors={errors.preferentialPayment}
          label="Formas de recebimentos"
          name="preferentialPayment"
          onChange={({ value }) => {
            setFieldValue('preferentialPayment', value);
          }}
          options={preferentialPaymentTypes?.map((type) => {
            return { label: getPreferencialPayments(type), value: type };
          })}
          placeholder="Selecione a forma de recebimento"
          touched={touched.preferentialPayment}
        />
      </div>
    </ConfigWrapper>
  );
}

export default function PaymentConfig() {
  const history = useHistory();
  const { user, setAuthData } = useAuth();

  const handleContinue = async (values) => {
    try {
      const {
        planName,
        cardNumber,
        cardCvv,
        cardExpiration_date,
        cardHolderName,
      } = values;
      if (
        (planName === 'gold' || planName === 'platinum') &&
        (cardNumber?.length < 16 ||
          cardCvv?.length < 3 ||
          cardExpiration_date?.length < 4)
      ) {
        toast.error('Dados do cartão são requeridos.');
      } else {
        if (values.planName === 'Gratuito') {
          const subscription = {
            data: {
              type: 'subscription',
              attributes: {
                planName: 'free',
              },
            },
          };
          if (user.subscription.id) {
            const { data } = await api.put(
              `professionals/subscriptions/${user.subscription.id}`,
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          } else {
            const { data } = await api.post(
              'professionals/subscriptions',
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          }
          toast.success(
            `Plano gratuito selecionado! Por favor autentique-se novamente.`
          );
        } else {
          const subscription = {
            data: {
              type: 'subscription',
              attributes: {
                planName,
                creditCardAttributes: {
                  cardNumber,
                  cardExpiration_date,
                  cardHolderName,
                  cardCvv,
                },
              },
            },
          };
          if (user.subscription.id) {
            const { data } = await api.put(
              `professionals/subscriptions/${user.subscription.id}`,
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          } else {
            const { data } = await api.post(
              'professionals/subscriptions',
              subscription
            );
            setAuthData({ ...user, subscription: data.data.attributes });
          }
          if (planName === 'gold') {
            toast.success(
              `Plano Ouro assinado com sucesso! Por favor autentique-se novamente.`
            );
          } else if (planName === 'platinum') {
            toast.success(
              `Plano Gestão assinado com sucesso! Por favor autentique-se novamente.`
            );
          }
        }
        history.push('/profissional/conectar-se');
      }
    } catch (error) {
      toast.error('Não foi possível cadastrar assinatura');
    }
  };

  return (
    <ContentContainer>
      <SubscribeFormik
        subscribe={user.subscription}
        handleContinue={handleContinue}
      />
    </ContentContainer>
  );
}
