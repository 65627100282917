/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Chart } from 'react-google-charts';
import { toast } from 'react-toastify';
import FormikControl from '../../../components/FormikControl';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import ClinicBanner from '../../../images/banner-clinic.png';
import LogoClinic from '../../../images/logo-default-clinic.svg';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import {
  convertKeyLabel,
  getLabelDashboardClinic,
} from '../../../utils/getLabelDashboardClinic';
import { BackgroundBanner } from '../Profile/styles';
import {
  BannerImage,
  ButtonFilterParameter,
  ButtonFilterWrapper,
  CardTitle,
  CardValue,
  ClinicImage,
  CloseButton,
  ContainerImage,
  FilterWrapper,
  GraficWrapper,
  MainCard,
  MainDashboard,
  MainScroll,
} from './styles';

function CardData({ title, value }) {
  const serializedValue = (title, value) => {
    if (title.toLowerCase().includes('receita') && !title.includes('%')) {
      return convertCurrencyPtBr(value);
    }
    if (title.includes('%')) {
      return `${value}%`;
    }
    return value;
  };

  return (
    <MainCard gridarea={convertKeyLabel(title, 'toEng')}>
      <CardTitle>{title}</CardTitle>
      <CardValue>{serializedValue(title, value)}</CardValue>
    </MainCard>
  );
}

export default function ClinicDashboard() {
  const { user, updateClinic } = useAuth();
  const [loading, setLoading] = useState(true);
  const [parameter, setParameter] = useState('totalRevenue');
  const [parameterFilter, setParameterFilter] = useState('year');
  const [overview, setOverview] = useState({});
  const [statistics, setStatistics] = useState({});
  const [dataGrafic, setDataGrafic] = useState([['', '']]);

  useEffect(() => {
    const controller = new AbortController();

    const confirmRemainingDaysTrial = async () => {
      try {
        const { data } = await api.get('/clinics/plan');

        const remainingDaysTrial = data.data.attributes.trialDaysLeft - 1;
        if (remainingDaysTrial >= 0) {
          toast.info(
            `Seu período de teste expira em ${data.data.attributes.trialDaysLeft} dias.`,
            {
              autoClose: false,
              closeButton: (
                <CloseButton>
                  <X />
                </CloseButton>
              ),
            }
          );
        } else {
          toast.info(
            <Fragment>
              Seu período de teste expirou. Seu plano foi atualizado e será
              realizado o pagamento da mensalidade.
            </Fragment>,
            {
              autoClose: false,
              closeButton: (
                <CloseButton>
                  <X />
                </CloseButton>
              ),
            }
          );
          await updateNotificationTrial();
        }
      } catch (error) {
        console.error(error);
        toast.error('Erro ao verificar período de teste.');
      }
    };
    if (user?.trialNoticeSeen === false) confirmRemainingDaysTrial();

    const getDataClinic = async () => {
      try {
        const { data } = await api.get('/clinics/reports', {
          signal: controller.signal,
        });
        setOverview(data.data.reports.overview);
        setStatistics(data.data.reports.statistics);
        filterYear(data.data.reports.statistics['last12Months']);
      } catch (error) {
        if (!controller.signal.aborted) {
          toast.error('Erro ao carregar dados da clínica.');
        }
      } finally {
        setLoading(false);
      }
    };
    getDataClinic();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (statistics.last12Months) {
      selectFilterDate(parameterFilter);
    }
  }, [parameter]);

  async function updateNotificationTrial() {
    try {
      await updateClinic(
        {
          trialNoticeSeen: true,
        },
        user?.id
      );
    } catch (error) {
      toast.error('Erro ao atualizar notificação de período de teste.');
    }
  }

  function onChangeParameter({ target }) {
    setParameter(target.value);
  }

  function onChangeParameterFilter({ target }) {
    selectFilterDate(target.value);
  }

  function filterWeek(parameterFilter) {
    const dataWeek = parameterFilter.map((param) => param).slice(-7);
    const graficWeek = dataWeek.map((week) => [
      PtBr.days_name_convert[week.day],
      week[parameter],
    ]);
    setDataGrafic([['', ''], ...graficWeek]);
  }

  function filterMonth(parameterFilter) {
    const dataMonth = parameterFilter.map((param) => param);
    const graficMonth = dataMonth.map((month) => [
      moment(month.date).format('DD/MM'),
      month[parameter],
    ]);
    setDataGrafic([['', ''], ...graficMonth]);
  }

  function filterSixMonth(parameterFilter) {
    const dataSixMonth = parameterFilter.map((param) => param).slice(-6);
    const graficSixMonth = dataSixMonth.map((sixMonth) => [
      PtBr.months_name_convert[sixMonth.month],
      sixMonth[parameter],
    ]);
    setDataGrafic([['', ''], ...graficSixMonth]);
  }

  function filterYear(parameterFilter) {
    const dataYear = parameterFilter.map((param) => param);
    const graficYear = dataYear.map((year) => [
      PtBr.months_name_convert[year.month] + ' / ' + year.year,
      year[parameter],
    ]);
    setDataGrafic([['', ''], ...graficYear]);
  }

  function selectFilterDate(dateFilter) {
    setParameterFilter(dateFilter);
    switch (dateFilter) {
      case 'week':
        return filterWeek(statistics['last30Days']);
      case 'month':
        return filterMonth(statistics['last30Days']);
      case 'sixMonths':
        return filterSixMonth(statistics['last12Months']);
      case 'year':
        return filterYear(statistics['last12Months']);
      default:
        return filterYear(statistics['last12Months']);
    }
  }

  const overviewFiltered = Object.keys(overview)
    .filter((over) => over !== 'percentRevenueExternal')
    .reduce((acc, curr) => {
      acc[curr] = overview[curr];
      return acc;
    }, {});

  const overviewCards = getLabelDashboardClinic(overviewFiltered);

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <MainScroll>
      <ContainerImage>
        {!user?.bannerColor ? (
          <BannerImage src={user?.banner || ClinicBanner} alt="Banner" />
        ) : (
          <BackgroundBanner color={user?.bannerColor} />
        )}
        {user?.bannerColor && <ClinicImage src={user?.avatar || LogoClinic} />}
      </ContainerImage>
      <MainDashboard>
        {overviewCards.map((item) => (
          <CardData key={item.label} title={item.label} value={item.value} />
        ))}
      </MainDashboard>
      <FilterWrapper>
        <FormikControl
          control="selectMultLang"
          defaultValue={{ value: 'totalRevenue', label: 'Receita total' }}
          label="Escolha um parâmetro"
          name="parameter"
          onChange={onChangeParameter}
          options={Object.entries(PtBr.dashboard_filter_select_options).map(
            ([value, label]) => ({ value, label })
          )}
          styles={{ width: '40%' }}
          value={parameter}
        />
        <ButtonFilterWrapper>
          <ButtonFilterParameter
            active={parameterFilter === 'week'}
            value="week"
            onClick={onChangeParameterFilter}
          >
            Semana
          </ButtonFilterParameter>
          <ButtonFilterParameter
            active={parameterFilter === 'month'}
            value="month"
            onClick={onChangeParameterFilter}
          >
            Mês
          </ButtonFilterParameter>
          <ButtonFilterParameter
            active={parameterFilter === 'sixMonths'}
            value="sixMonths"
            onClick={onChangeParameterFilter}
          >
            6 meses
          </ButtonFilterParameter>
          <ButtonFilterParameter
            active={parameterFilter === 'year'}
            value="year"
            onClick={onChangeParameterFilter}
          >
            Ano
          </ButtonFilterParameter>
        </ButtonFilterWrapper>
      </FilterWrapper>

      {/* Gráfico */}
      <GraficWrapper>
        <Chart
          chartType="Bar"
          data={dataGrafic}
          width="100vw"
          height="400px"
          legendToggle
          options={{
            colors: ['#999EBC'],
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: false,
                text: 'Dataset',
              },
            },
          }}
        />
      </GraficWrapper>
    </MainScroll>
  );
}
