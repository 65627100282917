import React from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/auth";
import LogoMySelf from "../../images/logo-blue.png";

export function LeaveScreen({ setIsMeetingLeft }) {
  const history = useHistory();
  const { user } = useAuth();

  return (
    <div className="h-[85vh] flex flex-col flex-1 items-center justify-center pt-5 pb-5">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-[#0C1B5C] font-bold text-2xl md:text-4xl bg-red">
          Você saiu da reunião!
        </h1>
        <img
          src={LogoMySelf}
          className="h-auto w-full md:w-[40vw] object-cover flex items-center justify-center flip"
          alt="Logo"
        />
        <button
          className="border text-[#0C1B5C] px-16 py-3 rounded-lg text-2xl underline"
          onClick={() => {
            setIsMeetingLeft(false);
          }}
        >
          Voltar para sala de espera
        </button>
        <button
          className="mt-5 border text-[#0C1B5C] px-16 py-3 rounded-lg text-4xl underline"
          onClick={() => {
            setIsMeetingLeft(false);
            history.push(`/${user.type}/dashboard`);
          }}
        >
          Voltar para a Home
        </button>
      </div>
    </div>
  );
}
