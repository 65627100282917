import { Box } from "@material-ui/core";
import { Check } from "react-feather";
import styled from "styled-components";
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  ScrollWrapper,
} from "../../../../styles/globalStyles";

export const BoxModalWrapper = styled(Box)`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 50%;
  max-height: 65%;
  max-width: 60%;
  padding: 2.5rem;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    max-width: 80%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 550px;
    max-height: 90%;
    max-width: 90%;
    padding: 1rem;
  }
`;

export const BoxWrapper = styled.div`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
`;

export const BoxFilter = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  .--arrows {
    color: #b0b4c2;
    flex-shrink: 0;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  &:hover {
    .--arrows {
      color: #040817;
    }
  }
`;

export const ButtonFilter = styled.button`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 0.3rem;
  border: 1px solid #eaecf4;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: ${({ selected }) =>
    selected ? "space-between" : "flex-end"};
  min-height: 3.5rem;
  width: 100%;
`;

export const Hr = styled.hr`
  border: 1px solid #bcc1e0;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin-left: 0.2rem;
  width: 100%;

  .--icon {
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 1.3rem;
    margin-left: 0.325rem;
    width: 1.3rem;
  }
`;

export const LabelFilter = styled.label`
  align-items: center;
  background-color: #426aff;
  border-radius: 5rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: 500;
  justify-content: center;
  margin: 0 0.25rem;
  min-height: 2.2rem;
  padding: 0.25rem 0.7rem;
`;

export const ButtonFilterApply = styled.button`
  align-items: center;
  background-color: #426aff;
  border-radius: 30px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  height: 41px;
  justify-content: center;
  padding: 10px 20px;
  width: 304px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 250px;
  }
`;

export const ButtonFilterWrapper = styled.button`
  align-items: center;
  background-color: #f4f5fa;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0rem;
  width: 100%;
  cursor: default;
  border-radius: 1rem;
`;

export const FilterWrapper = styled.section`
  align-items: flex-start;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 2rem 3rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const FilterWrapperModal = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .--modal-wrapper {
    align-items: flex-start;
    border: none;
    display: flex;
  }

  .--modal-label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    word-wrap: break-word;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
    .--modal-wrapper {
      margin: 1rem 0;
    }
  }
`;

export const FilterWrapperModalProfession = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.input`
  align-items: center;
  background-color: #f4f5fa;
  border-radius: 0.5rem;
  border: 1px solid #eaecf4;
  color: ${({ theme }) => theme.darkGray};
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 3rem;
  justify-content: center;
  padding: 10px 20px;
  width: 95%;

  &::placeholder {
    font-size: 18x;
    font-weight: 27px;
    font-weight: 400;
    color: #999ebc;
  }
`;

export const InputText = styled.input`
  background-color: #f4f5fa;
  border: 1px solid #eaecf4;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.darkGray};
  display: flex;
  font-weight: 400;
  height: 3rem;
  padding-left: 0.5rem;
  width: 95%;

  &::placeholder {
    font-size: 18x;
    font-weight: 27px;
    font-weight: 400;
    color: #999ebc;
  }
`;

export const BoxLabel = styled.label`
  width: 95%;
  font-size: 0.9rem;
  text-align: left;
  line-height: 21px;
  color: ${({ theme }) => theme.black};
`;

export const OptionButton = styled.button`
  flex: 1;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 44px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary : theme.primaryAlt};
  border: 1px solid ${({ theme }) => theme.primary};
  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: ${({ theme, checked }) =>
      checked ? theme.primaryAlt : theme.primary};
  }
`;

export const OptionButtonGrid = styled.section`
  width: 100%;
  display: grid;
  padding: 10px 0;
  background-color: transparent;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

export const OptionCheckBoxGrid = styled.section`
  background-color: transparent;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: ${({ secondary }) =>
    secondary === "true" ? "repeat(2, minmax(100px, 1fr))" : "100px, 1fr"};
  overflow-y: visible;
  padding: 0.5rem 0;
  justify-items: flex-start;
  width: ${({ secondary }) => (secondary === "true" ? "95%" : "100%")};
`;

export const CheckBox = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1rem;
  background-color: transparent;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 0.325rem;
    border: 1px solid
      ${({ theme, checked }) => (checked ? theme.primary : "#BCC1E0")};
    background-color: ${({ theme, checked }) =>
      checked ? theme.primary : "#EAECF4"};
  }

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: ${({ theme }) => theme.secondary};
  }
`;

export const CheckMark = styled(Check)`
  width: 15px;
  height: 15px;
  color: ${({ theme, checked }) =>
    checked ? theme.primaryAlt : "transparent"};
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  padding: 0.5rem 0.5rem 0 0;
  width: 100%;
  cursor: default;
`;

export const ScrollBar = styled(ScrollWrapper)`
  animation: none;
  margin-top: 2rem;
  height: 50vh;
  border-radius: 0.25rem 0.25rem 0 0;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 60vh;
  }
`;

export const EmptyContainerFilter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: 3 / 3;
  height: 100%;
  justify-content: flex-end;
  padding-top: 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-area: 4 / 2;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-area: 7 / 1;
  }
`;

export const EmptyFilter = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary_blue};
  border-radius: 2rem;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  height: 2.5rem;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
`;
