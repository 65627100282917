import styled from 'styled-components';
import { BigFont, BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const ConfigMain = styled.form`
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.newPrimaryAlt} inset !important;
  }
`;

export const ConfigBody = styled.section`
  width: 100%;
`;

export const RowLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ExtralightGray};
  margin-top: 10px;
  margin-bottom: 17px;
`;

export const MenuHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuOption = styled.button`
  background-color: ${({ active }) => (active ? '#040817' : '#fff')};
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  position: relative;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 0.325rem;
    margin-right: 1rem;
  }
`;

export const OptionText = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.black)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;
