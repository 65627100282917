import React, { Suspense } from 'react';
import LoaderSpinner from '../components/LoaderSpinner';
import DefaultRoutes from './routes';

export default function Router() {
  return (
    <Suspense fallback={<LoaderSpinner />}>
      <DefaultRoutes />
    </Suspense>
  );
}
