import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { PlansCard } from '../../../components/ListItens';
import api from '../../../services/api';
import {
  MainContainer,
  MenuHeader,
  MenuOption,
  OptionText,
  ScheduleWrapper,
} from './styles';
import { Hr } from '../../Public/Professionals/components/styles';

const options = ['Pacotes', 'Planos de Assinaturas'];

export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
        >
          <OptionText active={selected === item}>{item}</OptionText>
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

export default function PackagesPlans() {
  const [selected, setSelected] = useState('Pacotes');
  const [plans, setPlans] = useState([]);

  const getPlans = useCallback(async () => {
    try {
      const { data } = await api.get('clients/appointment_subscriptions');
      if (data?.data) {
        setPlans(data.data);
      }
    } catch (error) {
      toast.error('Não foi possível carregar os planos de assinaturas ativos');
    }
  }, []);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  return (
    <MainContainer>
      <TopMenuBar
        options={options}
        setSelected={setSelected}
        selected={selected}
      />

      <Hr />

      {selected === 'Planos de Assinaturas' && (
        <ScheduleWrapper>
          <Flatlist
            data={plans}
            dateSearch
            filterObject={(plan) => plan.attributes.search}
            placeholder="Buscar Planos de Assinaturas"
            renderItem={(plan, index) => (
              <PlansCard key={`${plan.id} ${index}`} plan={plan.attributes} />
            )}
            type="schedule"
            searchType="professional"
            // loading={getSchedules.loading || getPastSchedules.loading}
          />
        </ScheduleWrapper>
      )}
    </MainContainer>
  );
}
