export function handleVerifyMembers(user) {
  const visibility = [];
  if (user?.school) visibility.push('all-members');
  if (user?.schoolType === 'professor') visibility.push('members-professor');
  if (user?.type === 'client') visibility.push('all-clients');
  if (user?.type === 'professional') visibility.push('all-professionals');
  if (user?.subscription?.pagarmePlan.includes('platinum'))
    visibility.push('all-platinum');
  if (user?.subscription?.pagarmePlan.includes('free'))
    visibility.push('all-free');
  if (user?.type === 'client' && !user?.school) visibility.push('all-users');
  visibility.push('all');
  return visibility;
}
