import { toast } from 'react-toastify';

export default function handleErrors(errors, values) {
  let pageHasErrors = false;
  if (values?.length > 0) {
    values.forEach((value) => {
      if (errors?.[`${value}`]) {
        pageHasErrors = true;
        toast.error(errors[`${value}`], { autoClose: 10000 });
      }
    });
  } else {
    Object.keys(errors).forEach((value) => {
      pageHasErrors = true;
      toast.error(errors[`${value}`], { autoClose: 10000 });
    });
  }
  return pageHasErrors;
}
