/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material";
import React, { Fragment, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { DefaultContentCard } from "../../../components/ContentsCards";
import useAuth from "../../../hooks/auth";
import { getConvertTheme } from "../../../utils/getConvertTheme";
import { handleVerifyMembers } from "../../../utils/handleVerifyMembers";
import trimString from "../../../utils/trimString";
import EmptyContainer from "../EmptyContainer";
import { DefaultContainer, GridLayout } from "../styles";
import ModalLesson from "./ModalLesson/indes";
import {
  BackIcon,
  ButtonLesson,
  ButtonLessonText,
  ButtonLessonTitle,
  CourseContainer,
  CourseText,
  CourseTitle,
  CourseWrapper,
  ImgLesson,
  LessonsContainer,
  ModuleContainer,
  ModuleText,
  ModulesWrapper,
  RowLine,
} from "./styles";

export default function ContentCourses({ ...props }) {
  const { user } = useAuth();
  const { search, courses } = props;
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedModules, setSelectedModules] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLessons, setSelectedLessons] = useState(null);

  function handleClickCourse(course) {
    setSelectedCourse(course);
    const filteredModules = course.attributes.courseModules?.filter(module =>
      module?.visibility?.some(item =>
        handleVerifyMembers(user).includes(item),
      ),
    );
    setSelectedModules(filteredModules);
  }

  const filteredCourses = courses?.filter(course => {
    if (!search) return course;
    return course?.attributes?.title
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  return (
    <DefaultContainer>
      {!selectedCourse ? (
        filteredCourses?.length ? (
          <GridLayout>
            {filteredCourses?.map(course => (
              <DefaultContentCard
                key={course.id}
                item={course.attributes}
                label="Abrir Curso"
                onClick={() => handleClickCourse(course)}
              />
            ))}
          </GridLayout>
        ) : (
          <EmptyContainer title="Você não tem cursos diponíveis no momento." />
        )
      ) : (
        <Tooltip title="Voltar">
          <BackIcon onClick={() => setSelectedCourse(null)} />
        </Tooltip>
      )}

      {selectedCourse && (
        <CourseContainer>
          <CourseWrapper>
            <CourseTitle>{selectedCourse?.attributes?.title}</CourseTitle>
            <CourseText>{selectedCourse?.attributes?.description}</CourseText>
            <CourseTitle>
              {getConvertTheme(selectedCourse?.attributes?.theme)}
            </CourseTitle>
          </CourseWrapper>
          {selectedModules?.map(module => (
            <ModulesWrapper key={module.moduleId}>
              <ModuleContainer
                onClick={() => {
                  if (selectedModule?.moduleId === module.moduleId)
                    setSelectedModule(null);
                  else setSelectedModule(module);
                }}
                disabled={module.lessons?.length === 0}
              >
                <ModuleText>{module.title}</ModuleText>
                <AiOutlineArrowDown
                  style={{
                    transform:
                      selectedModule?.moduleId === module.moduleId
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "all 0.3s ease",
                  }}
                  size={28}
                  color="#fff"
                />
              </ModuleContainer>

              {selectedModule?.moduleId === module.moduleId && (
                <LessonsContainer selected={selectedModule}>
                  {module.lessons?.map((lesson, index) => {
                    const description = lesson.description
                      .slice(5, 14)
                      .includes("blocks")
                      ? JSON.parse(lesson.description)?.blocks[0]?.text
                      : lesson.description;
                    return (
                      <Fragment>
                        <ButtonLesson
                          key={lesson.title}
                          lastIndex={index === module.lessons?.length - 1}
                          onClick={() => setSelectedLessons(lesson)}
                        >
                          <ButtonLessonTitle>{lesson.title}</ButtonLessonTitle>
                          <ImgLesson src={lesson.image} alt="lesson-image" />
                          <ButtonLessonText>
                            <Tooltip title={description}>
                              {trimString(description, 200)}
                            </Tooltip>
                          </ButtonLessonText>
                        </ButtonLesson>
                        {index !== module.lessons?.length - 1 && <RowLine />}
                      </Fragment>
                    );
                  })}
                </LessonsContainer>
              )}
            </ModulesWrapper>
          ))}
        </CourseContainer>
      )}

      {selectedLessons && (
        <ModalLesson setModal={setSelectedLessons} lesson={selectedLessons} />
      )}
    </DefaultContainer>
  );
}
