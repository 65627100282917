import React from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'rsuite';
import LogoCli from '../../../../src/images/logo-navbar.png';
import { ContainerLogo, HeaderLogoImg, HeaderMain, HeaderNav } from './styles';

export default function HeaderPublic({ term, professionalsRef }) {
  const history = useHistory();

  return (
    <HeaderMain ref={professionalsRef}>
      {!term && (
        <Tooltip title="Voltar">
          <HeaderNav
            onClick={() => history.go(-1)}
            disabled={history.location.pathname === '/publica/profissionais'}
          >
            <ArrowLeft
              style={{ marginRight: '0.5rem' }}
              color="#fff"
              size={30}
            />
          </HeaderNav>
        </Tooltip>
      )}
      <ContainerLogo href="https://allminds.app/">
        <HeaderLogoImg src={LogoCli} />
      </ContainerLogo>
      <p style={{ color: '#fff' }}>
        A Allminds oferece profissionais qualificados de todo o Brasil, com
        sessões online ou presenciais.
      </p>
    </HeaderMain>
  );
}
