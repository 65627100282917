/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import HeaderPublic from '../../screens/Public/Header';
import { Container, Main } from './styles';

export default function SidebarPublic({ children }) {
  return (
    <Main>
      <HeaderPublic />
      <Container>{children}</Container>
    </Main>
  );
}
