import styled from 'styled-components';

export const StyledPage = styled.div`
  border: 1px solid ${({ theme }) => theme.lightGrey};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 0.7rem 0.3rem;
  width: 95%;

  .react-pdf__Page__canvas {
    width: 100% !important;
  }
`;
