export const validatePerson = (values) => {
  const validate = [
    values?.firstName,
    values?.lastName,
    values?.birthday,
    values?.phone,
    values?.documentNumber,
  ].map((value) => value === '' || value === undefined || value === null);

  return validate.some((value) => value === true);
};

export const validateAddress = (values) => {
  const validate = [
    values?.street,
    values?.street_number,
    values?.neighborhood,
    values?.cep,
    values?.country,
    values?.state,
    values?.city,
  ].map((value) => value === '' || value === undefined || value === null);

  return validate.some((value) => value === true);
};

export const validateInfoLegalGuardian = (values) => {
  const validate = [
    values?.legalGuardianFullName,
    values?.legalGuardianEmail,
    values?.legalGuardianDocumentNumber,
    values?.legalGuardianBirthday,
    values?.legalGuardianPhone,
  ].map((value) => value === '' || value === undefined || value === null);

  return validate.some((value) => value === true);
};
