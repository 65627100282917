/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Library, PostList } from '../../../components/List';
import useAuth from '../../../hooks/auth';
import api from '../../../services/api';
import { DashboardProfileBanner } from '../Dashboard';
import { BodyWrapper, MainContainer } from './styles';
import { toast } from 'react-toastify';

export default function ContentList() {
  const { user } = useAuth();
  const { id } = useParams() || null;
  const [schedules, setSchedules] = useState([]);
  const [selected, setSelected] = useState(id);
  const defaultType = localStorage.getItem('type');
  const type = user.type || defaultType;

  useEffect(() => {
    const controller = new AbortController();
    const fetchSchedules = async () => {
      try {
        const { data } = await api.get(`/${type}s/schedules`, {
          signal: controller.signal,
        });
        setSchedules(data.data);
      } catch (erro) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar os agendamentos');
      }
    };
    fetchSchedules();

    return () => controller.abort();
  }, [user]);

  return (
    <MainContainer>
      <DashboardProfileBanner user={user} schedules={schedules} />
      <BodyWrapper>
        {selected ? (
          <Library id={selected} goBack={() => setSelected(null)} />
        ) : (
          <PostList onClick={(id) => setSelected(id)} />
        )}
      </BodyWrapper>
    </MainContainer>
  );
}
