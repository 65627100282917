import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  DollarSign,
  MapPin,
  MessageSquare,
  Trash,
  Video,
} from 'react-feather';
import styled from 'styled-components';
import { ReactComponent as ReportAlert } from '../../images/ReportAlertSVG.svg';
import { ReactComponent as ReportCheck } from '../../images/ReportCheckSVG.svg';
import { ReactComponent as EventCalendarIcon } from '../../images/event-calendar.svg';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ModalBoxWrapper,
  ScrollCardStyle,
  SmallFont,
} from '../../styles/globalStyles';
import { StaticButton } from '../Button';

export const Main = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: center;
  padding: 15px 10px;
  width: 95%;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  width: 100%;
`;

export const EventSmallText = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`;

export const CalendarIcon = styled(EventCalendarIcon)`
  color: ${({ theme }) => theme.primary};
  height: 36px;
  margin-bottom: 5px;
  margin-left: 8px;
  width: 36px;
`;

export const Event = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.offWhite};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  min-width: 250px;
  padding: 1rem;
  width: 100%;
`;

export const EventRowItem = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
`;

export const EventColumnItem = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const EventDate = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
`;

export const EventType = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  padding-bottom: 5px;
`;

export const EventProfessional = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;
`;

export const StartAttendence = styled(StaticButton)``;

export const ButtonsRow = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const DeleteButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color12};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
`;

export const DeleteButtonClinic = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
`;

export const MessageButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: 0 5px;
  padding: 5px;
`;

export const PlaceButton = styled(MessageButton)`
  color: ${({ theme }) => theme.primary};
  padding: 2px;
`;

export const PlaceButtonClinic = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 2px;
`;

export const PayButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, AdvancePayment }) => (AdvancePayment ? 'green' : theme.pink)};
  display: flex;
  justify-content: flex-end;
  margin: 0 5px;
  padding: 5px;
`;

export const EvaluateButton = styled.button`
  background-color: ${({ theme }) => theme.lightBlue};
  border-radius: 0.7rem;
  border: none;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-weight: 600;
  height: 2rem;
  width: 4rem;

  &:hover {
    background-color: ${({ theme }) => theme.primary_blue};
    color: ${({ theme }) => theme.white};
  }
`;

export const ReportButtonEvent = styled(MessageButton)`
  border: 1px solid ${({ theme }) => theme.pink};
`;

export const TrashIcon = styled(Trash)`
  color: ${({ theme }) => theme.color12};
  height: 18px;
  width: 18px;
`;

export const PayIcon = styled(DollarSign)`
  color: ${({ theme }) => theme.pink};
  height: 18px;
  width: 18px;
`;

export const MessageIcon = styled(MessageSquare)`
  color: ${({ theme }) => theme.primary};
  height: 18px;
  width: 18px;
`;

export const EventCalendar = styled(Calendar)`
  background-color: ${({ diary }) => (diary ? 'transparent' : '#f4f5fa')};
  border: none;
  font-family: 'Open-Sans', Helvetica, sans-serif;
  line-height: 1.125em;
  max-width: 100%;
  padding: ${({ diary }) => (diary ? '0' : '1rem')};
  width: 100%;

  .react-calendar__viewContainer {
    background-color: #f4f5f5;
    border-radius: 0.325rem;
    display: ${({ diary }) => (diary ? 'none' : 'flex')};
    padding: 0.5rem;
  }

  .react-calendar__navigation button {
    align-items: center;
    background: ${({ diary }) => (diary ? '#eaecf4' : '#bcc1e0')};
    border-bottom: 1px solid #eaecf4;
    border-top: 1px solid #eaecf4;
    color: #000;
    display: flex;
    font-size: 16px;
    justify-content: center;
    min-width: 44px;
    text-transform: uppercase;
  }
  .react-calendar__navigation__next-button {
    background-color: ${({ diary }) => (diary ? '#eaecf4' : '#bcc1e0')};
    border-bottom-right-radius: 0.325rem;
    border-bottom: 1px solid #eaecf4;
    border-right: 1px solid #eaecf4;
    border-top-right-radius: 0.325rem;
    border-top: 1px solid #eaecf4;
    border: none;
  }
  .react-calendar__navigation__prev-button {
    background-color: ${({ diary }) => (diary ? '#eaecf4' : '#bcc1e0')};
    border-bottom-left-radius: 0.325rem;
    border-bottom: 1px solid #eaecf4;
    border-left: 1px solid #eaecf4;
    border-top-left-radius: 0.325rem;
    border-top: 1px solid #eaecf4;
    border: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ diary }) => (diary ? '#eaecf4' : '#bcc1e0')};
  }
  .react-calendar__navigation button[disabled] {
    background-color: ${({ diary }) => (diary ? '#eaecf4' : '#bcc1e0')};
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar__tile {
    border-radius: 0.25rem;
    border: 1px solid #c0d1d2;
    color: #000;
    font-weight: 600;
  }
  .react-calendar__tile[disabled] {
    background-color: #dce4e8;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #426aff;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
  }
  .react-calendar__tile--now {
    background: #426aff33;
    border-radius: 6px;
    color: #426aff;
    font-weight: bold;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #426aff33;
    border-radius: 6px;
    color: #426aff;
    font-weight: bold;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
  }
  .react-calendar__tile--active {
    background: #426aff;
    border-radius: 6px;
    color: white;
    font-weight: bold;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #426aff;
    color: white;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
  }
  .react-calendar__tile--range {
    background: #f8f8fa;
    border-radius: 0;
    color: #426aff;
  }
  .react-calendar__tile--rangeStart {
    background: #426aff;
    border-radius: 6px;
    color: white;
  }
  .react-calendar__tile--rangeEnd {
    background: #426aff;
    border-radius: 6px;
    color: white;
  }
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.black};
`;
export const ArrowRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.black};
`;

export const TimeCalendar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const TimeCalendarHeader = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const ButtonCalendar = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
`;

export const HeaderContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
`;

export const TimeCalendarContainer = styled.section`
  align-items: center;
  display: grid;
  flex: 1;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
`;

export const TimeTile = styled.button`
  align-items: center;
  background-color: ${({ available, selected }) => {
    if (selected) return '#426AFF';
    if (available === 'available') return '#fff';
    return '#dce4e8';
  }};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  cursor: ${({ available }) =>
    available === 'available' ? 'pointer' : 'default'};
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export const TileLabel = styled.label`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.black)};
  cursor: ${({ available }) =>
    available === 'available' ? 'pointer' : 'default'};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;

export const HeaderTitle = styled(BigFont)`
  color: ${({ theme }) => theme.black};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;
export const HeaderText = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`;

export const ArrowLeftPrimary = styled(ArrowLeft)`
  color: ${({ theme }) => theme.primary};
`;

export const ArrowRightPrimary = styled(ArrowRight)`
  color: ${({ theme }) => theme.primary};
`;
// event calendar

export const ButtonArea = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
`;

export const LinkArea = styled.a`
  align-items: center;
  background-color: ${({ theme, type }) =>
    type === 'timeBlock' ? 'transparent' : theme.white};
  border-radius: 0.325rem;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 2.2rem;
  justify-content: space-between;
  padding: 0.5rem;
  width: 2.2rem;
`;

export const TrashIconCalendar = styled(Trash)`
  color: ${({ theme }) => theme.ExtralightGray};
  height: 22px;
  width: 22px;
`;

export const CameraIcon = styled(Video)`
  color: ${({ theme }) => theme.ExtralightGray};
  height: 25px;
  width: 25px;
`;

export const MapPinIcon = styled(MapPin)`
  color: ${({ theme }) => theme.ExtralightGray};
  height: 25px;
  width: 25px;
`;

export const ReportIcon = styled(AlertCircle)`
  color: ${({ theme }) => theme.pink};
  height: 20px;
  width: 20px;
`;

export const ReportIconAlert = styled(ReportAlert)`
  height: 70px;
  width: 70px;
`;

export const ReportIconCheck = styled(ReportCheck)`
  height: 70px;
  width: 70px;
`;

export const ScheduleTime = styled(SmallFont)`
  color: ${({ theme, type }) =>
    type === 'schedule' ? theme.white : theme.black};
  font-weight: 700;
`;

export const ScheduleClinicTime = styled(SmallFont)`
  color: ${({ theme }) => theme.white};
  font-weight: 700;
`;

export const ScheduleClient = styled(SmallFont)`
  color: ${({ theme, type }) =>
    type === 'schedule' ? theme.white : theme.black};
  font-size: 12px;
  font-weight: 400;
`;

export const ScheduleClientClinic = styled(SmallFont)`
  color: ${({ theme }) => theme.white};
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  left: 0.5rem;
  top: 0;
`;

export const WrapperButtonsClinic = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 100%;
`;

export const ButtonsSchedule = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 100%;
`;

export const ScheduleCard = styled.section`
  align-items: center;
  background-color: ${({ theme, type }) => {
    if (type === 'online') return theme.primary;
    if (type === 'in_person') return theme.lightBlue;
    if (type === 'blockedTime') return '#BCC1E0';
    return theme.white;
  }};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  padding: 1rem 0.5rem;
  position: relative;
  width: 100%;
`;

export const ScheduleInfo = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ScheduleInfoClinic = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ScheduleWrapperClinic = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: max-content;
  width: 50%;
`;

export const StyledList = styled.ul`
  border: 1px solid ${({ theme }) => theme.primaryAlt};
  border-radius: 0.5rem;
  margin: 1rem 0;
  width: 100%;

  li {
    border: 1px solid ${({ theme }) => theme.primaryAlt};
    list-style-type: none;
    padding: 0.5rem 0.25rem;
  }

  .--title {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .--content {
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const WrapperList = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const ScheduleWrapperAvatar = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const ModalAvatar = styled.img`
  border-radius: 50%;
  height: 150px;
  width: 150px;
`;

export const ScheduleProfessionalAvatar = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
`;

export const ScheduleClientAvatar = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 70%;
`;

export const BoxCalendarCard = styled(ModalBoxWrapper)`
  width: 400px;
  padding: 2rem;
`;

export const BoxCalendarCardInfo = styled(BoxCalendarCard)`
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const BoxEvaluate = styled(ModalBoxWrapper)`
  padding: 1rem;
  width: 500px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 1rem 0.5rem;
  }
`;

export const BoxCancelInformation = styled(BoxEvaluate)``;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const IconAreaPay = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.pink};
  display: inline-block;
  justify-content: center;
  margin: 0 0.7rem;
  padding: 0.25rem 0.25rem 0 0.25rem;
  width: fit-content;
`;

export const ReportBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
`;

export const ReportTitle = styled(BigFont)`
  font-weight: 900;
  font-size: 1.7rem;
`;

export const ReportParagraph = styled(SmallFont)`
  font-weight: 700;
  margin: 1rem 0;
  padding: 0 1rem;
  text-align: center;
`;

export const ReportButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ReportIconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  margin-top: 1rem;
  width: 100%;
`;

export const ReportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, primary }) =>
    primary ? theme.pink : theme.white};
  border: 1px solid
    ${({ theme, primary }) => (primary ? theme.pink : theme.black)};
  border-radius: 1.5rem;
  color: ${({ theme, primary }) => (primary ? theme.white : theme.black)};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 2.5rem;
  width: 200px;
`;

export const PaymentModal = styled(ModalBoxWrapper)`
  max-height: 90%;
  padding: 1rem;
  width: 35vw;
`;

export const PaymentWrapper = styled(ScrollCardStyle)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 1rem;
`;

export const PaymentTitle = styled(BigFont)`
  font-size: 1.5rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

export const WrapperPayment = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ContainerCardPayment = styled(ScrollCardStyle)`
  width: 100%;
  height: 40vh;
  padding: 0 2rem;
  overflow-y: auto;
`;

export const CardButtonMethod = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.secondary_blue : theme.white};
  border-radius: 2rem;
  color: ${({ theme, checked }) =>
    checked ? theme.white : theme.secondary_blue};
  display: flex;
  font-size: 0.8rem;
  height: 2.5rem;
  font-weight: 700;
  justify-content: center;
  width: 45%;
  margin: 0 1rem;
`;

export const RowLine = styled.section`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentButton = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary_blue : theme.white};
  border-radius: 2rem;
  color: ${({ theme, checked }) =>
    checked ? theme.white : theme.primary_blue};
  display: flex;
  font-size: 0.8rem;
  height: 2.5rem;
  font-weight: 700;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.primary_blue};
    color: ${({ theme }) => theme.primary_blue};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.primary_blue};
      color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.primary_blue};
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 3rem;
    width: 100%;
  }
`;

export const ButtonAddCreditCard = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.primary_blue};
  border-radius: 2rem;
  color: ${({ theme }) => theme.primary_blue};
  font-weight: 700;
  height: 2.3rem;
  margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0 2.5rem')};
  width: 60%;

  &:hover {
    background-color: ${({ theme }) => theme.primary_blue};
    color: ${({ theme }) => theme.white};
  }

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0 1.5rem')};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;
