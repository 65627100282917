/* eslint-disable react-hooks/exhaustive-deps */

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ShareTwoTone,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderSpinner from "../../../components/LoaderSpinner";
import { SMFontBackground } from "../../../components/MessageElements/styles";
import { UserAvatar, handleUserNames } from "../../../components/UserElements";
import useAuth from "../../../hooks/auth";
import useEvent from "../../../hooks/event";
import LogoClinic from "../../../images/logo-default-clinic.svg";
import Profile from "../../../images/noAvatar.png";
import api from "../../../services/api";
import { PtBr } from "../../../services/pt_br";
import { ButtonScrollToTop, ScrollToTop } from "../../../styles/globalStyles";
import convertCurrencyPtBr from "../../../utils/convertCurrencyPtBr";
import getProfessionName from "../../../utils/getProfessionName";
import { getProfessions } from "../../../utils/getProfessionsFilter";
import { ProfessionalFilter } from "../../Public/Professionals/components";
import { ArtClinic, BannerImage } from "../Dashboard/styles";
import { BackgroundBanner } from "../Profile/styles";
import {
  AvatarCard,
  BodyDescription,
  BodyTitle,
  Button,
  ClinicImage,
  ClinicImageInBanner,
  ColumnTextContent,
  ColumnTextTitle,
  ContainerClinic,
  ContainerImage,
  ContainerResponsible,
  DescriptionWrapper,
  DescriptionWrapperResponsible,
  EmptyContainer,
  EmptyImg,
  EmptyLabel,
  FilterButtonContainer,
  ListGrid,
  ListProfessionalContainer,
  MBFontSecondary,
  MainContainer,
  ProfessionalCard,
  ProfessionalFilterButton,
  ProfessionalSharedButton,
  PublicListBody,
  PublicSearchContainer,
  ResponsibleAbout,
  ResponsibleAvatar,
  ResponsibleName,
  RowTextCenter,
  RowTextSpaced,
  SMFontDark,
  SMFontPrimary,
} from "./styles";
import isFilterDataInputCompare from "../../../utils/isFilterDataInputCompare";

function getLinkMembers(username) {
  const environment = process.env.REACT_APP_HOST;
  switch (environment) {
    case "development":
      return `https://mind-client-development.herokuapp.com/${username}`;
    case "development.local":
      return `http://localhost:4200/${username}`;
    default:
      return `https://web.allminds.app/${username}`;
  }
}

function EmptyList({ ...props }) {
  const { text } = props;
  return (
    <EmptyContainer>
      <EmptyLabel>{text}</EmptyLabel>
      <EmptyImg />
    </EmptyContainer>
  );
}

export function Professional({ ...props }) {
  const { professional, id } = props;
  const history = useHistory();

  function getNextSchedule(professional) {
    let today = new Date();
    let todayWeekDay = today.getDay();
    if (professional?.availabilityRule?.workdays) {
      let nextDay = professional?.availabilityRule?.workdays?.filter(
        day => parseInt(day, 10) > todayWeekDay,
      );
      if (nextDay?.length === 0) {
        nextDay = professional?.availabilityRule?.workdays[0];
        today.setDate(
          today.getDate() + (6 - todayWeekDay + (parseInt(nextDay, 10) + 1)),
        );
      } else {
        today.setDate(
          today.getDate() + (parseInt(nextDay[0], 10) - todayWeekDay),
        );
      }
    }
    return today;
  }

  return (
    <ProfessionalCard>
      <AvatarCard>
        <UserAvatar src={professional.avatar || Profile} search />
      </AvatarCard>
      <ColumnTextTitle>
        <MBFontSecondary>{handleUserNames(professional)}</MBFontSecondary>
        <SMFontDark>{`${getProfessionName(professional?.profession, "toPt")} ${
          professional?.address?.city ? `| ${professional?.address?.city}` : ""
        }`}</SMFontDark>
      </ColumnTextTitle>
      <ColumnTextContent>
        <RowTextCenter>
          <SMFontPrimary style={{ fontWeight: "400" }}>
            Próxima data:{" "}
          </SMFontPrimary>
          <SMFontPrimary>{`${
            PtBr.days_abs[getNextSchedule(professional).getDay()]
          }, ${moment(getNextSchedule(professional), "YYYY-MM-DD").format(
            "DD/MM/YYYY",
          )}`}</SMFontPrimary>
        </RowTextCenter>
        <RowTextCenter>
          {professional?.specialities?.map((item, index) =>
            index <= 3 ? (
              <SMFontBackground key={item}>{item}</SMFontBackground>
            ) : null,
          )}
          {professional?.specialities?.length > 4 && (
            <Tooltip title={professional?.specialities?.slice(4).join(", ")}>
              <SMFontBackground>
                {`+${professional?.specialities?.length - 4}`}
              </SMFontBackground>
            </Tooltip>
          )}
        </RowTextCenter>
        <RowTextSpaced>
          <RowTextCenter style={{ width: "40%" }}>
            <SMFontPrimary style={{ fontSize: "1.1rem" }}>
              {convertCurrencyPtBr(professional?.sessionPrice)}
            </SMFontPrimary>
            <SMFontDark style={{ fontSize: "1.1rem" }}>{`por ${
              professional?.availabilityRule?.appointmentDuration || "30"
            } min`}</SMFontDark>
          </RowTextCenter>
          <RowTextCenter className="evaluate">
            <SMFontDark style={{ fontSize: "1.1rem", marginRight: "0.325rem" }}>
              {professional.likes || "0"} avaliações
            </SMFontDark>
          </RowTextCenter>
        </RowTextSpaced>
      </ColumnTextContent>
      <RowTextCenter
        style={{
          justifyContent: "center",
          margin: "2rem 0 2rem 0",
          paddingLeft: "0",
        }}
      >
        <Button
          onClick={() => {
            history.push(`/clinic/schedule/${id}`);
          }}
        >
          Mais informações
        </Button>
      </RowTextCenter>
    </ProfessionalCard>
  );
}

export default function AllProfessional() {
  const { user } = useAuth();
  const { eventFilter, setCities } = useEvent();
  const [loading, setLoading] = useState(true);
  const [professions, setProfessions] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [professionalsFilter, setProfessionalsFilter] = useState(null);
  const [toggleFilter, setToggleFilter] = useState(false);
  const scrollRef = useRef(null);

  const getCities = professionalsFilter => {
    const citys = professionalsFilter?.reduce((acc, curr) => {
      if (curr?.attributes.address.city === null) return acc;
      if (acc.includes(curr?.attributes?.address?.city.toLowerCase().trim()))
        return acc;
      acc.push(curr?.attributes?.address?.city.toLowerCase().trim());
      return acc;
    }, []);

    const parseCities = citys?.map(city => city.toLowerCase()).sort();

    return parseCities;
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessionals = async () => {
      try {
        const { data } = await api.get("/clinics/my_professionals", {
          signal: controller.signal,
        });
        if (data.data) {
          const cities = getCities(data.data);
          setCities(cities);
          setProfessionals(data.data);
        } else
          throw new Error("Não foi possível buscar lista de profissionais");
      } catch (error) {
        toast.error("Não foi possível buscar lista de profissionais");
      } finally {
        setLoading(false);
      }
    };
    fetchProfessionals();

    const fetchProfessions = async () => {
      try {
        const { data } = await api.get("/professions", {
          signal: controller.signal,
        });
        const professionList = getProfessions(
          data?.data?.attributes?.professions,
        );
        setProfessions(professionList);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível buscar profissões");
      }
    };
    fetchProfessions();

    return () => controller.abort();
  }, []);
  // useEffect(() => {
  //   const controller = new AbortController();
  //   const fetchProfessionals = async () => {
  //     try {
  //       await getProfessionals(controller);
  //     } catch (error) {
  //       toast.error("Não foi possível buscar lista de profissionais");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchProfessionals();

  //   const fetchProfessions = async () => {
  //     try {
  //       const { data } = await api.get("/professions", {
  //         signal: controller.signal,
  //       });
  //       const professionList = getProfessions(
  //         data?.data?.attributes?.professions,
  //       );
  //       setProfessions(professionList);
  //     } catch (error) {
  //       if (!controller.signal.aborted)
  //         toast.error("Não foi possível buscar profissões");
  //     }
  //   };
  //   fetchProfessions();

  //   return () => controller.abort();
  // }, []);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  function isEqual(a, b, type) {
    if (b?.length === 0 || b === "" || b === 0 || b === null) return true;
    if (type === 1) return a?.toLowerCase()?.includes(b?.toLowerCase());
    if (type === 2) return filterProfessions(a, b);
    return FilterArray(a, b);
  }

  function isDefault(filter) {
    let isDefaultValue = true;
    Object.keys(filter).forEach(key => {
      if (
        filter[`${key}`]?.length !== 0 &&
        filter[`${key}`] !== "" &&
        filter[`${key}`] !== 0 &&
        filter[`${key}`] !== null
      ) {
        isDefaultValue = false;
      }
    });
    return isDefaultValue;
  }

  function FilterArray(a, b) {
    let tmp = [];
    tmp = b?.filter(item => a?.includes(item));

    return tmp?.length > 0;
  }

  function filterProfessions(a, b) {
    let tmp = [];

    tmp = b?.filter(item => a?.includes(item));
    return tmp?.length > 0;
  }

  function filterProfessionalAdvisor(filter) {
    let profTmp = [];
    if (filter?.professionFilter?.includes("professional_advisor")) {
      profTmp = professionals?.filter(
        item =>
          (item.attributes?.reasons?.includes("Orientação profissional") ||
            item.attributes?.approaches?.includes("Orientação profissional") ||
            item.attributes?.specialities?.includes(
              "Orientação profissional",
            )) &&
          item,
      );
    }
    return profTmp;
  }

  function filterAddress(filter) {
    const address =
      filter.address === "todas as cidades" ||
      filter.address === undefined ||
      filter.address === null
        ? (filter.address = "")
        : filter.address;
    return address;
  }

  const filterProfessionals = async filter => {
    const address = filterAddress(filter);

    const professionalAdvisor = eventFilter.professionFilter.includes(
      "professional_advisor",
    )
      ? filterProfessionalAdvisor(filter)
      : [];

    const data = professionals?.filter(
      item =>
        isDefault(filter) ||
        (isEqual(
          item.attributes.firstName + item.attributes.lastName,
          filter.search,
          1,
        ) &&
          isEqual(item.attributes.profession, filter.professionFilter, 2) &&
          isEqual(
            item.attributes.availabilityRule.appointmentTypes,
            filter.sessionType,
            3,
          ) &&
          isEqual(item.attributes.address.city, address, 1) &&
          isEqual(item.attributes.specialities, filter.specialities, 0) &&
          isEqual(item.attributes.reasons, filter.reasons, 0) &&
          isEqual(item.attributes.approaches, filter.approaches, 0) &&
          parseInt(item.attributes.sessionPrice, 10) >= filter.price),
    );

    !isFilterDataInputCompare(eventFilter)
      ? setProfessionalsFilter([...data, ...professionalAdvisor])
      : setProfessionalsFilter(null);
  };

  const professionalsData = useMemo(() => {
    if (!professionalsFilter) return professionals;
    return professionalsFilter;
  }, [professionalsFilter, professionals]);

  return (
    <MainContainer>
      <ContainerImage>
        {!user?.hiddenFields.includes("banner") ? (
          user?.banner ? (
            <BannerImage src={user?.banner} alt="Banner" />
          ) : (
            <BackgroundBanner color={user?.bannerColor} />
          )
        ) : (
          <div style={{ height: "13rem" }} />
        )}
        {!user?.banner && (
          <ClinicImageInBanner src={user?.avatar || LogoClinic} />
        )}
      </ContainerImage>

      <ContainerClinic>
        {!user?.hiddenFields.includes("avatar") && (
          <ClinicImage src={user?.avatar || LogoClinic} />
        )}
        {!user?.hiddenFields.includes("description") && (
          <DescriptionWrapper>
            <BodyTitle style={{ fontSize: "1.5rem" }}>{user?.name}</BodyTitle>
            <BodyTitle>Sobre nós:</BodyTitle>
            <BodyDescription>{user?.description}</BodyDescription>
          </DescriptionWrapper>
        )}
      </ContainerClinic>

      <ContainerResponsible>
        <DescriptionWrapperResponsible>
          {!user?.hiddenFields.includes("responsibleName") && (
            <ResponsibleName style={{ fontSize: "1.5rem" }}>
              {user?.responsibleName}
            </ResponsibleName>
          )}
          <ResponsibleName
            style={{
              color: "#0C1B5C",
              fontWeight: "normal",
              marginBottom: "0",
            }}
          >
            Responsável Técnico
          </ResponsibleName>
          {!user?.hiddenFields.includes("responsibleDocumentNumber") && (
            <ResponsibleName style={{ color: "#0C1B5C", fontWeight: "normal" }}>
              CRP: {user?.responsibleDocumentNumber}
            </ResponsibleName>
          )}
          {!user?.hiddenFields.includes("responsibleAbout") && (
            <ResponsibleAbout>{user?.responsibleAbout}</ResponsibleAbout>
          )}
        </DescriptionWrapperResponsible>

        {!user?.hiddenFields.includes("responsibleAvatar") && (
          <ResponsibleAvatar src={user?.responsibleAvatar || Profile} />
        )}
      </ContainerResponsible>

      {user?.secondaryBanner && <ArtClinic src={user?.secondaryBanner} />}

      <PublicListBody ref={scrollRef}>
        <PublicSearchContainer>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            Nossos Profissionais:
          </p>
          <FilterButtonContainer>
            <ProfessionalFilterButton
              onClick={() => setToggleFilter(!toggleFilter)}
            >
              {toggleFilter ? "Esconder Filtros" : "Mostrar Filtros"}
              {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ProfessionalFilterButton>
            <ProfessionalSharedButton
              onClick={async () => {
                const link = getLinkMembers(user?.username);
                try {
                  await navigator.clipboard.writeText(link);
                  toast.success(
                    "Link de convite copiado para área de transferência",
                  );
                } catch (error) {
                  toast.error("Erro ao copiar link");
                }
              }}
            >
              Compartilhar
              <ShareTwoTone sx={{ ml: 1, fontSize: "1.2rem" }} />
            </ProfessionalSharedButton>
          </FilterButtonContainer>
          {toggleFilter && (
            <ProfessionalFilter
              onSubmit={filterProfessionals}
              professions={professions}
              loading={loading}
            />
          )}
        </PublicSearchContainer>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <ListProfessionalContainer>
            {professionalsData?.length === 0 ? (
              <EmptyList text="Nenhum profissional foi encontrado. Tente alterar os filtros de busca!" />
            ) : (
              <ListGrid>
                {professionalsData?.map(prof => (
                  <Professional
                    key={prof.id}
                    professional={prof.attributes}
                    id={prof.id}
                  />
                ))}
              </ListGrid>
            )}
            {!loading && (
              <Tooltip title="Voltar ao topo">
                <ButtonScrollToTop onClick={scrollToTop}>
                  <ScrollToTop />
                </ButtonScrollToTop>
              </Tooltip>
            )}
          </ListProfessionalContainer>
        )}
      </PublicListBody>
    </MainContainer>
  );
}
