/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { SchedulesListItem } from '../../../components/ListItens';
import useAuth from '../../../hooks/auth';
import useAxiosGet from '../../../hooks/useAxiosGet';
import sortedSchedules from '../../../utils/sortedSchedules';
import { Main, ScheduleWrapper } from './styles';

export default function SchedulesReport() {
  const { user } = useAuth();
  const getSchedules = useAxiosGet();
  const getPastSchedules = useAxiosGet();

  useEffect(() => {
    const { controller, data, errors, fetchData } = getSchedules;

    if (data?.length === 0) fetchData(`${user.type}s/schedules`);

    if (errors) toast.error('Não foi possível carregar os agendamentos');

    return () => controller?.abort();
  }, [getSchedules.loading]);

  useEffect(() => {
    const { controller, data, errors, fetchData } = getPastSchedules;

    if (data?.length === 0) fetchData(`${user.type}s/schedules?past=true`);

    if (errors)
      toast.error('Não foi possível carregar os agendamentos concluidos');

    return () => controller?.abort();
  }, [getPastSchedules.loading]);

  const schedules = [...getPastSchedules.data, ...getSchedules.data];

  return (
    <Main>
      <ScheduleWrapper>
        <Flatlist
          data={sortedSchedules(schedules, true)}
          dateSearch
          filterObject={(schedule) => schedule.attributes.search}
          placeholder="Buscar Consultas"
          renderItem={(schedule, index) => (
            <SchedulesListItem
              key={`${schedule.id} ${index}`}
              data={schedule}
              schedule="true"
              report
            />
          )}
          type="schedule"
          searchType="client"
          loading={getSchedules.loading || getPastSchedules.loading}
        />
      </ScheduleWrapper>
    </Main>
  );
}
