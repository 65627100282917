import moment from 'moment';

export default function sortedSchedules(schedules, desc = false) {
  return schedules.sort((a, b) => {
    const dateTimeA = moment(
      `${a.attributes.startDate}T${a.attributes.startTime}`
    );
    const dateTimeB = moment(
      `${b.attributes.startDate}T${b.attributes.startTime}`
    );
    if (dateTimeA.isBefore(dateTimeB)) return desc ? 1 : -1;
    return desc ? -1 : 1;
  });
}
