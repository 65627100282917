/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import FormikControl from '../../../components/FormikControl';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import EllipseBottom from '../../../images/ellipse-bottom.svg';
import EllipseTop from '../../../images/ellipse-top.svg';
import LoginBackGroundImg from '../../../images/login-background.svg';
import Logo from '../../../images/logo-mind-white.svg';
import { BREAKPOINT_MD, InstagramIcon } from '../../../styles/globalStyles';
import theme from '../../../theme';
import useWindowSize from '../../../utils/WindowSize';
import {
  IconAreaInstagran,
  SocialIconsWrapper,
} from '../../Public/Profile/styles';
import {
  BackgroundImg,
  IconArea,
  ImageContainer,
  ImgEllipseBottom,
  ImgEllipseTop,
  InputWrapper,
  LeftArrowIcon,
  LoginButtonWrapper,
  LoginForm,
  LoginLink,
  LoginMain,
  LoginParagraph,
  LoginScroll,
  LoginTitle,
  LoginWrapper,
  LoginWrapperScreen,
  LoginWrapperSection,
  LogoImg,
  Main,
  RegisterButton,
  SendButton,
} from './styles';

export function FormLogin({ ...props }) {
  const { form, loading } = props;
  const { values, errors, touched, handleChange, handleSubmit } = form;
  const history = useHistory();
  const location = useLocation();

  const getLinkUrl = (pathname) => {
    const url = pathname.split('/')[1];
    switch (url) {
      case 'cliente':
        return 'cliente';
      case 'profissional':
        return 'profissional';
      case 'clinica':
        return 'clinica';
      default:
        return 'cliente';
    }
  };

  const linkUrl = getLinkUrl(location?.pathname);

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <LoginForm onSubmit={handleSubmit}>
      <LoginWrapper>
        <LoginScroll>
          <ImageContainer>
            <LogoImg src={Logo} alt="Logo" />
          </ImageContainer>
          <InputWrapper>
            <LoginTitle>Faça o seu Login abaixo</LoginTitle>
            <FormikControl
              errors={errors.email}
              placeholder="Digite seu email"
              touched={touched.email}
              bgColor="#fff"
              control="input"
              label="Email"
              labelFamily="Poppins"
              labelSize="0.9rem"
              labelWeight="600"
              name="email"
              onChange={handleChange}
              type="email"
              upLabel
              value={values.email.trim()}
            />

            <FormikControl
              errors={errors.password}
              placeholder="Digite sua senha"
              touched={touched.password}
              bgColor="#fff"
              control="input"
              label="Senha"
              labelFamily="Poppins"
              labelSize="0.9rem"
              labelWeight="600"
              name="password"
              onChange={handleChange}
              type="password"
              upLabel
              value={values.password}
            />

            <SendButton style={{ marginTop: '1rem' }} bgColor={theme.secondary}>
              Entrar
            </SendButton>
            <LoginParagraph style={{ marginTop: '1rem' }}>
              Ainda não possui uma conta?{' '}
            </LoginParagraph>
            <RegisterButton
              bgColor={theme.lightBlue}
              dark
              onClick={() => history.push(`/${linkUrl}/cadastre-se`)}
            >
              Criar
            </RegisterButton>
            <LoginParagraph style={{ marginTop: '1.5rem' }}>
              Esqueceu sua senha?{' '}
              <LoginLink
                onClick={() => history.push(`/${linkUrl}/recuperar-senha`)}
              >
                Recuperar
              </LoginLink>
            </LoginParagraph>
            <IconArea>
              <LeftArrowIcon onClick={() => history.push('/conectar-se')} />
            </IconArea>
          </InputWrapper>
        </LoginScroll>
      </LoginWrapper>
    </LoginForm>
  );
}

export function ClientLoginScreen() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { backgroundImg } = useEvent();
  const { signIn } = useAuth();
  const { width } = useWindowSize();

  const schema = Yup.object({
    email: Yup.string().email('Email invalido').required('Requerido'),
    password: Yup.string()
      .required('Requerido')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
  });

  const handleContinue = async (values) => {
    const { email, password } = values;
    setLoading(true);
    await signIn({ email, password, type: 'client' });
    setLoading(false);
    history.push('/client/dashboard');
  };

  return (
    backgroundImg && (
      <Main>
        <LoginMain>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                handleContinue(values);
              }, 400);
            }}
          >
            {(form) => <FormLogin form={form} loading={loading} />}
          </Formik>
        </LoginMain>
        <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
        <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
        {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
        <SocialIconsWrapper>
          {/* <Tooltip title="Fale com a gente">
            <IconAreaWhatsapp
              href="https://wa.me/5571999960614"
              target="_blank"
            >
              <WhatsappIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaWhatsapp>
          </Tooltip> */}
          <Tooltip title="Siga-nos no Instagram">
            <IconAreaInstagran
              href="https://www.instagram.com/allminds.app/"
              target="_blank"
            >
              <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaInstagran>
          </Tooltip>
        </SocialIconsWrapper>
      </Main>
    )
  );
}

export function ProfessionalLoginScreen() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { backgroundImg } = useEvent();
  const { signIn } = useAuth();
  const { width } = useWindowSize();

  const schema = Yup.object({
    email: Yup.string().email('Email invalido').required('Requerido'),
    password: Yup.string()
      .required('Requerido')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
  });

  const handleContinue = async (values) => {
    setLoading(true);
    const { email, password } = values;
    await signIn({ email, password, type: 'professional' });
    setLoading(false);
    history.push('/professional/dashboard');
  };

  return (
    backgroundImg && (
      <Main>
        <LoginMain>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                handleContinue(values);
              }, 400);
            }}
          >
            {(form) => <FormLogin form={form} loading={loading} />}
          </Formik>
        </LoginMain>
        <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
        <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
        {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
        <SocialIconsWrapper>
          {/* <Tooltip title="Fale com a gente">
            <IconAreaWhatsapp
              href="https://wa.me/5571999960614"
              target="_blank"
            >
              <WhatsappIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaWhatsapp>
          </Tooltip> */}
          <Tooltip title="Siga-nos no Instagram">
            <IconAreaInstagran
              href="https://www.instagram.com/allminds.app/"
              target="_blank"
            >
              <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaInstagran>
          </Tooltip>
        </SocialIconsWrapper>
      </Main>
    )
  );
}

export function ClinicLoginScreen() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { backgroundImg } = useEvent();
  const { signIn } = useAuth();
  const { width } = useWindowSize();

  const schema = Yup.object({
    email: Yup.string().email('Email invalido').required('Requerido'),
    password: Yup.string()
      .required('Requerido')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
  });

  const handleContinue = async (values) => {
    const { email, password } = values;
    setLoading(true);
    await signIn({ email, password, type: 'clinic' });
    setLoading(false);
    history.push('/clinic/dashboard');
  };

  return (
    backgroundImg && (
      <Main>
        <LoginMain>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                handleContinue(values);
              }, 400);
            }}
          >
            {(form) => <FormLogin form={form} loading={loading} />}
          </Formik>
        </LoginMain>
        <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
        <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
        {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
        <SocialIconsWrapper>
          {/* <Tooltip title="Fale com a gente">
            <IconAreaWhatsapp
              href="https://wa.me/5571999960614"
              target="_blank"
            >
              <WhatsappIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaWhatsapp>
          </Tooltip> */}
          <Tooltip title="Siga-nos no Instagram">
            <IconAreaInstagran
              href="https://www.instagram.com/allminds.app/"
              target="_blank"
            >
              <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaInstagran>
          </Tooltip>
        </SocialIconsWrapper>
      </Main>
    )
  );
}

export default function LoginScreen() {
  const history = useHistory();
  const { signOut } = useAuth();
  const { backgroundImg } = useEvent();
  const { width } = useWindowSize();

  useEffect(() => {
    signOut();
  }, []);

  return (
    backgroundImg && (
      <Main>
        <LoginWrapperScreen>
          <LoginWrapperSection>
            <InputWrapper style={{ justifyContent: 'flex-start' }}>
              <ImageContainer>
                <LogoImg src={Logo} alt="AllMinds" />
              </ImageContainer>
              <LoginTitle>Olá! Escolha como quer acessar:</LoginTitle>
              <LoginButtonWrapper>
                <SendButton
                  bgColor={theme.secondary}
                  onClick={() => history.push('/cliente/conectar-se')}
                >
                  Sou um Paciente
                </SendButton>
                <SendButton
                  bgColor={theme.lightBlue}
                  dark
                  onClick={() => history.push('/profissional/conectar-se')}
                >
                  Sou um Especialista
                </SendButton>
                <SendButton
                  bgColor={theme.third}
                  dark
                  onClick={() => history.push('/clinica/conectar-se')}
                >
                  Sou uma Clínica
                </SendButton>
              </LoginButtonWrapper>
            </InputWrapper>
          </LoginWrapperSection>
        </LoginWrapperScreen>
        <ImgEllipseTop src={EllipseTop} alt="imgLogin" />
        <ImgEllipseBottom src={EllipseBottom} alt="imgLogin" />
        {width > BREAKPOINT_MD && <BackgroundImg src={LoginBackGroundImg} />}
        <SocialIconsWrapper>
          {/* <Tooltip title="Fale com a gente">
            <IconAreaWhatsapp
              href="https://wa.me/5571999960614"
              target="_blank"
            >
              <WhatsappIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaWhatsapp>
          </Tooltip> */}
          <Tooltip title="Siga-nos no Instagram">
            <IconAreaInstagran
              href="https://www.instagram.com/allminds.app/"
              target="_blank"
            >
              <InstagramIcon fontSize="medium" sx={{ color: '#fff' }} />
            </IconAreaInstagran>
          </Tooltip>
        </SocialIconsWrapper>
      </Main>
    )
  );
}
