/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import trimString from '../../utils/trimString';
import {
  ButtonAccess,
  Card,
  ContainerContent,
  ContainerImage,
  DateLabel,
  DescriptionText,
  Image,
  TitleCard,
} from './styles';

export const DefaultContentCard = ({ item, label, onClick }) => {
  const { title, locked, description, createdAt, image } = item;
  const trim = 200;

  return (
    <Card disabled={locked}>
      <ContainerImage image={image}>
        <Image src={image} />
      </ContainerImage>
      <ContainerContent>
        <Tooltip title={title}>
          <TitleCard>{trimString(title, 30)}</TitleCard>
        </Tooltip>
        <DateLabel>{moment(createdAt).format('DD.MM.YY')}</DateLabel>
        <DescriptionText>{trimString(description, trim)}</DescriptionText>
        <ButtonAccess onClick={onClick}>{label}</ButtonAccess>
      </ContainerContent>
    </Card>
  );
};

export const DefaultVideoCard = ({ item, label, onClick }) => {
  const { title, description, image } = item;
  const trim = 200;

  return (
    <Card>
      <ContainerImage image={image}>
        <Image src={image} />
      </ContainerImage>
      <ContainerContent>
        <TitleCard>{title}</TitleCard>
        <DescriptionText>{trimString(description, trim)}</DescriptionText>
        <ButtonAccess onClick={() => onClick()}>{label}</ButtonAccess>
      </ContainerContent>
    </Card>
  );
};
