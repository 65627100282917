import React from "react";
import styled from "styled-components";

export function InputColorPicker({ ...props }) {
  const { label, ...rest } = props;

  return (
    <Main>
      <ColorPickerLabel color={props.value}>{label}</ColorPickerLabel>
      <ColorPickerWrapper>
        <ColorPicker type="color" {...rest} />
      </ColorPickerWrapper>
    </Main>
  );
}

const Main = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

const ColorPickerWrapper = styled.div`
  border-radius: 5px;
  height: 35px;
  overflow: hidden;
  width: 100px;
`;

const ColorPicker = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${props => props.value || "#ffffff"};
  border: none;
  cursor: pointer;
  height: 48px;
  margin-right: 10px;
  transform: translate(-4px, -4px);
  width: 108px;
`;

const ColorPickerLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 900;
  margin-right: 1rem;
`;
