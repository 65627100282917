import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
} from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: center;
  display: flex;
  justify-items: center;
  width: 100%;

  .--input-wrapper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
      gap: 2rem;
      grid-template-columns: 1fr;
    }

    &-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: ${({ theme }) => theme.secundary};
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 10px 0;
      color: ${({ theme }) => theme.darkGray};
    }

    &-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      align-self: center;
      padding: 50px 0 10px 0;
      color: ${({ theme }) => theme.lightGray};
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    row-gap: 1rem;
  }
`;

export const MainPlans = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  height: fit-content;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'plans'
    'signature';
  align-items: flex-start;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    gap: 2rem;
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    gap: 2rem;
    .subscribe {
      padding: 0 1rem 0 0;
    }
  }
`;

export const MainPayment = styled.div`
  width: 100%;
  display: flex;

  .subscribe {
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
  }

  .subscribe-form {
    max-width: 800px;
    width: 100%;
  }

  .--input-wrapper {
    align-self: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    .subscribe {
      align-items: center;
      justify-content: center;
      width: 90%;
    }
  }
`;

export const ContainerSignature = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.primary_blue};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: center;
  min-width: 12rem;
  padding: 1rem 0;
  width: 100%;

  .--signature-title {
    color: ${({ theme }) => theme.white};
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .--signature-type {
    color: ${({ theme }) => theme.white};
    font-size: 2rem;
    padding: 1.5rem 0;
  }
  .--signature-text {
    color: ${({ theme }) => theme.white};
    margin-bottom: 1rem;
  }

  .--signature-price {
    color: ${({ theme }) => theme.white};
    font-size: 1.5rem;
    font-weight: 700;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    .--signature-title {
      font-size: 1.5rem;
    }
    .--signature-type {
      font-size: 2rem;
    }
    .--signature-text {
    }
  }
`;

export const ContainerPayment = styled.div`
  align-items: ${({ justify }) => justify || 'center'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ContainerCardPayment = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const HeaderElement = styled.div`
  width: 100%;
  display: flex;
  height: 150px;
  padding: 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0px 0px 50% 50% / 0px 0px 15px 15px;

  .--title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    align-self: center;
    color: ${({ theme }) => theme.primaryAlt};
  }

  .--text {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    align-self: center;
    padding-top: 10px;
    color: ${({ theme }) => theme.primaryAlt};
  }
`;

export const ButtonPlan = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.primary_blue};
  border-radius: 2rem;
  color: ${({ theme }) => theme.primary_blue};
  cursor: pointer;
  font-weight: 700;
  height: fit-content;
  margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
  padding: 0.7rem 2rem;
  width: fit-content;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? '2rem 0 0 0' : '0')};
    width: 100%;
  }
`;

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
`;

export const PlansWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

export const CardsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-area: 'signature';
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    order: -1; /* Mudança de ordem para ficar na primeira linha */
  }
`;

export const PlansContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: 'plans';
  justify-content: center;
  width: 100%;
`;
