/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CalendarCard } from "../../../../components/EventElements";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import useAuth from "../../../../hooks/auth";
import EmptyEvent from "../../../../images/empty-dashboard.png";
import api from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import {
  CalendarContainer,
  ContainerImg,
  TimeItemList,
  TimeText,
  WeekItemList,
  WeekList,
  WeekText,
} from "../styles";

export function CalendarItemList({ ...props }) {
  const { item, schedules, date } = props;
  const handleDate = date => {
    return moment(date).format("HH:mm");
  };

  const handleSchedule = () => {
    return (
      schedules?.filter(
        dt =>
          dt.attributes.startDate === date &&
          dt.attributes.startTime === moment(item).format("HH:mm"),
      )[0] || null
    );
  };

  return (
    <TimeItemList>
      <TimeText>{handleDate(item)}</TimeText>
      {handleSchedule() && <CalendarCard event={handleSchedule()} />}
    </TimeItemList>
  );
}

function WeekItem({ ...props }) {
  const { date, schedules, daysTimes, setLoading } = props;
  const [timeBlocks, setTimeBlocks] = useState([]);
  const formatedDate = moment(date).format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDay = moment(date).format("ddd").toLowerCase();

  useEffect(() => {
    const blockTimes = daysTimes?.filter(dt => dt.day === currentDay);

    const schedulesTimes = schedules?.filter(
      schedule => schedule.attributes.startDate === formatedDate,
    );

    const timesBlocks = [...blockTimes, ...schedulesTimes]?.reduce(
      (acc, curr) => {
        if (
          schedulesTimes?.some(
            item => item.attributes.startTime === curr.attributes.startTime,
          )
        ) {
          const index = schedulesTimes?.findIndex(
            item => item.attributes.startTime === curr.attributes.startTime,
          );
          if (acc.includes(schedulesTimes[index])) {
            return acc;
          }
          acc.push(schedulesTimes[index]);
          return acc;
        }
        acc.push(curr);
        return acc;
      },
      [],
    );

    setTimeBlocks(timesBlocks);
  }, [date]);

  return (
    <WeekItemList
      active={"true"}
      current={formatedDate === currentDate ? "true" : "false"}
    >
      <WeekItemList>
        <WeekText
          active={"true"}
          current={formatedDate === currentDate ? "true" : "false"}
        >
          {date.getDate()} de {PtBr.months_name[date.getMonth()].toLowerCase()}
        </WeekText>
        <WeekText
          active={"true"}
          current={formatedDate === currentDate ? "true" : "false"}
        >
          {PtBr.days_name[date.getDay()]}
        </WeekText>
      </WeekItemList>
      {timeBlocks?.length > 0 ? (
        <WeekList>
          {timeBlocks?.map(event => (
            <CalendarCard
              key={event.attributes.startTime}
              event={event}
              calendarProfessional={true}
              setLoading={setLoading}
            />
          ))}
        </WeekList>
      ) : (
        <WeekList>
          <ContainerImg>
            <img
              src={EmptyEvent}
              alt="empty"
              style={{ width: "70%", alignSelf: "center" }}
            />
            <p
              style={{
                color: "#999EBC",
                fontSize: "0.8rem",
                marginTop: "0.7rem",
                textAlign: "center",
              }}
            >
              Sem agendamento para o dia.
            </p>
          </ContainerImg>
        </WeekList>
      )}
    </WeekItemList>
  );
}

export default function CalendarComponent({ ...props }) {
  const { user } = useAuth();
  const { date, dayColumn } = props;
  const [schedules, setSchedules] = useState([]);
  const [blockTimes, setBlockTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [daysTimes, setDaysTimes] = useState([]);
  const defaultType = localStorage.getItem("type");
  const type = user.type || defaultType;

  useEffect(() => {
    const controller = new AbortController();
    const fetchTimeBlocks = async () => {
      try {
        const { data } = await api.get(`/professionals/availabilities/rule`, {
          signal: controller.signal,
        });

        const duration = data?.data?.attributes?.appointmentDuration;

        const appointmentTimeBlocks = Object.entries(
          data?.data?.attributes?.appointmentTimeBlocks,
        );

        const blockedTimes = Object.entries(
          data?.data?.attributes?.blockedTimes,
        );

        const timesBlock = [];

        if (appointmentTimeBlocks) {
          appointmentTimeBlocks.forEach(([key, value]) =>
            value.forEach(date => {
              timesBlock.push({
                type: "timeFree",
                day: PtBr.days_abs[key].toLowerCase(),
                attributes: {
                  startTime: moment(date).format("HH:mm"),
                  duration: duration,
                },
              });
            }),
          );
        }

        if (blockedTimes) {
          blockedTimes.forEach(([key, value]) =>
            value.forEach(date => {
              timesBlock.push({
                type: "blockedTime",
                day: PtBr.days_abs[key].toLowerCase(),
                attributes: {
                  startTime: moment(date).format("HH:mm"),
                  duration: duration,
                },
              });
            }),
          );
        }

        setBlockTimes(
          timesBlock.sort(
            (a, b) =>
              Number(a.attributes.startTime.split(":").join("")) -
              Number(b.attributes.startTime.split(":").join("")),
          ),
        );
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao buscar horários disponíveis");
      }
    };
    fetchTimeBlocks();

    return () => controller.abort();
  }, [date]);

  useEffect(() => {
    const controller = new AbortController();
    const getEvents = async () => {
      try {
        const { data } = await api.get(`/${type}s/schedules_locked_times`, {
          signal: controller.signal,
        });
        if (data.data) {
          setSchedules(data.data);
          return true;
        }
        throw new Error("Não foi possível listar os agendamentos");
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível listar os agendamentos");
        return false;
      }
    };
    getEvents();

    return () => controller.abort();
  }, [date]);

  useEffect(() => {
    const getDates = () => {
      const numberWeek = [];
      for (let i = 0; i < dayColumn; i++) {
        numberWeek.push(moment(date).add(i, "days").toDate());
      }
      return numberWeek;
    };
    const getDays = getDates();
    const daysFilter = getDays?.map(item => moment(item).format("ddd"));
    const filterTimes = blockTimes?.filter(
      item => daysFilter.includes(item.day) && item,
    );
    setDaysTimes(filterTimes);
    setDays(getDays);
  }, [blockTimes, schedules]);

  useEffect(() => {
    if (days?.length > 0 && daysTimes?.length > 0) {
      setLoading(false);
    }
  }, [days, daysTimes]);

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <CalendarContainer>
      {days?.map(item => (
        <WeekItem
          key={item}
          date={item}
          schedules={schedules}
          daysTimes={daysTimes}
          setLoading={setLoading}
        />
      ))}
    </CalendarContainer>
  );
}
