import { InputLabel, Select } from "@mui/material";
import { Field } from "formik";
import DateView from "react-datepicker";
import { HelpCircle } from "react-feather";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import styled from "styled-components";
import {
  BREAKPOINT_SM_PX,
  ScrollCardStyle,
} from "../../../styles/globalStyles";

export const DateContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const Container = styled.div`
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerInput = styled.div`
  align-items: center;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.newPrimaryAlt};
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme.ExtralightGray}`};
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: flex-start;
  width: 100%;
`;

export const TimeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  max-width: 70px;
  min-height: 50px;
`;

export const AreaContainer = styled.div`
  align-items: ${({ align }) => (align ? align : "flex-start")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const SelectContainer = styled.div`
  margin-top: -3px;
  width: 100%;

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: ${({ theme }) => theme.newPrimaryAlt};
    width: 100%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0;
    width: 100%;

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100%;
    }
  }
`;

export const StyledSelect = styled(Select)`
  height: 40px;
  min-width: 200px;
  width: fit-content;
  background-color: ${({ theme, type }) =>
    type ? "transparent" : theme.offWhite};
`;

export const StyledLabelSelect = styled(InputLabel)`
  color: ${({ theme }) => theme.black};
`;

export const FieldLabel = styled.label`
  color: ${({ theme, color }) => (color ? color : theme.black)};
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 0.25rem;
`;

export const Daylabel = styled.label`
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  color: ${({ theme }) => theme.secodaru};
`;

export const StyledField = styled(Field)`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
`;

export const StyledInputMask = styled(InputMask)`
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
  border: none;
  border-radius: 0.5rem;
  color: ${({ color }) => (color ? color : "black")};
  display: flex;
  flex-direction: row;
  height: 2.3rem;
  justify-content: flex-start;
  padding-left: 10px;
  width: 100%;
`;

export const SyledRange = styled.input`
  align-items: center;
  border: none;
  color: black;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: flex-start;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  width: 80%;
`;

export const StyledDateView = styled(DateView)`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  border: none;
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.ExtralightGray};
`;

export const RowLine = styled.section`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  label {
    width: 20%;
  }
`;
export const CheckBoxContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  padding: 10px 0;
`;

export const CheckBoxField = styled(Field)`
  align-items: flex-start;
  border: none;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  width: 100%;
`;
export const CheckBoxGroupContent = styled(ScrollCardStyle)`
  align-items: center;
  background-color: ${({ theme }) => theme.primaryAlt};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  max-height: 300px;
  min-width: 600px;
  overflow-y: scroll;
  padding: 0.5rem 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    min-width: 70%;
  }
`;

export const CheckBoxContent = styled.section`
  width: 100%;
  margin-top: 30px;
  padding: 30px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  max-height: 300px;
  overflow-y: scroll;
  background-color: transparent;
`;

export const ListWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const ButtonsContainer = styled.section`
  width: 100%;
  padding-top: 15px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const DaysContainer = styled.section`
  width: 100%;
  padding-top: 15px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 5px;
`;

export const BodyFragment = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: flex-start;
  padding: 5px 0;
  width: 100%;
`;

export const DayFragment = styled.button`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-radius: 10px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary : theme.color11};
`;

export const DayCard = styled.section``;

export const StyledCheckBox = styled.label`
  width: 23px;
  height: 23px;
  margin-right: 6px;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DayButton = styled.input.attrs({ type: "checkbox" })`
  display: none;
  width: 50px;
  height: 50px;
`;

export const Checkabel = styled.label`
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.secondary};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const FormikButton = styled.input`
  flex: 1;
  min-width: 100%;
  display: flex;
  height: 50px;
  border: none;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : theme.offWhite};
`;

export const HelpIcon = styled(HelpCircle)`
  width: 15px;
  height: 18px;
  margin-left: 5px;
  color: ${({ theme }) => theme.offWhite};
  fill: ${({ theme }) => theme.color11};
`;

export const TextError = styled.p`
  color: ${({ theme }) => theme.pink};
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;

export const InputTextCurrency = styled(IntlCurrencyInput)`
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #bcc1e0;
  height: 2.5rem;
  padding: 0 1rem;
  width: 100%;
`;
