import React from "react";
import {
  ButtonGroup,
  CheckBoxGroup,
  CheckBoxUnique,
  DatePicker,
  InputCurrency,
  InputRange,
  InputText,
  InputTextArea,
  SelectInput,
  SelectInputMultLanguage,
  TimeInput,
} from "./FormikComponents";

export default function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <InputText {...rest} />;
    case "input-currency":
      return <InputCurrency {...rest} />;
    case "textarea":
      return <InputTextArea {...rest} />;
    case "select":
      return <SelectInput {...rest} />;
    case "checkbox":
      return <CheckBoxUnique {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "checkbox-group":
      return <CheckBoxGroup {...rest} />;
    case "button-group":
      return <ButtonGroup {...rest} />;
    case "time":
      return <TimeInput {...rest} />;
    case "range":
      return <InputRange {...rest} />;
    case "selectMultLang":
      return <SelectInputMultLanguage {...rest} />;
    default:
      return null;
  }
}
