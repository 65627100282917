import { useContext } from "react";
import { EventContext } from "../providers/EventProvider";

export default function useEvent() {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error("useAtuh must be used within a EventProvider.");
  }

  return context;
}
