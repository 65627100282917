import styled from 'styled-components';
import { BREAKPOINT_SM_PX, ScrollPageStyle } from '../../styles/globalStyles';

export const Main = styled.section`
  display: grid;
  grid-template-columns: auto; /* Coluna do conteúdo principal e do menu lateral */
  grid-template-rows: min-content auto; /* Ajusta automaticamente a altura conforme o conteúdo */
  grid-template-areas:
    'header'
    'content';
  height: 100vh;
`;

export const Container = styled(ScrollPageStyle)`
  grid-area: content;
  overflow-y: auto;
  transition: 0.4s;
  padding: 1rem 3rem 2rem 3rem;
  height: calc(100vh - 12vh);

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
