/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import useAuth from "../../hooks/auth";
import api from "../../services/api";
import { handleUserNames } from "../UserElements";
import {
  ChatBody,
  ChatContainer,
  ChatContent,
  ChatHeader,
  ChatInput,
  ChatInputContainer,
  Message,
  MessageContainer,
  MessageFont,
  SendButton,
  SMFontDarkGray,
  SMFontSecondary,
  SMFontWhite,
} from "./styles";

export default function MessageChat({ ...props }) {
  const { user } = props;
  const { user: profile } = useAuth();
  const defaultType = localStorage.getItem("type");
  const type = profile.type || defaultType;
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchMessages = async () => {
      try {
        const { data } = await api.get(
          `/${type}s/messages?${
            type === "professional" ? "client" : "professional"
          }_id=${user.id}`,
          {
            signal: controller.signal,
          },
        );
        setMessages(data.data);
      } catch (erro) {
        if (!controller.signal.aborted)
          toast.error(
            "Não foi possível carregar as mensagens, por favor tente novamente",
          );
      }
    };
    fetchMessages();

    return () => controller.abort();
  }, [user]);

  const SendMessage = async () => {
    try {
      const request =
        type === "professional"
          ? { client_id: user.id, body: messageInput }
          : { professional_id: user.id, body: messageInput };
      await api.post(`/${type}s/messages`, {
        data: {
          type: "message",
          attributes: request,
        },
      });
      setMessages([
        ...messages,
        { attributes: { body: messageInput, sender: type } },
      ]);
      setMessageInput("");
    } catch (erro) {
      toast.error(
        "Não foi possível enviar essa mensagem, porfavor tente novamente",
      );
    }
  };

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ChatContainer>
      <ChatContent>
        <ChatHeader>
          <SMFontDarkGray>Bate-papo com</SMFontDarkGray>
          <SMFontSecondary style={{ marginLeft: "0.5rem", fontWeight: "700" }}>
            {handleUserNames(user)}
          </SMFontSecondary>
        </ChatHeader>
        <ChatBody>
          {messages?.map(item => (
            <MessageContainer key={item.attributes.id}>
              <Message myself={item.attributes.sender === type}>
                <MessageFont>{item.attributes.body}</MessageFont>
              </Message>
            </MessageContainer>
          ))}
          <div ref={messagesEndRef} />
        </ChatBody>
      </ChatContent>
      <ChatInputContainer>
        <ChatInput
          value={messageInput}
          onInput={e => setMessageInput(e.target.value)}
        />
        <SendButton onClick={() => SendMessage()}>
          <SMFontWhite>Enviar</SMFontWhite>
        </SendButton>
      </ChatInputContainer>
    </ChatContainer>
  );
}
