import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
} from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1.5fr 1fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-content: center;
    row-gap: 1rem;
  }
`;

export const ListContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;
