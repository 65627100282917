import styled from 'styled-components';
import { DraftailEditor } from 'draftail';

export const StyledPage = styled.div`
  width: 100%;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.darkBlue};
  align-self: flex-end;
  width: 40%;
`;

export const TitleModal = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: ${({ theme }) => theme.darkBlue};
  margin-bottom: 10px;
  width: 90%;
  text-align: center;
`;

export const RowLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.darkBlue};
  align-self: center;
`;

export const StyledDraftailEditor = styled(DraftailEditor)`
  & .DraftEditor-editorContainer {
    opacity: 1 !important;
  }
`;
