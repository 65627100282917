import CloseIcon from '@mui/icons-material/Close';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import styled from 'styled-components';
import {
  BigFont,
  BoxScroll,
  MediumFont,
  ModalBoxWrapper,
  SmallFont,
} from '../../../styles/globalStyles';

export const BackContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const BackIcon = styled(AiOutlineArrowLeft)`
  color: ${({ theme, color }) => color || theme.primary};
  cursor: pointer;
  font-size: 2rem;
  left: 0;
  margin-bottom: 1rem;
  padding: 0.15rem;
  position: absolute;
  top: 0;
`;

export const BackText = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 10px;
`;

export const ModuleContainer = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 25px;
  border: solid 1px ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ModuleText = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: ${({ theme }) => theme.white};
`;

export const LessonsContainer = styled.div`
  width: 98%;
  margin-top: 1px;
  border-bottom: solid 1px ${({ theme }) => theme.primary};
  border-left: solid 1px ${({ theme }) => theme.primary};
  border-right: solid 1px ${({ theme }) => theme.primary};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

export const ButtonLesson = styled.button`
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;

  ${({ lastIndex }) =>
    lastIndex &&
    `
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  `}

  :hover {
    background-color: ${({ theme }) => theme.newPrimaryAlt};
  }
`;

export const ButtonLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

export const ButtonLessonText = styled.p`
  font-size: 16px;
`;

export const ButtonLessonTitle = styled.p`
  color: ${({ theme }) => theme.secondary};
  font-size: 18px;
  font-weight: 700;
`;

export const ImgLesson = styled.img`
  height: 50px;
  width: 50px;
  margin: 0 2rem;
`;

export const RowLine = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.primary};
  height: 1px;
  width: 100%;
`;

export const CourseContainer = styled(BoxScroll)`
  margin-top: 2rem;
  flex-direction: column;
`;

export const WebinarContainer = styled(CourseContainer)``;

export const CourseWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const CourseWrapperLesson = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90%;
  width: 90%;
  max-width: 700px;
`;

export const ModulesWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const CourseTitle = styled(BigFont)`
  margin-bottom: 1rem;
`;

export const CourseText = styled(SmallFont)`
  margin-bottom: 1rem;
`;

export const LessonTitle = styled(MediumFont)``;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

export const BoxWrapper = styled(ModalBoxWrapper)`
  height: 100%;
  max-width: 80%;
  width: 100%;
  max-height: 90%;
`;

export const BoxWrapperLesson = styled(BoxWrapper)`
  background: #4b51d9;
`;

export const BoxWrapperScroll = styled(BoxScroll)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ImgContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-height: 300px;
  max-width: 300px;
  width: 100%;
`;

export const LessonImg = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const IconArea = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  width: 100%;
`;

export const IconAreaLesson = styled(IconArea)`
  background-color: #7a95fe;
  color: #0e1666;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  justify-content: space-between;
`;

export const GroupIconAreaLesson = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > :nth-child(1) {
    margin-left: 2rem;
  }
`;

export const GroupPlayerButton = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
`;

export const TitleBar = styled(GroupPlayerButton)`
  border-radius: 1rem;
  border: 2px solid #7a95fe;
  justify-content: center;
  margin-bottom: 0.7rem;
  margin-top: 0;
  padding: 0.25rem;
`;

export const FakeButtonTopBar = styled.span`
  background: #0e1666;
  border-radius: 50%;
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

export const FakeButtonPause = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > :nth-child(1) {
    margin-right: 0.3rem;
  }
`;

export const FakeButtonPauseBar = styled.span`
  height: 1.5rem;
  width: 0.5rem;
  background: #7a95fe;
  border-radius: 0.5rem;
`;

export const CircularPlayer = styled.span`
  background: #7a95fe;
  border-radius: 50%;
  border: 4px solid #7a95fe;
  height: 1.1rem;
  left: 4rem;
  margin-right: 0.5rem;
  position: absolute;
  top: -0.3rem;
  width: 1.1rem;
`;

export const FakeButtonNavigation = styled.div`
  background: #7a95fe;
  border-radius: 0.3rem;
  height: 0.5rem;
  position: relative;
  width: 75%;
`;

export const FakeButtonSpace = styled(FakeButtonNavigation)`
  width: 10%;
`;

export const VideoContainer = styled.div`
  align-items: center;
  background: #0e1666;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 85%;
`;
