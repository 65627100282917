import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import imgAgendarData from '../../../images/img agendar data.svg';
import imgAgendarHorarios from '../../../images/img agendar horarios png.png';
import imgBuscaProfissional from '../../../images/img busca profissional.svg';
import imgFiltros from '../../../images/img filtros.svg';
import imgLogo from '../../../images/logo-mind-white.svg';
import imgProximoEventoFoto from '../../../images/img proximo evento foto.svg';
import imgProximoEvento from '../../../images/img proximos eventos.svg';
import imgReview from '../../../images/img review.svg';
import useWindowSize from '../../../utils/WindowSize';
import './styles.css';

export default function OnboardingClient({ handleModal }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [currentPage, setCurrentPage] = useState('0');
  const { width } = useWindowSize();

  return (
    <Modal open onClose={() => null}>
      <Box
        style={{
          background: 'linear-gradient(180deg, #C0CCF9 0%, #FFFFFF 100%)',
          borderRadius: '20px',
        }}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width > 1024 ? 515 : 350,
          height: 600,
          p: 2.5,
        }}
      >
        <Swiper
          cssMode={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          onSlideChange={(swiper) => setCurrentPage(swiper.activeIndex)}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgBuscaProfissional}
                style={{
                  height: 'auto',
                  width: '50%',
                  zIndex: 2,
                  marginBottom: '55px',
                  marginRight: '30px',
                }}
                alt="imgBuscaProfissional"
              />
              <img
                src={imgFiltros}
                style={{
                  height: 'auto',
                  width: '50%',
                  zIndex: 1,
                  marginRight: '-60px',
                  marginTop: '50px',
                  marginLeft: '-75px',
                }}
                alt="imgFiltros"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Busca de profissionais
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  Através da aba “Buscar especialistas” você pode encontrar
                  todos os profissionais que estão disponíveis para lhe atender
                  da maneira que achar mais conveniente, seja de forma
                  presencial, ou através da modalidade online.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgAgendarData}
                style={{
                  height: 'auto',
                  width: '50%',
                  zIndex: 2,
                  marginBottom: '0px',
                  marginRight: '-40px',
                }}
                alt="imgAgendarData"
              />
              <img
                src={imgAgendarHorarios}
                style={{
                  height: 'auto',
                  width: '50%',
                  zIndex: 1,
                  marginRight: '0px',
                  marginTop: '0px',
                }}
                alt="imgAgendarHorarios"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Agendamento
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  Após escolher o seu profissional, você pode verificar a agenda
                  e disponibilidade de horários no perfil dele. Para agendar um
                  atendimento, basta selecionar o dia e horário desejado e
                  selecionar a opção “Agendar atendimento”. Em seguida, cadastre
                  os dados de pagamento e a sua consulta será confirmada.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgProximoEvento}
                style={{
                  height: 'auto',
                  width: '60%',
                  zIndex: 2,
                  marginBottom: '-100px',
                  marginRight: '80px',
                }}
                alt="imgProximoEvento"
              />
              <img
                src={imgProximoEventoFoto}
                style={{
                  height: 'auto',
                  width: '60%',
                  zIndex: 1,
                  marginRight: '-150px',
                  marginTop: '-40px',
                }}
                alt="imgProximoEventoFoto"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Atendimento
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  Para realizar o seu atendimento online, basta logar no seu
                  perfil na Allminds no dia e horário agendado com o
                  profissional escolhido e clicar em “iniciar atendimento”. Logo
                  você será direcionado para a página do Zoom, aonde acontecerá
                  o seu atendimento. Você também pode escolher por atendimentos
                  presenciais.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgReview}
                style={{
                  height: 'auto',
                  width: '80%',
                  marginTop: '40px',
                }}
                alt="imgReview"
              />
              <section style={{ alignSelf: 'flex-end' }}>
                <Typography style={{ alignSelf: 'flex-end' }} variant="h5">
                  Review
                </Typography>
                <Typography
                  style={{ alignSelf: 'flex-end', marginBottom: '50px' }}
                  variant="body1"
                >
                  É muito importante que você nos conte de maneira geral, o que
                  achou do atendimento realizado pelo profissional escolhido.
                  Você pode optar por fazer esse comentário de forma anônima.
                  Mas se preferir não deixar comentário, pode apenas opinar
                  através de um like ou dislike.
                </Typography>
              </section>
            </Grid>
          </SwiperSlide>
          <SwiperSlide>
            <Grid
              style={{ height: '100%' }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                src={imgLogo}
                style={{
                  height: 'auto',
                  width: '80%',
                  marginTop: '40px',
                }}
                alt="imgLogo"
              />
              <section style={{ alignSelf: 'flex-start' }}>
                <Typography
                  style={{ alignSelf: 'flex-start', marginBottom: '50px' }}
                  variant="h5"
                >
                  {'Seja bem-vindo(a) a Allminds.'}
                </Typography>
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  container
                >
                  <Button
                    style={{
                      marginTop: '-25px',
                      width: '195px',
                      height: 'auto',
                      fontSize: '18px',
                    }}
                    sx={{ m: 1 }}
                    onClick={handleModal}
                    size="small"
                    variant="contained"
                  >
                    Começar
                  </Button>
                </Grid>
              </section>
            </Grid>
          </SwiperSlide>
        </Swiper>

        <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
        >
          <Button
            disabled={currentPage > 0 ? false : true}
            sx={{ m: 1 }}
            onClick={null}
            size="small"
            variant="outlined"
            ref={navigationPrevRef}
          >
            Anterior
          </Button>
          <Button
            disabled={currentPage !== 4 ? false : true}
            sx={{ m: 1 }}
            onClick={null}
            size="small"
            variant="contained"
            ref={navigationNextRef}
          >
            Próximo
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
