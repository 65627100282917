/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { SchedulesListClinicItem } from '../../../components/ListItens';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import sortedSchedules from '../../../utils/sortedSchedules';
import { Main, ScheduleWrapper } from './styles';

export default function SchedulesReport() {
  const { user } = useAuth();
  const {
    loadingEvent,
    loadingPastEvent,
    setLoadingEvent,
    setLoadingPastEvent,
  } = useEvent();
  const [schedules, setSchedules] = useState([]);
  const [pastSchedules, setPastSchedules] = useState([]);

  const fetchSchedules = useCallback(async () => {
    const controller = new AbortController();
    try {
      const { data } = await api.get(`${user.type}s/schedules`, {
        signal: controller.signal,
      });
      if (data.data) {
        setSchedules(data.data);
        return;
      }
      throw new Error('Não foi possível carregar os agendamentos');
    } catch (error) {
      return toast.error('Não foi possível carregar os agendamentos');
    } finally {
      setLoadingEvent(false);
    }
  }, [loadingEvent]);

  const fetchPastSchedules = useCallback(async () => {
    const controller = new AbortController();
    try {
      const { data } = await api.get(`${user.type}s/schedule-history`, {
        signal: controller.signal,
      });
      if (data.data) {
        setPastSchedules(data.data);
        return;
      }
      throw new Error('Não foi possível carregar os agendamentos concluidos');
    } catch (error) {
      return toast.error(
        'Não foi possível carregar os agendamentos concluidos'
      );
    } finally {
      setLoadingPastEvent(false);
    }
  }, [loadingPastEvent]);

  useEffect(() => {
    fetchPastSchedules();
    fetchSchedules();
  }, [fetchPastSchedules, fetchSchedules]);

  const schedulesAll = useMemo(() => {
    return [...pastSchedules, ...schedules].sort((a, b) => {
      const dateA = moment(a.attributes.startDate);
      const dateB = moment(b.attributes.startDate);

      return dateA.isBefore(dateB) ? 1 : -1;
    });
  }, [pastSchedules, schedules]);

  return (
    <Main>
      <ScheduleWrapper>
        <Flatlist
          data={sortedSchedules(schedulesAll, true)}
          dateSearch
          filterObject={(schedule) => schedule.attributes.search}
          placeholder="Buscar Consultas"
          renderItem={(schedule, index) => (
            <SchedulesListClinicItem
              key={`${schedule.id} ${index}`}
              data={schedule}
              schedule="true"
            />
          )}
          type="schedule"
          searchType="clinic"
          loading={loadingEvent || loadingPastEvent}
        />
      </ScheduleWrapper>
    </Main>
  );
}
