import styled from 'styled-components';
import { ReactComponent as Empty } from '../../../images/msgEmpty.svg';
import {
  BREAKPOINT_SM_PX,
  BigFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const EmptyMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 100%;
  }
`;

export const EmptyImage = styled(Empty)`
  height: 250px;
  padding: 30px 0;
`;

export const EmptyText = styled(SmallFont)`
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.darkGray};
`;

export const EmptyTitle = styled(BigFont)`
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;
