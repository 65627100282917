import React from 'react';
import { EmptyMain, EmptyImage, EmptyText, EmptyTitle } from './styles';

export default function EmptyContainer({ ...props }) {
  const { title, text } = props;
  return (
    <EmptyMain>
      <EmptyImage />
      <EmptyTitle>{title}</EmptyTitle>
      <EmptyText>{text}</EmptyText>
    </EmptyMain>
  );
}
