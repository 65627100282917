/* eslint-disable react-hooks/exhaustive-deps */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { addZero } from '../../../services';
import { PtBr } from '../../../services/pt_br';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
} from '../../../styles/globalStyles';
import useWindowSize from '../../../utils/WindowSize';
import { CalendarComponent } from './Components';
import {
  ArrowLeft,
  ArrowRight,
  BodyContainer,
  ButtonsContainer,
  CalendarButton,
  DateInfo,
  InfoDay,
  InfoMonth,
  MenuHeader,
} from './styles';

export default function CalendarClientScreen() {
  const { width } = useWindowSize();
  const [date, setDate] = useState(moment().toDate());
  const [daysWeek, setDaysWeek] = useState(0);

  useEffect(() => {
    const dayColumn = () => {
      if (width <= BREAKPOINT_SM) return CALENDAR_COLUMN_SM;
      if (width <= BREAKPOINT_MD) return CALENDAR_COLUMN_MD;
      if (width <= BREAKPOINT_LG) return CALENDAR_COLUMN_LG;
      if (width > BREAKPOINT_LG) return CALENDAR_COLUMN_XL;
    };
    setDaysWeek(dayColumn());
  }, [width]);

  useEffect(() => {
    const primaryWeekDay = (days) => {
      let day = moment().format('ddd');
      if (days <= 3) return moment(day, 'ddd').toDate();
      while (day !== 'dom') {
        day = moment(day, 'ddd').subtract(1, 'days').format('ddd');
      }
      return moment(day, 'ddd').toDate();
    };
    setDate(primaryWeekDay(daysWeek));
  }, [daysWeek]);

  const sumSubDate = (days, method) =>
    method === 'add'
      ? moment(date, 'YYYY-MM-DD').add(days, 'days').toDate()
      : moment(date).subtract(days, 'days').toDate();

  const lastDate = sumSubDate(daysWeek - 1, 'add');

  return (
    <BodyContainer>
      <MenuHeader>
        <ButtonsContainer>
          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'sub'))}>
            <ArrowLeft />
          </CalendarButton>

          {daysWeek === 1 ? (
            <DateInfo>
              <InfoMonth>
                {PtBr.months_name[date.getMonth()].toUpperCase()}
              </InfoMonth>
            </DateInfo>
          ) : (
            <DateInfo>
              <InfoMonth>
                {lastDate.getMonth() === date.getMonth()
                  ? PtBr.months_name[date.getMonth()].toUpperCase()
                  : `${PtBr.months_name[
                      date.getMonth()
                    ].toUpperCase()} / ${PtBr.months_name[
                      lastDate.getMonth()
                    ].toUpperCase()}`}
              </InfoMonth>
              <InfoDay>
                {daysWeek === 1
                  ? `${addZero(date.getDate())}`
                  : `${addZero(date.getDate())} a ${addZero(
                      lastDate.getDate()
                    )}`}
              </InfoDay>
            </DateInfo>
          )}

          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'add'))}>
            <ArrowRight />
          </CalendarButton>
        </ButtonsContainer>
      </MenuHeader>
      <CalendarComponent date={date} dayColumn={daysWeek} />
    </BodyContainer>
  );
}
