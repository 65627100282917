import React from 'react';
import { MainContainer } from './styles';
import {
  CardsList,
  PaymentHistory,
} from '../../Professional/ProfessionalUpdate/Subscribe';

export default function ClientFinanceScreen() {
  return (
    <MainContainer>
      <CardsList />
      <PaymentHistory />
    </MainContainer>
  );
}
