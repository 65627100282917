import React from "react";
import {
  BorderContainer,
  CheckContainer,
  CheckText,
  Container,
  DinamicContainer,
  LinkContainer,
  SlimContainer,
} from "./styles";

export function StaticButton({ ...props }) {
  const {
    disabled,
    title,
    onClick,
    buttonColor,
    children,
    height,
    width,
    reverse,
    type = "submit",
  } = props;
  return (
    <Container
      height={height}
      width={width}
      type={type}
      color={buttonColor}
      onClick={onClick}
      reverse={reverse}
      disabled={disabled}
      {...props}
    >
      {children || <label color={buttonColor}>{title}</label>}
    </Container>
  );
}

export function BorderButton({ ...props }) {
  const { title, onClick, buttonColor, brdColor, children, ...rest } = props;
  return (
    <BorderContainer
      type="submit"
      {...rest}
      color={buttonColor}
      brdColor={brdColor}
      onClick={onClick}
      s
    >
      {children ? <label>{children}</label> : <label {...props}>{title}</label>}
    </BorderContainer>
  );
}

export function SlimButton({ ...props }) {
  const { title, onClick, buttonColor, disabled, children } = props;
  return (
    <SlimContainer disabled={disabled} color={buttonColor} onClick={onClick}>
      {children || <label {...props}>{title}</label>}
    </SlimContainer>
  );
}

export function CheckButton({ ...props }) {
  const { title, children } = props;
  return (
    <CheckContainer {...props}>
      {children || <CheckText {...props}>{title}</CheckText>}
    </CheckContainer>
  );
}

export function SmallButton({ ...props }) {
  const { title, children, color } = props;
  return (
    <DinamicContainer color={color} {...props}>
      {children || title}
    </DinamicContainer>
  );
}

export function LinkButton({ ...props }) {
  const { label, disabled } = props;
  return (
    <LinkContainer large disabled={disabled} {...props}>
      {label}
    </LinkContainer>
  );
}

export function PrimaryButton({ ...props }) {
  const { iconEnd, iconStart, children, ...rest } = props;
  return (
    <Container type="button" {...rest}>
      {iconStart && (
        <div
          style={{
            marginRight: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {iconStart}
        </div>
      )}
      {children}
      {iconEnd && (
        <div
          style={{
            marginLeft: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {iconEnd}
        </div>
      )}
    </Container>
  );
}
