/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StaticButton } from '../../../components/Button';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import validateCPF from '../../../utils/validateCPF';
import {
  validateAddress,
  validateInfoLegalGuardian,
  validatePerson,
} from '../../../utils/validateInfos';
import {
  UpdateAdress,
  UpdateBasicData,
  UpdateLegalGuardian,
  UpdatePassword,
} from '../../Professional/Config/AccountConfig';
import { ConfigWrapper } from '../../Professional/Config/styles';
import {
  ConfigBody,
  ConfigMain,
  MenuHeader,
  MenuOption,
  OptionText,
  RowLine,
} from './styles';

const formatedDate = (date) => {
  if (date) {
    if (moment(date).isValid()) {
      return moment(date).format('DD/MM/YYYY');
    }
  }
  return null;
};

function TopMenuBar({ ...props }) {
  const { options, setSelected, selected, validateInfoClient } = props;
  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
        >
          <OptionText active={selected === item}>{item}</OptionText>
          {item === 'Dados Básicos' && validateInfoClient?.person && (
            <span
              style={{
                width: '0.7rem',
                height: '0.7rem',
                backgroundColor: '#EB5757',
                borderRadius: '50%',
                position: 'absolute',
                top: '-0.4rem',
                right: '0.6rem',
              }}
            ></span>
          )}
          {item === 'Responsável' && validateInfoClient?.legalGuardian && (
            <span
              style={{
                width: '0.7rem',
                height: '0.7rem',
                backgroundColor: '#EB5757',
                borderRadius: '50%',
                position: 'absolute',
                top: '-0.4rem',
                right: '0.6rem',
              }}
            ></span>
          )}
          {item === 'Endereço' && validateInfoClient?.address && (
            <span
              style={{
                width: '0.7rem',
                height: '0.7rem',
                backgroundColor: '#EB5757',
                borderRadius: '50%',
                position: 'absolute',
                top: '-0.4rem',
                right: '0.6rem',
              }}
            ></span>
          )}
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

export default function ClientConfigs() {
  const history = useHistory();
  const { user, updateClient, updatePassword } = useAuth();
  const { scheduleInfo } = useEvent();
  const [selected, setSelected] = useState('Dados Básicos');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [validateInfoClient, setValidateInfoClient] = useState({
    person: false,
    address: false,
  });

  const schemaInfoPerson = Yup.object({
    avatar: Yup.string().nullable().optional(),
    firstName: Yup.string().required('Nome é obrigatorio'),
    lastName: Yup.string().required('Sobrenome é obrigatorio'),
    documentNumber: Yup.string()
      .required('Numero do documento é obrigatorio')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '') || null;
        },
      }),
    phone: Yup.string()
      .required('Telefone é obrigatorio')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Telefone inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    birthday: Yup.string('Formato de data inválido')
      .required('Data de nascimento é obrigatorio')
      .matches(/^(\d{2}\/\d{2}\/\d{4})$/, {
        message: 'Formato de data inválido. Utilize o formato DD/MM/YYYY.',
      })
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'Data de nascimento inválida',
        test(value) {
          if (!value) {
            return false; // Retorna falso para acionar a mensagem de "required"
          }

          const isValidFormat = moment(value, 'DD/MM/YYYY', true).isValid();
          if (!isValidFormat) {
            return false; // Retorna falso para acionar a mensagem de "matches"
          }

          return true;
        },
      }),
    needLegalGuardian: Yup.boolean().when('birthday', {
      is: (birthday) => {
        const birthdayFormat = moment(birthday, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
        const age = moment().diff(moment(birthdayFormat), 'years');
        return age < 18;
      },
      then: Yup.boolean().optional(),
    }),
  });

  const schemaLegalGuardian = Yup.object({
    legalGuardianFullName: Yup.string().when('needLegalGuardian', {
      is: true,
      then: Yup.string().required('Nome do responsável é obrigatorio.'),
    }),
    legalGuardianEmail: Yup.string().when('needLegalGuardian', {
      is: true,
      then: Yup.string().required('Email do responsável é obrigatorio.'),
    }),
    legalGuardianDocumentNumber: Yup.string().when('needLegalGuardian', {
      is: true,
      then: Yup.string()
        .required('CPF do responsável é obrigatorio.')
        .test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'CPF inválido',
          test(value) {
            return validateCPF(value || '') || null;
          },
        }),
    }),
    legalGuardianBirthday: Yup.string().when('needLegalGuardian', {
      is: true,
      then: Yup.string()
        .required('Data de nascimento do responsável é obrigatorio.')
        .matches(/^(\d{2}\/\d{2}\/\d{4})$/, {
          message: 'Formato de data inválido. Utilize o formato DD/MM/YYYY.',
        })
        .test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'O responsável precisa ser maior de 18 anos.',
          test(value) {
            if (!value) {
              return false; // Retorna falso para acionar a mensagem de "required"
            }

            const isValidFormat = moment(value, 'DD/MM/YYYY', true).isValid();
            if (!isValidFormat) {
              return false; // Retorna falso para acionar a mensagem de "matches"
            }

            const today = moment();
            const birthday = moment(value, 'DD/MM/YYYY');
            const age = today.diff(birthday, 'years');
            return age < 18 ? false : true;
          },
        }),
    }),
    legalGuardianPhone: Yup.string().when('needLegalGuardian', {
      is: true,
      then: Yup.string()
        .required('Telefone do responsável é obrigatorio.')
        .test({
          name: 'isValid',
          exclusive: false,
          params: {},
          message: 'Telefone inválido',
          test(value) {
            const maskDefaultValue = '_';
            const str = value;
            const result = str?.search(maskDefaultValue);
            return result > -1 ? false : true;
          },
        }),
    }),
  });

  const schemaAddress = Yup.object({
    cep: Yup.string()
      .required('CEP é obrigatorio')
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CEP inválido',
        test(value) {
          const maskDefaultValue = '_';
          const str = value;
          const result = str?.search(maskDefaultValue);
          return result > -1 ? false : true;
        },
      }),
    street: Yup.string().required('Endereço é obrigatorio'),
    neighborhood: Yup.string().required('Bairro é obrigatorio'),
    country: Yup.string().required('Pais é obrigatorio'),
    city: Yup.string().required('Cidade é obrigatorio'),
    state: Yup.string().required('Estado é obrigatorio'),
    complement: Yup.string().optional(),
    street_number: Yup.string().required('Numero é obrigatorio'),
  });

  const schemaSecurity = Yup.object({
    password: Yup.string().required('Senha atual é obrigatória'),
    newPassword: Yup.string()
      .min(8, 'A senha deve possuir no mínimo 8 caracteres')
      .required('Nova senha é obrigatória'),
    confirmPassword: Yup.string()
      .required('Confirmação da senha é obrigatorio!')
      .oneOf([Yup.ref('newPassword'), ''], 'As senhas devem ser iguais.'),
  });

  const schemas = () => {
    switch (selected) {
      case 'Dados Básicos':
        return schemaInfoPerson;
      case 'Responsável':
        return schemaLegalGuardian;
      case 'Endereço':
        return schemaAddress;
      case 'Segurança':
        return schemaSecurity;
      default:
        return schemaInfoPerson;
    }
  };

  const form = useFormik({
    initialValues: {
      avatar: user?.avatar || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      documentNumber: user?.documentNumber || '',
      birthday: formatedDate(user?.birthday) || '',
      phone: user?.phone || '',
      needLegalGuardian: user?.needLegalGuardian || false,
      legalGuardianFullName: user?.legalGuardianFullName || '',
      legalGuardianEmail: user?.legalGuardianEmail || '',
      legalGuardianDocumentNumber: user?.legalGuardianDocumentNumber || '',
      legalGuardianBirthday: formatedDate(user?.legalGuardianBirthday) || '',
      legalGuardianPhone: user?.legalGuardianPhone || '',
      cep: user?.address?.cep || '',
      street: user?.address?.street || '',
      neighborhood: user?.address?.neighborhood || '',
      country: user?.address?.country || '',
      state: user?.address?.state || '',
      city: user?.address?.city || '',
      complement: user?.address?.complement || '',
      street_number: user?.address?.street_number || '',
      schoolId: user?.school?.id || null,
    },
    validationSchema: schemas(),
    validateOnChange: true,
    onSubmit: (values) => {
      if (selected === 'Segurança') submitFormSecurity(values);
      else submitForm(values);
    },
  });

  useEffect(() => {
    const birthday = moment(form.values?.birthday, 'DD/MM/YYYY');
    const age = moment().diff(moment(birthday), 'years');
    const needLegalGuardian = age < 18;
    form.setFieldValue('needLegalGuardian', needLegalGuardian);
    if (needLegalGuardian) {
      setValidateInfoClient({
        person: validatePerson(form.values),
        address: validateAddress(form.values),
        legalGuardian: validateInfoLegalGuardian(form.values),
      });
    } else {
      setValidateInfoClient({
        person: validatePerson(form.values),
        address: validateAddress(form.values),
      });
    }
  }, [form.values]);

  const validateSubmit = (values) => {
    const validationInfos = Object.values(validateInfoClient)?.every(
      (info) => info === false
    );

    if (validationInfos) {
      if (scheduleInfo?.professional?.id) {
        history.push(`/client/schedule/${scheduleInfo?.professional?.id}`);
      } else {
        setSelected(selected);
      }
    } else if (values.needLegalGuardian && validateInfoClient?.legalGuardian) {
      setSelected('Responsável');
    } else if (validateInfoClient?.address) {
      setSelected('Endereço');
    } else setSelected('Dados Básicos');
  };

  const submitForm = async (values) => {
    try {
      setSubmitLoading(true);
      const success = await updateClient(values, user.id);
      if (success) {
        toast.success('Cadastro atualizado com sucesso!');
        validateSubmit(values);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const submitFormSecurity = async (values) => {
    setSubmitLoading(true);
    try {
      const data = await updatePassword(values, user?.type, user?.id);
      if (data.error) {
        return toast.error('Senha inválida');
      }
      if (data) {
        return toast.success('Senha alterada com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao alterar senha');
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/client/config',
        state: { selected: selected },
      });
    }
  };

  const options = [
    'Dados Básicos',
    'Responsável',
    'Endereço',
    'Segurança',
  ]?.filter((option) =>
    form.values?.needLegalGuardian === false ? option !== 'Responsável' : option
  );

  const validateInfos = (values) => {
    setDisabled(values);
  };

  const storeAvatarAsBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      form.setFieldValue('avatar', reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar
          options={options}
          selected={selected}
          setSelected={setSelected}
          validateInfoClient={validateInfoClient}
        />
        <RowLine />
        {submitLoading ? (
          <LoaderSpinner />
        ) : (
          <ConfigBody>
            {selected === 'Dados Básicos' && (
              <UpdateBasicData
                form={form}
                storeAvatarAsBase64={storeAvatarAsBase64}
                user={user}
                validateInfos={validateInfos}
                validateNeedLegalGuardian={validateInfoLegalGuardian(user)}
              />
            )}
            {selected === 'Responsável' && (
              <UpdateLegalGuardian
                form={form}
                validateInfos={validateInfos}
                user={user}
              />
            )}
            {selected === 'Endereço' && (
              <UpdateAdress
                form={form}
                validateInfos={validateInfos}
                user={user}
              />
            )}
            {selected === 'Segurança' && (
              <UpdatePassword form={form} validateInfos={validateInfos} />
            )}
            <ConfigWrapper style={{ marginTop: '3rem' }}>
              <StaticButton
                height="3rem"
                title={submitLoading ? 'Carregando...' : 'Salvar alterações'}
                disabled={disabled}
              />
            </ConfigWrapper>
          </ConfigBody>
        )}
      </ConfigMain>
    </FormikProvider>
  );
}
