/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './providers/AuthProvider';
import { EventProvider } from './providers/EventProvider';
import Router from './routes';
import GlobalStyles from './styles/globalStyles';
import theme from './theme';
import { pageViewEvent } from './utils/facebookPixel';

export default function App() {
  useEffect(() => {
    hotjar.initialize(3506248);
    hotjar.event('page_view');
    pageViewEvent();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthProvider>
        <EventProvider>
          <Router />
          <ToastContainer />
        </EventProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
