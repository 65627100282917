/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { X } from "react-feather";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ColumnItem } from "../../../components/ListItens/styles";
import LoaderSpinner from "../../../components/LoaderSpinner";
import useAuth from "../../../hooks/auth";
import api, { brasilApi } from "../../../services/api";
import theme from "../../../theme";
import convertCurrencyPtBr from "../../../utils/convertCurrencyPtBr";
import { addLeftZeros } from "../../Professional/BankAccount";
import {
  BanckAccountContainer,
  BoxModalWrapper,
  BoxWrapper,
  Button,
  ButtonMaxWithdraw,
  ButtonWithdraw,
  ButtonWithdrawWrapper,
  ContainerItem,
  ContainerItemLeft,
  Hr,
  IconArea,
  InputWithdraw,
  InputWrapper,
  MDFontDark,
  MDFontRed,
  MainContainer,
  PayOutContainer,
  SMFont,
} from "./styles";
import { CardsList } from "../Subscribe";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default function FinanceScreen() {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [bank, setBank] = useState(null);

  useEffect(() => {
    const fetchBankCodes = async () => {
      try {
        setLoading(true);
        await brasilApi
          .get()
          .then(({ data, errors }) => {
            if (errors) {
              throw new Error("Erro ao carregar bancos");
            }
            const bankFullName = data?.filter(
              bank =>
                addLeftZeros(bank.code, 3) === user?.paymentAccount?.bankCode,
            )[0]?.fullName;
            setBank({
              label: bankFullName,
              value: bankFullName,
            });
          })
          .catch(error => toast.error(error));
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBankCodes();
  }, []);

  const SERVICE_CHARGE = 3.67;

  const schema = yup.object().shape({
    withdraw: yup
      .number()
      .positive("Valor deve ser positivo.")
      .min(4.67, "Valor mínimo para saque é de R$ 4,67.")
      .required("Campo Requerido."),
  });

  const fetchFinances = async controller => {
    try {
      const { data } = await api.get("/clinics/transactions", {
        signal: controller?.signal,
      });
      if (data.data) {
        setUserInfo(data.data.attributes.balance);
      }
    } catch (error) {
      if (!controller?.signal?.aborted) toast.error("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      const request = {
        data: {
          attributes: {
            amount: (values.withdraw - 3.67) * 100,
          },
        },
      };
      const response = await api.post(
        "/professionals/transactions/withdraw",
        request,
      );
      if (response.status === 200) {
        toast.success("Saque realizado com sucesso!");
        setModal(false);
        fetchFinances();
      } else {
        toast.error("Erro ao realizar saque.");
        setModal(false);
      }
    } catch (error) {
      toast.error("Erro ao realizar saque");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setValues({ withdraw: value }); // value without mask (ex: 1234.56)
  };

  const form = useFormik({
    initialValues: {
      withdraw: 0,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: values => {
      setTimeout(() => {
        handleClick(values);
      }, 400);
    },
    onChange: (event, value) => {
      handleChange(event, value);
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    fetchFinances(controller);

    return () => controller.abort();
  }, []);

  const { values, setValues, errors, touched } = form;

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <MainContainer>
      <ContainerItemLeft>
        <BanckAccountContainer>
          <SMFont
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "700",
              lineHeight: "2.5rem",
              marginBottom: "1rem",
            }}
          >
            Conta corrente cadastrada:
          </SMFont>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SMFont
              style={{
                color: "white",
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "0.25rem",
              }}
            >
              Banco: <span style={{ fontWeight: "400" }}>{bank?.label}</span>
            </SMFont>
            <SMFont
              style={{
                color: "white",
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "0.25rem",
              }}
            >
              CC:{" "}
              <span style={{ fontWeight: "400" }}>
                {user?.paymentAccount?.bankCc}
              </span>
            </SMFont>
            <SMFont
              style={{
                color: "white",
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "0.25rem",
              }}
            >
              Agência:{" "}
              <span style={{ fontWeight: "400" }}>
                {user?.paymentAccount?.bankAg}
              </span>
            </SMFont>
            <SMFont
              style={{
                color: "white",
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "0.25rem",
              }}
            >
              {`${
                user?.paymentAccount?.documentNumber.length > 11
                  ? "CNPJ: "
                  : "CPF: "
              }`}
              <span style={{ fontWeight: "400" }}>
                {user?.paymentAccount?.documentNumber}
              </span>
            </SMFont>
          </div>
        </BanckAccountContainer>
        <CardsList finance />
      </ContainerItemLeft>

      <ContainerItem>
        <PayOutContainer style={{ alignItems: "center" }}>
          <SMFont
            style={{
              color: theme.darkBlue,
              fontSize: "2rem",
              lineHeight: "2.5rem",
            }}
          >
            Resumo do Saldo
          </SMFont>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <ColumnItem>
              <PayOutContainer className="funds">
                <MDFontRed>
                  {convertCurrencyPtBr(userInfo?.waitingFunds)}
                </MDFontRed>
                <SMFont style={{ color: "#040817" }}>Faturamento total</SMFont>
              </PayOutContainer>
              <PayOutContainer className="invoice">
                <MDFontDark>
                  {convertCurrencyPtBr(userInfo?.transferred)}
                </MDFontDark>
                <SMFont style={{ color: "#040817" }}>Valor transferido</SMFont>
              </PayOutContainer>
              <PayOutContainer className="withdraw">
                <MDFontDark>
                  {convertCurrencyPtBr(userInfo?.available)}
                </MDFontDark>
                <SMFont style={{ color: "#040817", marginBottom: "0.325rem" }}>
                  Saldo Disponível para saque
                </SMFont>
                <Button
                  title="Sacar valor disponível"
                  onClick={() => setModal(true)}
                />
              </PayOutContainer>
            </ColumnItem>
          )}
        </PayOutContainer>
      </ContainerItem>

      {modal && (
        <Modal open>
          <BoxModalWrapper
            style={{
              width: "600px",
              height: "400px",
              padding: 0,
            }}
          >
            <BoxWrapper>
              <IconArea style={{ cursor: "default" }}>
                <X
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  onClick={() => setModal(false)}
                />
              </IconArea>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Você tem um saldo disponivel de{" "}
                <span
                  style={{
                    fontSize: "1.5rem",
                    color: "#16B83E",
                    fontWeight: "bold",
                  }}
                >
                  {convertCurrencyPtBr(userInfo?.available)}
                </span>
                . Informe o valor desejado para o saque!
              </Typography>
              <InputWrapper>
                <InputWithdraw
                  currency="BRL"
                  value={values.withdraw}
                  config={currencyConfig}
                  onChange={handleChange}
                />
                <ButtonMaxWithdraw
                  disabled={userInfo.available > SERVICE_CHARGE ? false : true}
                  onClick={() => setValues({ withdraw: userInfo.available })}
                >
                  Sacar tudo
                </ButtonMaxWithdraw>
              </InputWrapper>
              <Typography
                sx={{
                  color: "#FD6492",
                  fontSize: "0.8rem",
                  textAlign: "center",
                  width: "80%",
                }}
              >
                {errors.withdraw || touched.withdraw}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                  width: "80%",
                  fontWeight: 700,
                }}
              >
                {`Taxa de serviço: ${convertCurrencyPtBr(
                  SERVICE_CHARGE,
                )} por saque.`}
              </Typography>
              {values.withdraw > userInfo?.available ? (
                <Typography
                  sx={{
                    color: "#FD6492",
                    fontSize: "0.8rem",
                    textAlign: "center",
                    width: "80%",
                    fontWeight: 700,
                  }}
                >
                  Saldo insuficiente!
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    width: "80%",
                    fontWeight: 700,
                  }}
                >
                  {values.withdraw > 0 && (
                    <Fragment>
                      <p>
                        Valor debitado em carteira de{" "}
                        <span
                          style={{ color: "#FD6492" }}
                        >{`${convertCurrencyPtBr(values.withdraw)}.`}</span>
                      </p>
                      <p>
                        Valor creditado em conta{" "}
                        <span
                          style={{ color: "#16B83E" }}
                        >{`${convertCurrencyPtBr(
                          values.withdraw - SERVICE_CHARGE,
                        )}.`}</span>
                      </p>
                    </Fragment>
                  )}
                </Typography>
              )}
              <ButtonWithdrawWrapper>
                <Hr />
                <ButtonWithdraw
                  disabled={
                    values.withdraw < SERVICE_CHARGE + 1 ||
                    values.withdraw > userInfo?.available
                  }
                  onClick={handleClick}
                >
                  Sacar
                </ButtonWithdraw>
              </ButtonWithdrawWrapper>
            </BoxWrapper>
          </BoxModalWrapper>
        </Modal>
      )}
    </MainContainer>
  );
}
