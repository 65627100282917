import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
} from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    justify-items: center;
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 60%;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 90%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;
