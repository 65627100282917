import styled from 'styled-components';
import { BREAKPOINT_SM_PX, BigFont } from '../../../styles/globalStyles';

export const BankFormContainer = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0 1em 0 1em;
  }
`;

export const BankFormTitle = styled(BigFont)`
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.black};
`;

export const BankInputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
`;

export const BankInputWrapperRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const BankInputWrapperColumnInline = styled.div`
  align-items: center;
  display: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.5rem;
  width: 48%;
`;

export const BankInputWrapperColumn = styled(BankInputWrapperColumnInline)`
  width: 100%;
`;
