import { User } from "react-feather";
import Modal from "react-modal";
import styled from "styled-components";
import { SmallButton } from "../../../components/Button";
import {
  BREAKPOINT_SM_PX,
  BigFont,
  SmallFont,
} from "../../../styles/globalStyles";

export const BodyContainer = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const MenuHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuOption = styled.button`
  background-color: ${({ active }) => (active ? "#040817" : "#fff")};
  border-radius: 20px;
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  position: relative;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 0.325rem;
    margin-right: 1rem;
  }
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionText = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.black)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;

export const MedicalRecordCard = styled.section`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.ExtralightGray};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  width: 100%;
`;

export const NotesCard = styled.section`
  flex: 1;
  width: 90%;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.ExtralightGray};
`;

export const UserProfile = styled.div`
  padding: 15px;
  display: flex;
  min-width: 270px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.offWhite};
`;

export const RowItem = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const UserIcon = styled(User)`
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.darkGray};
`;

export const Button = styled.button`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 150%;
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
`;

export const ProfileName = styled(BigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const SMFontGray = styled(SmallFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.darkGray};
`;

export const SMFontLightGray = styled(SmallFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.lightGray};
`;

export const StyledButton = styled(SmallButton)`
  padding: 5px;
  margin-top: 10px;
  width: fit-content;
  border-radius: 10px;
`;

export const InputContainer = styled.section`
  width: 55%;
  padding-top: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.section`
  padding: 15px 0;
  width: 55%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const CancelButton = styled(SmallButton)`
  height: 50px;
  width: 10rem;
  border: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.offWhite};
  background-color: ${({ theme }) => theme.pink};
`;

export const ContinueButton = styled(SmallButton)`
  height: 50px;
  width: 10rem;
  border: none;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.offWhite};
`;

export const StyledModal = styled(Modal)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;
export const ModalConatiner = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const ModalWrapper = styled.section`
  min-width: 300px;
  display: flex;
  padding: 20px;
  border-radius: 10px;

  background-color: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: 1px solid ${({ theme }) => theme.color11};
`;

export const InfoContainer = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CloseModalButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.secondary};
`;
