export default function getPlanName(plan) {
  switch (plan) {
    case "free":
      return "Gratuito";
    case "platinum":
      return "Gestão";
    case "gold":
      return "Ouro";
    case "silver":
      return "Prata";
    default:
      return "Gratuito";
  }
}
