import styled from 'styled-components';
import { BREAKPOINT_XL_PX } from '../../styles/globalStyles';

export const Main = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerSection = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const BoxSection = styled.button`
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : theme.white};
  border-bottom-left-radius: ${({ first }) => (first ? '10px' : '0px')};
  border-bottom-right-radius: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.primary};
  border-left: ${({ first, theme }) => first && `solid 1px ${theme.primary}`};
  border-right: solid 1px ${({ theme }) => theme.primary};
  padding: 10px;
`;

export const TextLabel = styled.p`
  color: ${({ selected, theme }) => (selected ? theme.white : theme.primary)};
`;

export const DateLabel = styled.p`
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 0.7rem;
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  padding: 0.25rem 0.7rem;
  width: fit-content;
`;

export const DefaultContainer = styled.div`
  margin-top: 10px;
`;
export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
`;

export const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;

  & > :nth-child(1),
  & > :nth-child(2) {
    & > :nth-child(1),
    & > :nth-child(2),
    & > :nth-child(3) {
      opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    }
  }

  &:hover {
    transform: scale(1.03);
  }
`;

export const ContainerImage = styled.div`
  height: 100%;
  max-height: 200px;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    max-height: 180px;
  }
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: 25% 25%;
  transition: all 0.3s ease-in-out;
  width: 100%;
`;

export const ContainerContent = styled.div`
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  border-bottom: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  border-left: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  border-right: 1px solid ${({ theme }) => theme.newPrimaryAlt};
  padding: 0 1.5rem 1rem 1.5rem;
`;

export const TitleCard = styled.p`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  overflow-wrap: break-word;
  width: 100%;
`;

export const TitleBlock = styled(TitleCard)`
  margin: 0 0 0.5rem 0;
  text-align: center;
`;

export const DescriptionText = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  text-align: left;
`;

export const ButtonAccess = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50px;
  border: none;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 3rem;
  padding: 10px;
  width: 100%;
`;
