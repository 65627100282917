/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@material-ui/core';
import moment from 'moment/moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useCallback, useEffect, useState } from 'react';
import useAuth from '../../hooks/auth';
import LogoFooter from '../../images/VectorProfile.png';
import LogoHeader from '../../images/logo-navbar.png';
import api from '../../services/api';
import theme from '../../theme';
import convertCurrencyPtBr from '../../utils/convertCurrencyPtBr';
import { DownloadButton, Icon } from './styles';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfGenerator = ({ id }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const generatePdf = (receipt) => {
    const header = {
      table: {
        widths: ['*'],
        body: [
          [
            {
              image: LogoHeader,
              width: 100,
              alignment: 'center',
              margin: [0, 10, 0, 10],
              fillColor: theme.secondary,
            },
          ],
        ],
      },
      layout: 'noBorders',
    };

    const content = [
      // Seção de cabeçalho
      { text: 'RECIBO', style: 'header' },

      // Seção de dados principais
      {
        style: 'mainContent',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'PACIENTE',
                style: 'mainTitle',
              },
              { text: 'PROFISSIONAL', style: 'mainTitle' },
              { text: `RECIBO N ${receipt.id}`, style: 'mainTitle' },
            ],
            [
              {
                text: `${receipt.client.firstName} ${receipt.client.lastName}`,
                style: 'text',
              },
              {
                text: `${receipt.professional.firstName} ${receipt.professional.lastName}`,
                style: 'text',
              },
              {
                text: `Data da Consulta: ${moment(
                  receipt.startDate,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY')}`,
                style: 'text',
              },
            ],
            [
              {
                text: `CPF: ${receipt.client.documentNumber}`,
                style: 'text',
              },
              {
                text: `CPF/CNPJ: ${receipt.professional.documentNumber}`,
                style: 'text',
              },
              {
                text: `Hora da Consulta: ${moment(
                  receipt.startTime,
                  'HH:mm:ss'
                ).format('HH:mm')}`,
                style: 'text',
              },
            ],
            [
              {
                text: ``,
                style: 'text',
              },
              {
                text: `CRP: ${receipt.professional.professionalDocumentNumber}`,
                style: 'text',
              },
              {
                text: `Forma de Pagamento: ${receipt.payment.paymentMethod}`,
                style: 'text',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: 'mainContent',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: '',
                style: 'mainTitle',
              },
              { text: '', style: 'mainTitle' },
              { text: 'ENDEREÇO', style: 'mainTitle' },
            ],
            [
              {
                text: '',
                style: 'text',
              },
              {
                text: '',
                style: 'text',
              },
              {
                text: `Rua: ${receipt.professional.address.street}`,
                style: 'text',
              },
            ],
            [
              {
                text: '',
                style: 'text',
              },
              {
                text: '',
                style: 'text',
              },
              {
                text: `Bairro: ${receipt.professional.address.neighborhood}`,
                style: 'text',
              },
            ],
            [
              {
                text: '',
                style: 'text',
              },
              {
                text: '',
                style: 'text',
              },
              {
                text: `Cep:: ${receipt.professional.address.cep}`,
                style: 'text',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },

      // Seção de detalhes
      {
        style: 'sectionContent',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              { text: 'QTD', style: 'sectionTitle', alignment: 'center' },
              { text: 'DESCRIÇÃO', style: 'sectionTitle', alignment: 'center' },
              { text: 'VALOR', style: 'sectionTitle', alignment: 'center' },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 0;
          },
          vLineWidth: function () {
            return 0;
          },
          hLineColor: function () {
            return theme.secondary;
          },
          vLineColor: function () {
            return theme.secondary;
          },
        },
      },

      // Detalhes dos serviços
      {
        style: 'sectionLastContent',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              { text: '1', style: 'text', alignment: 'center' },
              {
                text: `Consulta com ${receipt.professional.profession}`,
                style: 'text',
                alignment: 'center',
              },
              {
                text: `${convertCurrencyPtBr(receipt.paymentAmount)}`,
                style: 'text',
                alignment: 'center',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: 'sectionContent',
        table: {
          widths: ['*', '*', '*'],
          body: [[{ text: '' }, { text: '' }, { text: '' }]],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 ? 2 : 0;
          },
          vLineWidth: function () {
            return 0;
          },
          hLineColor: function (i) {
            return i === 0 ? theme.secondary : 'transparent';
          },
          vLineColor: function () {
            return 'transparent';
          },
        },
      },
    ];

    const footer = {
      table: {
        widths: ['*'],
        body: [
          [
            {
              image: LogoFooter,
              width: 50,
              alignment: 'center',
              margin: [0, 10, 0, 15],
              fillColor: theme.secondary,
            },
          ],
        ],
      },
    };

    const docDefinition = {
      pageSize: 'A4',
      header: [header],
      content: [content],
      footer: [footer],
      styles: {
        header: {
          fontSize: 40,
          bold: true,
          margin: [20, 50, 20, 10],
          alignment: 'left',
        },
        mainContent: {
          margin: [20, 5, 0, 15],
        },
        mainTitle: {
          fontSize: 16,
          bold: true,
          margin: [0, 5, 0, 5],
          color: theme.secondary,
        },
        sectionContent: {
          margin: [30, 25, 30, 15],
        },
        sectionTitle: {
          fontSize: 20,
          bold: true,
          margin: [0, 5, 0, 5],
          color: theme.secondary,
        },
        sectionLastContent: {
          margin: [30, 5, 30, 5],
        },
        mainText: {
          fontSize: 14,
          margin: [0, 5, 0, 5],
        },
        text: {
          fontSize: 11,
          margin: [0, 0, 0, 0],
        },
      },
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const getReceipt = useCallback(async () => {
    try {
      const { data } = await api.get(`/${user?.type}s/schedules/${id}/invoice`);
      const attributes = data.data[0].attributes;
      return {
        id: data.data[0].id,
        ...attributes,
      };
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [loading, id]);

  useEffect(() => {
    if (!loading) {
      getReceipt().then((receipt) => generatePdf(receipt));
    }
  }, [getReceipt]);

  return (
    <Tooltip title="Baixar Recibo">
      <DownloadButton onClick={getReceipt}>
        <Icon />
      </DownloadButton>
    </Tooltip>
  );
};

export default PdfGenerator;
