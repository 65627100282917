/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { InvoiceItemList } from '../../../components/ListItens';
import api from '../../../services/api';
import { Container, MainContainer } from './styles';

export default function InvoiceScreen() {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchInvoices = async () => {
      try {
        const { data } = await api.get('/professionals/invoices', {
          signal: controller.signal,
        });
        if (data.data) {
          setPaymentHistory(data.data);
        } else {
          setPaymentHistory([]);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar os agendamentos');
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();

    return () => controller.abort();
  }, []);

  return (
    <MainContainer>
      <Container>
        <Flatlist
          type="invoice"
          data={paymentHistory}
          filterObject={(item) => item?.attributes?.client?.fullName}
          placeholder="Pesquise um Pacientes"
          renderItem={(item, index) => (
            <InvoiceItemList key={index} invoiceItem={item} />
          )}
          loading={loading}
        />
      </Container>
    </MainContainer>
  );
}
