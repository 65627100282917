import styled from 'styled-components';
import { BREAKPOINT_MD_PX } from '../../../../styles/globalStyles';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  width: 500px;

  .--title {
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 250px;
  }
`;
