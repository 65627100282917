import React from 'react';
import PDF from '../../../../assets/contrato_profissional_allminds_2024.pdf';
import AllPagesPDFViewer from '../../../../components/AllPagesPDF';
import { TermDocument } from '../styles';

export default function Terms() {
  return (
    <TermDocument>
      <AllPagesPDFViewer pdf={PDF} />
    </TermDocument>
  );
}
