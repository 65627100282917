import { Send } from 'react-feather';
import styled from 'styled-components';
import {
  BREAKPOINT_SM_PX,
  ScrollCardStyle,
  SmallFont,
} from '../../styles/globalStyles';
import { SmallButton } from '../Button';
import { OpenedTextInput } from '../Input';

export const ChatContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
`;

export const ChatHeader = styled.section`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 0.325rem;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 0.325rem;
  width: 100%;
`;

export const ChatContent = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem 0;
  width: 100%;
  height: 100%;
`;

export const ChatBody = styled(ScrollCardStyle)`
  overflow: auto;
  height: 70vh;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.7rem;
  width: 100%;
`;

export const ChatInputContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ChatInput = styled(OpenedTextInput)`
  border: 1px solid ${({ theme }) => theme.darkGray};
  border-radius: 0.325rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
`;

export const SendButton = styled(SmallButton)`
  width: 7rem;
  height: 2.5rem;
  display: flex;
  margin-left: 15px;
  align-items: center;
  border-radius: 1.5rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 4rem;
  }
`;

export const SendIcon = styled(Send)`
  width: 20px;
  width: 20px;
  color: ${({ theme }) => theme.primaryAlt};
`;

export const SMFontDarkGray = styled(SmallFont)`
  color: ${({ theme }) => theme.darkGray};
`;

export const SMFontSecondary = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
`;

export const SMFontWhite = styled(SmallFont)`
  color: ${({ theme }) => theme.white};
`;

export const SMFontBackground = styled(SmallFont)`
  align-items: center;
  font-size: 0.8rem;
  background-color: #eaecf4;
  border-radius: 1rem;
  color: ${({ theme }) => theme.black};
  display: flex;
  height: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.5rem;
`;

export const Message = styled.div`
  align-items: center;
  align-self: ${({ myself }) => (myself ? 'flex-end' : 'flex-start')};
  background-color: ${({ theme, myself }) =>
    myself ? theme.secondary_blue : theme.primary_blue};
  border-radius: ${({ myself }) =>
    myself ? '10px 10px 0 10px' : '10px 10px 10px 0'};
  justify-content: center;
  margin: 5px 0;
  max-width: 200px;
  padding: 10px;
`;

export const MessageContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const MessageFont = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
  color: ${({ theme }) => theme.primaryAlt};
`;
