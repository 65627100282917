import { Modal, Typography } from '@material-ui/core';
import React from 'react';
import ReactPlayer from 'react-player';
import {
  BoxWrapperLesson,
  BoxWrapperScroll,
  CircularPlayer,
  CloseButton,
  CourseWrapperLesson,
  FakeButtonNavigation,
  FakeButtonPause,
  FakeButtonPauseBar,
  FakeButtonSpace,
  FakeButtonTopBar,
  GroupIconAreaLesson,
  GroupPlayerButton,
  IconAreaLesson,
  TitleBar,
  VideoContainer,
} from '../styles';
import VimeoEmbed from './VimeoEmbed';

export default function ModalLesson({ ...props }) {
  const { setModal, lesson } = props;

  return (
    <Modal open>
      <BoxWrapperLesson>
        <IconAreaLesson>
          <GroupIconAreaLesson>
            <FakeButtonTopBar />
            <FakeButtonTopBar />
            <FakeButtonTopBar />
          </GroupIconAreaLesson>
          <CloseButton
            sx={{ fontSize: '2.5rem', mr: 1 }}
            onClick={() => setModal(null)}
          />
        </IconAreaLesson>
        <BoxWrapperScroll>
          <VideoContainer>
            <CourseWrapperLesson>
              <TitleBar>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ color: '#7a95fe', fontWeight: '600' }}
                >
                  {lesson?.title}
                </Typography>
              </TitleBar>
              {lesson.link.includes('vimeo') ? (
                <VimeoEmbed url={lesson.link} />
              ) : (
                <ReactPlayer
                  url={lesson.link}
                  controls
                  width="100%"
                  height="100%"
                />
              )}
              <GroupPlayerButton>
                <FakeButtonPause>
                  <FakeButtonPauseBar />
                  <FakeButtonPauseBar />
                </FakeButtonPause>
                <FakeButtonNavigation>
                  <CircularPlayer />
                </FakeButtonNavigation>
                <FakeButtonSpace />
              </GroupPlayerButton>
            </CourseWrapperLesson>
          </VideoContainer>
        </BoxWrapperScroll>
      </BoxWrapperLesson>
    </Modal>
  );
}
