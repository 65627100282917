/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StaticButton } from '../../../components/Button';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import api, { apiPublic } from '../../../services/api';
import validateCPF from '../../../utils/validateCPF';
import { CustomPackages, CustomPlans } from '../../Clinic/Profile/components';
import Availability, { getSortedWorkdays } from '../../Register/Availability';
import {
  UpdateAdress,
  UpdateBankAccount,
  UpdateBasicData,
  UpdatePassword,
  UpdateProfessionalInfos,
} from './AccountConfig';
import Coupons from './AccountConfig/Coupons';
import { UpdateTypesReceipts } from './PaymentConfig';
import Terms from './Terms';
import {
  ConfigBody,
  ConfigMain,
  ConfigWrapper,
  MenuHeader,
  MenuOption,
  MobileHeader,
  MobileHeaderTitle,
  OptionText,
  RowLineOptions,
} from './styles';

export function MobileHeaderComponent({ ...props }) {
  const { title } = props;
  return (
    <MobileHeader>
      <MobileHeaderTitle>{title}</MobileHeaderTitle>
    </MobileHeader>
  );
}

export function TopMenuBar({ ...props }) {
  const { options, setSelected, selected } = props;
  return (
    <MenuHeader>
      {options?.map((item) => (
        <MenuOption
          active={selected === item}
          key={item}
          onClick={() => setSelected(item)}
          type="button"
        >
          <OptionText active={selected === item}>{item}</OptionText>
        </MenuOption>
      ))}
    </MenuHeader>
  );
}

function ConfigBodyComponent({ ...props }) {
  const {
    disabled,
    form,
    selected,
    setUpdateBank,
    updateBank,
    user,
    validateInfos,
  } = props;

  return (
    <ConfigBody>
      {selected === 'Dados básicos' && (
        <UpdateBasicData
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Endereço comercial' && (
        <UpdateAdress form={form} user={user} validateInfos={validateInfos} />
      )}
      {selected === 'Dados profissionais' && (
        <UpdateProfessionalInfos
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Dados bancários' && (
        <UpdateBankAccount
          form={form}
          updateBank={updateBank}
          setUpdateBank={setUpdateBank}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Formas de Recebimento' && (
        <UpdateTypesReceipts
          form={form}
          user={user}
          validateInfos={validateInfos}
        />
      )}
      {selected === 'Disponibilidade' && (
        <Availability config={true} form={form} />
      )}
      {selected === 'Cupons' && <Coupons />}
      {selected === 'Pacotes Personalizados' && <CustomPackages form={form} />}
      {selected === 'Planos Personalizados' && <CustomPlans form={form} />}
      {selected === 'Segurança' && (
        <UpdatePassword form={form} validateInfos={validateInfos} />
      )}
      {selected === 'Termos de uso' && <Terms />}
      {![
        'Disponibilidade',
        'Cupons',
        'Termos de uso',
        'Pacotes Personalizados',
        'Planos Personalizados',
      ].includes(selected) && (
        <ConfigWrapper style={{ marginTop: '3rem' }}>
          <StaticButton
            height="3rem"
            title="Salvar alterações"
            disabled={disabled}
          />
        </ConfigWrapper>
      )}
    </ConfigBody>
  );
}

export function ConfigScreen() {
  const history = useHistory();
  const {
    professionalUpdateAdress,
    professionalUpdateBasicData,
    professionalUpdateProfessionalInfos,
    setAuthData,
    updatePassword,
    updateProfessional,
    user,
  } = useAuth();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selected, setSelected] = useState('Dados básicos');
  const [updateBank, setUpdateBank] = useState(false);

  const options = useMemo(() => {
    const menuOptions = [
      'Dados básicos',
      'Endereço comercial',
      'Dados profissionais',
      'Dados bancários',
      'Formas de Recebimento',
      'Disponibilidade',
      'Cupons',
      'Pacotes Personalizados',
      'Planos Personalizados',
      'Segurança',
      'Termos de uso',
    ];

    if (user?.clinic?.id) {
      return menuOptions.filter(
        (option) =>
          !['Pacotes Personalizados', 'Planos Personalizados'].includes(option)
      );
    }
    if (user?.subscription?.pagarmePlan !== 'platinum') {
      return menuOptions.filter(
        (option) =>
          ![
            'Pacotes Personalizados',
            'Planos Personalizados',
            'Formas de Recebimento',
          ].includes(option)
      );
    }
    return menuOptions;
  }, [user]);

  useEffect(() => {
    const getCheckClinic = async () => {
      try {
        const { data } = await apiPublic.get(
          `/public/check_clinic_username?username=${user?.clinic?.username}`
        );
        if (data.data) {
          form.setFieldValue('clinicId', data.data.id);
          form.setFieldValue('clinicAcceptPix', data.data.attributes.acceptPix);
          form.setFieldValue(
            'clinicAcceptCredit',
            data.data.attributes.acceptCredit
          );
          form.setFieldValue('clinicPostPaid', data.data.attributes.postPaid);
          form.setFieldValue('clinicPrePaid', data.data.attributes.prePaid);
          form.setFieldValue(
            'clinicPostPaidDay',
            data.data.attributes.postPaidDay
          );
          form.setFieldValue(
            'clinicExternalPayment',
            data.data.attributes.externalPayment
          );
        }
      } catch (error) {
        toast.error('Clinica não encontrada, entre em contato com Allminds', {
          autoClose: false,
        });
      }
    };

    if (user?.clinic?.id) {
      getCheckClinic();
    }
  }, [user]);

  const professorPrice = 50; // Valor mínimo de consulta para professor
  const psychologistPrice = 80; // Valor mínimo de consulta para psicólogo
  const professionalAdvisorPrice = 70; // Valor mínimo de consulta para orientador profissional
  const psychopedagoguePrice = 70; // Valor mínimo de consulta para psicopedagogo
  const durationAppointment = 30; // Duração mínima de consulta em minutos

  const formatedDate = (date) => moment(date).format('DD/MM/YYYY');

  const parsedAppointmentsTimes = (appointmentsTimes) => {
    const parsedAppointments = Object.entries(appointmentsTimes).reduce(
      (acc, [key, value]) => {
        acc[key] = value.map((item) => moment(item).format('HH:mm'));
        return acc;
      },
      {}
    );

    return parsedAppointments;
  };

  const schemaInfoPersonal = Yup.object({
    avatar: Yup.string().required('Foto de perfil é obrigatório!').nullable(),
    firstName: Yup.string().required('Nome é obrigatório!').nullable(),
    lastName: Yup.string().required('Sobrenome é obrigatório!').nullable(),
    birthday: Yup.string()
      .required('Data de nascimento é obrigatório!')
      .nullable(),
    phone: Yup.string().required('Telefone é obrigatório!').nullable(),
    documentNumber: Yup.string()
      .nullable()
      .test({
        name: 'isValid',
        exclusive: false,
        params: {},
        message: 'CPF inválido',
        test(value) {
          return validateCPF(value || '');
        },
      }),
  });

  const schemaBusinessAddress = Yup.object({
    cep: Yup.string().required('CEP é obrigatório!').nullable(),
    street: Yup.string().required('Endereço é obrigatório!').nullable(),
    street_number: Yup.string().required('Número é obrigatório!').nullable(),
    complement: Yup.string().optional().nullable(),
    neighborhood: Yup.string().required('Bairro é obrigatório!').nullable(),
    city: Yup.string().required('Cidade é obrigatório!').nullable(),
    state: Yup.string().required('Estado é obrigatório!').nullable(),
    country: Yup.string().required('Pais é obrigatório!').nullable(),
  });

  const schemaProfessional = Yup.object({
    specialities: Yup.array()
      .min(1, 'Escolha no mínimo uma especialidade')
      .max(8, 'Escolha no máximo oito especialidades')
      .required('Especialidades são obrigatórias')
      .nullable(),
    reasons: Yup.array()
      .min(1, 'Escolha no mínimo um motivo')
      .max(8, 'Escolha no máximo oito motivos')
      .required('Motivos são obrigatórias')
      .nullable(),
    approaches: Yup.array()
      .min(1, 'Escolha no mínimo uma abordagem')
      .max(8, 'Escolha no máximo oito abordagens')
      .required('Abordagens são obrigatórias')
      .nullable(),
    bio: Yup.string()
      .max(200, 'Tamanho máximo de 200 caracteres')
      .required('Biografia é obrigatória!')
      .nullable(),
    ageGroups: Yup.array()
      .min(1, 'Escolha no mínimo uma faixa etária')
      .required('Faixa etária é obrigatório')
      .nullable(),
    appointmentTypes: Yup.array()
      .min(1, 'Escolha no mínimo um tipo de agendamento')
      .required('Tipo de agendamento é obrigatório')
      .nullable(),
    presentationVideo: Yup.string().nullable(),
    sessionPrice: Yup.number('Valor deve ser numérico')
      .nullable()
      .when('job', {
        is: (job) => job === 'Psicólogo(a)',
        then: Yup.number('Valor deve ser numérico').min(
          psychologistPrice,
          'Valor da consulta não pode ser inferior a R$ 80,00'
        ),
      })
      .when('job', {
        is: (job) => job === 'Professor(a)',
        then: Yup.number('Valor deve ser numérico').min(
          professorPrice,
          'Valor da consulta não pode ser inferior a R$ 50,00'
        ),
      })
      .when('job', {
        is: (job) => job === 'Orientador(a) Profissional',
        then: Yup.number('Valor deve ser numérico').min(
          professionalAdvisorPrice,
          'Valor da consulta  não pode ser inferior a R$ 70,00'
        ),
      })
      .when('job', {
        is: (job) => job === 'Psicopedagogo(a)',
        then: Yup.number('Valor deve ser numérico').min(
          psychopedagoguePrice,
          'Valor da consulta  não pode ser inferior a R$ 70,00'
        ),
      }),
    preferentialPayment: Yup.string()
      .required('Tipo de pagamento é obrigatório')
      .nullable(),
    newPreferOwnMeetingLink: Yup.boolean().optional(),
    ownMeetingLink: Yup.string()
      .nullable()
      .when('newPreferOwnMeetingLink', {
        is: true,
        then: Yup.string()
          .nullable()
          .url('Link de reunião deve ser uma URL válida')
          .required('Link de reunião é obrigatório'),
      }),
  });

  const schemaBank = Yup.object({
    bankCode: Yup.string().required('Banco é obrigatório').nullable(),
    bankAg: Yup.string().required('Agência da conta é obrigatório').nullable(),
    agenciaDv: Yup.string().optional().nullable(),
    bankCc: Yup.string().required('Número da conta é obrigatório').nullable(),
    contaDv: Yup.string().required('Dígito da conta é obrigatório').nullable(),
    accountType: Yup.string()
      .required('Tipo da conta é obrigatório')
      .nullable(),
    name: Yup.string().required('Nome é obrigatório').nullable(),
  });

  const schemaTypesReceipts = Yup.object({
    acceptCredit: Yup.boolean().optional(),
    acceptPix: Yup.boolean().optional(),
    postPaid: Yup.boolean().when('planName', {
      is: 'platinum',
      then: Yup.boolean().required(
        'Pós-pago é obrigatório para o plano platinum'
      ),
      otherwise: Yup.boolean().optional(),
    }),
    prePaid: Yup.boolean().when('planName', {
      is: 'platinum',
      then: Yup.boolean().required(
        'Pré-pago é obrigatório para o plano platinum'
      ),
      otherwise: Yup.boolean().optional(),
    }),
    postPaidDay: Yup.number().when(['postPaid', 'planName'], {
      is: (postPaid, planName) => postPaid && planName === 'platinum',
      then: Yup.number()
        .min(1, 'Dia do pagamento deve ser maior que 1')
        .max(31, 'Dia do pagamento deve ser menor que 31')
        .required('Dia do pagamento é obrigatório para o plano platinum'),
      otherwise: Yup.number().optional(),
    }),
    externalPayment: Yup.boolean().when('planName', {
      is: 'platinum',
      then: Yup.boolean().required(
        'Pagamento externo é obrigatório para o plano platinum'
      ),
      otherwise: Yup.boolean().optional(),
    }),
  });

  const schemaSecurity = Yup.object({
    password: Yup.string().required('Senha é obrigatório'),
    newPassword: Yup.string().required('Nova senha é obrigatório'),
    confirmPassword: Yup.string()
      .required('Confirmação de senha é obrigatório')
      .oneOf([Yup.ref('newPassword'), null], 'Senhas não conferem'),
  });

  const schemaAvailability = Yup.object({
    breakTime: Yup.number()
      .min(0, 'Dempo de descanço não pode ser menor que 0')
      .test(
        'is-multiple-of-5',
        'Tempo de descanço deve conter apenas múltiplos de 5 minutos',
        (value) => value % 5 === 0
      )
      .optional(),
    workdays: Yup.array()
      .min(1, 'Selecione pelo menos um dia da semana')
      .required('Requerido'),
    appointmentTypes: Yup.array().required('Requerido'),
    appointmentDuration: Yup.number()
      .min(
        durationAppointment,
        `Duração de atendimento não pode ser menor que ${durationAppointment} minutos`
      )
      .test(
        'is-multiple-of-5',
        ({ value }) =>
          value
            ? `${value} não é uma dureção válida. A duração deve conter apenas múltiplos de 5 minutos.`
            : 'A duração deve conter apenas múltiplos de 5 minutos',
        (value) => value % 5 === 0
      )
      .required('Requerido'),
  });

  const schemaPackages = Yup.object({
    packageId: Yup.string().optional().nullable(),
    editNamePackage: Yup.string().when(['packageId', 'isDeletePackage'], {
      is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editNumberOfAppointments: Yup.string().when(
      ['packageId', 'isDeletePackage'],
      {
        is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
        then: Yup.string()
          .matches(/^\d+$/, 'Deve conter apenas números')
          .required('Campo obrigatório'),
        otherwise: Yup.string().optional(),
      }
    ),
    editPricePackage: Yup.number().when(['packageId', 'isDeletePackage'], {
      is: (packageId, isDeletePackage) => packageId && !isDeletePackage,
      then: Yup.number()
        .typeError('Deve ser um número')
        .required('Campo obrigatório'),
      otherwise: Yup.number().optional(),
    }),
    namePackage: Yup.string().when('packageId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    numberOfAppointments: Yup.string().when('packageId', {
      is: (value) => !value,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
    }),
    pricePackage: Yup.number().when('packageId', {
      is: (value) => !value,
      then: Yup.number()
        .typeError('Deve ser um número')
        .required('Campo obrigatório'),
    }),
  });

  const schemaPlans = Yup.object({
    planId: Yup.string().optional().nullable(),
    editNamePlan: Yup.string().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editAppointmentsPerWeek: Yup.string().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
      otherwise: Yup.string().optional(),
    }),
    editPricePlan: Yup.number().when(['planId', 'isDeletePlan'], {
      is: (planId, isDeletePlan) => planId && !isDeletePlan,
      then: Yup.number()
        .typeError('Deve ser um número')
        .required('Campo obrigatório'),
      otherwise: Yup.number().optional(),
    }),
    namePlan: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    frequency: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string().required('Campo obrigatório'),
    }),
    appointmentsPerWeek: Yup.string().when('planId', {
      is: (value) => !value,
      then: Yup.string()
        .matches(/^\d+$/, 'Deve conter apenas números')
        .required('Campo obrigatório'),
    }),
    pricePlan: Yup.number().when('planId', {
      is: (value) => !value,
      then: Yup.number()
        .typeError('Deve ser um número')
        .required('Campo obrigatório'),
    }),
  });

  const schemas = () => {
    switch (selected) {
      case 'Dados básicos':
        return schemaInfoPersonal;
      case 'Endereço comercial':
        return schemaBusinessAddress;
      case 'Dados profissionais':
        return schemaProfessional;
      case 'Dados bancários':
        return schemaBank;
      case 'Formas de Recebimento':
        return schemaTypesReceipts;
      case 'Disponibilidade':
        return schemaAvailability;
      case 'Pacotes Personalizados':
        return schemaPackages;
      case 'Plano Personalizados':
        return schemaPlans;
      case 'Segurança':
        return schemaSecurity;
      default:
        return schemaInfoPersonal;
    }
  };

  const updateMethod = (values, id, method) => {
    switch (method) {
      case 'Dados básicos':
        return professionalUpdateBasicData(values, id);
      case 'Endereço comercial':
        return professionalUpdateAdress(values, id);
      case 'Dados profissionais':
        return professionalUpdateProfessionalInfos(values, id);
      default:
        return updateProfessional(values, id);
    }
  };

  const submitForm = async (values) => {
    values.avatar === user?.avatar && delete values.avatar;
    try {
      setSubmitLoading(true);
      const success = await updateMethod(values, user.id, selected);

      if (success) {
        toast.success('Dados atualizados com sucesso.');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error(
        'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
      );
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: selected },
      });
    }
  };

  const submitFormBank = async (values) => {
    try {
      setSubmitLoading(true);
      const documentNumber = user?.documentNumber
        .split('.')
        .join('')
        .replace('-', '');
      const request = {
        data: {
          type: 'payment_accounts',
          attributes: {
            documentNumber,
            name: values?.name,
            bankAg: values?.bankAg,
            accountType: values.accountType,
            bankCc: values?.bankCc,
            bankCode: values?.bankCode,
            agenciaDv: values?.agenciaDv,
            contaDv: values?.contaDv,
          },
        },
      };
      if (!values.agenciaDv) delete request.data.attributes.agenciaDv;
      let data = {};
      if (user?.paymentAccount?.id) {
        const { data: response } = await api.put(
          `/professionals/payment_accounts/${user?.paymentAccount?.id}`,
          request
        );
        data = response;
      } else {
        const { data: response } = await api.post(
          '/professionals/payment_accounts',
          request
        );
        data = response;
      }
      if (data.data) {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            paymentAccount: data.data.attributes,
          })
        );
        setAuthData({
          ...user,
          paymentAccount: data.data.attributes,
        });
        toast.success('Dados bancários atualizados com sucesso.');
      } else {
        throw new Error(JSON.stringify(data.error));
      }
    } catch (error) {
      toast.error('Falha ao atualizar conta bancária.');
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: selected },
      });
    }
  };

  const submitFormSecurity = async (values) => {
    setSubmitLoading(true);
    try {
      const data = await updatePassword(values, user?.type, user?.id);
      if (data.error) {
        return toast.error('Senha inválida');
      }
      if (data) {
        return toast.success('Senha alterada com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao alterar senha');
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: selected },
      });
    }
  };

  const submitFormAvailability = async (values) => {
    try {
      setSubmitLoading(true);
      const success = await updateProfessional(values, user?.id);
      if (success) {
        history.push('/professional/dashboard');
        toast.success('Disponibilidade Atualizada!');
      } else {
        toast.error(
          'Erro no servidor. Reveja se seus dados estão corretos e tente se cadastrar novamente em alguns minutos.'
        );
      }
    } catch (error) {
      toast.error('Erro ao atualizar disponibilidade');
    } finally {
      setSubmitLoading(false);
      history.push({
        pathname: '/professional/config',
        state: { selected: 'Disponibilidade' },
      });
    }
  };

  const handleCreateCustomPackages = async (values) => {
    try {
      setSubmitLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.namePackage,
            price: values?.pricePackage,
            numberOfAppointments: Number(values?.numberOfAppointments),
          },
        },
      };
      const { data } = await api.post(
        '/professionals/appointment_packages',
        request
      );
      if (data) {
        toast.success('Plano personalizado criado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao criar plano personalizado');
    } finally {
      form.setFieldValue('namePackage', '');
      form.setFieldValue('numberOfAppointments', '');
      form.setFieldValue('pricePackage', '');
      setSubmitLoading(false);
    }
  };

  const handleUpdateCustomPackages = async (values) => {
    try {
      setSubmitLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.editNamePackage,
            price: values?.editPricePackage,
            numberOfAppointments: Number(values?.editNumberOfAppointments),
          },
        },
      };
      const { data } = await api.patch(
        `/professionals/appointment_packages/${values?.packageId}`,
        request
      );
      if (data) {
        toast.success('Plano personalizado atualizado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao atualizar plano personalizado');
    } finally {
      form.setFieldValue('packageId', null);
      form.setFieldValue('editNamePackage', '');
      form.setFieldValue('editNumberOfAppointments', '');
      form.setFieldValue('editPricePackage', '');
      setSubmitLoading(false);
    }
  };

  const handleDeleteCustomPackages = async (id) => {
    try {
      setSubmitLoading(true);
      const { data } = await api.delete(
        `/professionals/appointment_packages/${id}`
      );
      if (data) {
        toast.success('Plano personalizado deletado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao deletar plano personalizado');
    } finally {
      form.setFieldValue('packageId', null);
      form.setFieldValue('isDeletePackage', false);
      setSubmitLoading(false);
    }
  };

  const submitFormPackages = async (values) => {
    if (values?.isDeletePackage && values?.packageId) {
      handleDeleteCustomPackages(values?.packageId);
    } else if (values?.packageId) {
      handleUpdateCustomPackages(values);
    } else {
      handleCreateCustomPackages(values);
    }
  };

  const handleCreateCustomPlans = async (values) => {
    try {
      setSubmitLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.namePlan,
            price: values?.pricePlan,
            frequency: values?.frequency,
            appointmentsPerWeek: 1,
            userType: values?.userType,
            userId: values?.userId,
          },
        },
      };

      const { data } = await api.post(
        '/professionals/appointment_plans',
        request
      );
      if (data) {
        toast.success('Plano criado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao criar plano');
    } finally {
      form.setFieldValue('namePlan', '');
      form.setFieldValue('pricePlan', '');
      form.setFieldValue('frequency', '');
      form.setFieldValue('userType', '');
      form.setFieldValue('userId', '');
      setSubmitLoading(false);
    }
  };

  const handleUpdateCustomPlans = async (values) => {
    try {
      setSubmitLoading(true);
      const request = {
        data: {
          attributes: {
            name: values?.editNamePlan,
            price: values?.editPricePlan,
            frequency: values?.editFrequency,
            appointmentsPerWeek: 1,
            userType: values?.editUserType,
            userId: values?.editUserId,
          },
        },
      };
      const { data } = await api.patch(
        `/professionals/appointment_plans/${values?.planId}`,
        request
      );
      if (data) {
        toast.success('Plano atualizado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao atualizar plano');
    } finally {
      form.setFieldValue('planId', null);
      form.setFieldValue('editNamePlan', '');
      form.setFieldValue('editPricePlan', '');
      form.setFieldValue('editFrequency', '');
      form.setFieldValue('editUserType', '');
      form.setFieldValue('editUserId', '');
      setSubmitLoading(false);
    }
  };

  const handleDeleteCustomPlans = async (id) => {
    try {
      setSubmitLoading(true);
      const { data } = await api.delete(
        `/professionals/appointment_plans/${id}`
      );
      if (data) {
        toast.success('Plano deletado com sucesso');
      }
    } catch (error) {
      toast.error('Erro ao deletar plano');
    } finally {
      form.setFieldValue('planId', null);
      form.setFieldValue('isDeletePlan', false);
      setSubmitLoading(false);
    }
  };

  const submitFormPlans = async (values) => {
    if (values?.isDeletePlan && values?.planId) {
      handleDeleteCustomPlans(values?.planId);
    } else if (values?.planId) {
      handleUpdateCustomPlans(values);
    } else {
      handleCreateCustomPlans(values);
    }
  };

  const form = useFormik({
    initialValues: {
      // Dados Pessoais
      avatar: user?.avatar || '',
      birthday: formatedDate(user?.birthday) || '',
      documentNumber: user?.documentNumber || '',
      firstName: user?.firstName || '',
      hasSocialName: !user?.socialName === '' ? true : false,
      lastName: user?.lastName || '',
      phone: user?.phone || '',
      socialName: user?.socialName || '',
      termId: user?.term?.id || '',

      // Dados de Endereço
      cep: user?.address.cep || '',
      city: user?.address.city || '',
      complement: user?.address.complement || 'N/A',
      country: user?.address.country || '',
      neighborhood: user?.address.neighborhood || '',
      state: user?.address.state || '',
      street: user?.address.street || '',
      street_number: user?.address.street_number || '',

      // Dados Profissionais
      academicBackground: user?.academicBackground || [''],
      ageGroups: user?.ageGroups || [],
      approaches: user?.approaches || [],
      bio: user?.bio || '',
      formations: user?.academicBackground.map((item) => item).join('\n') || '',
      newPreferOwnMeetingLink: user?.ownMeetingLink ? true : false,
      presentationVideo: user?.presentationVideo || '',
      professionalDocumentNumber: user?.professionalDocumentNumber || '',
      reasons: user?.reasons || [],
      sessionPrice: user?.sessionPrice || 0,
      specialities: user?.specialities || [],
      yearsOfExperience: user?.yearsOfExperience || 0,

      // Dados Bancários e Pagamentos
      accountType: user?.paymentAccount?.accountType || '',
      agenciaDv: user?.paymentAccount?.agenciaDv || '',
      bankAg: user?.paymentAccount?.bankAg || '',
      bankCc: user?.paymentAccount?.bankCc || '',
      bankCode: user?.paymentAccount?.bankCode || '',
      contaDv: user?.paymentAccount?.contaDv || '',
      name: user?.paymentAccount?.name || '',

      // Formas de Recebimento
      planName: user?.subscription?.pagarmePlan,
      preferentialPayment: user?.preferentialPayment,
      acceptCredit: user?.acceptCredit || false,
      acceptPix: user?.acceptPix || false,
      postPaid: user?.postPaid || false,
      postPaidDay: user?.postPaidDay || 10,
      prePaid: user?.prePaid || true,
      externalPayment: user?.externalPayment || false,

      // Dados de disponibilidade
      appointmentDuration: user?.appointmentDuration || durationAppointment,
      appointmentTimeBlocks: parsedAppointmentsTimes(
        user?.availabilityRule?.appointmentTimeBlocks
      ),
      appointmentTypes: user?.availabilityRule.appointmentTypes || [],
      blockedTimes: parsedAppointmentsTimes(
        user?.availabilityRule?.blockedTimes
      ),
      breakTime: user?.availabilityRule?.breakTime || 0,
      configTimes: {},
      workdays: user?.availabilityRule?.workdays || [],

      // Pacotes Personalizados
      editNamePackage: '',
      editNumberOfAppointments: '',
      editPricePackage: '',
      isDeletePackage: false,
      namePackage: '',
      numberOfAppointments: '',
      packageId: null,
      pricePackage: '',

      // Planos Personalizados
      appointmentsPerWeek: 1,
      frequency: '',
      isDeletePlan: false,
      namePlan: '',
      pricePlan: '',
      userId: '',
      userType: '',
    },
    validationSchema: schemas(),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (selected === 'Pacotes Personalizados') submitFormPackages(values);
      else if (selected === 'Planos Personalizados') submitFormPlans(values);
      else if (selected === 'Dados bancários') submitFormBank(values);
      else if (selected === 'Segurança') submitFormSecurity(values);
      else if (selected === 'Disponibilidade') submitFormAvailability(values);
      else submitForm(values);
    },
  });

  useEffect(() => {
    if (form?.values?.clinicAcceptPix && !form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'pix');
    }
    if (!form?.values?.clinicAcceptPix && form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'credit_card');
    }
    if (form?.values?.clinicAcceptPix && form?.values?.clinicAcceptCredit) {
      return form?.setFieldValue('preferentialPayment', 'all_payment');
    }
  }, [form?.values?.clinicAcceptPix, form?.values?.clinicAcceptCredit]);

  const validateInfos = (values) => {
    setDisabled(values);
  };

  useEffect(() => {
    const controller = new AbortController();
    const getUserAvailabilityRule = async () => {
      try {
        setSubmitLoading(true);
        const { data } = await api.get('/professionals/availabilities/rule', {
          signal: controller.signal,
        });
        form.setFieldValue(
          'blockedTimes',
          parsedAppointmentsTimes(data?.data?.attributes?.blockedTimes)
        );
        form.setFieldValue(
          'appointmentDuration',
          data?.data?.attributes?.appointmentDuration
        );
        form.setFieldValue('breakTime', data?.data?.attributes?.breakTime);
        form.setFieldValue(
          'workdays',
          getSortedWorkdays(data?.data?.attributes?.workdays)
        );
        form.setFieldValue(
          'appointmentTypes',
          data?.data?.attributes?.appointmentTypes
        );
        form.setFieldValue(
          'appointmentTimeBlocks',
          parsedAppointmentsTimes(data?.data?.attributes?.appointmentTimeBlocks)
        );
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Erro ao buscar as suas regras de disponibilidade');
      } finally {
        setSubmitLoading(false);
      }
    };
    getUserAvailabilityRule();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const verifyHistoryState = () => {
      const selectedCash = history.location?.state?.selected;
      if (selectedCash) {
        setSelected(selectedCash);
      }
    };
    verifyHistoryState();
  }, []);

  return (
    <FormikProvider value={form}>
      <ConfigMain onSubmit={form.handleSubmit}>
        <TopMenuBar
          options={options}
          setSelected={setSelected}
          selected={selected}
        />
        <RowLineOptions />
        {submitLoading ? (
          <LoaderSpinner />
        ) : (
          <ConfigBodyComponent
            disabled={disabled}
            form={form}
            selected={selected}
            setUpdateBank={setUpdateBank}
            updateBank={updateBank}
            user={user}
            validateInfos={validateInfos}
          />
        )}
      </ConfigMain>
    </FormikProvider>
  );
}
