import styled from 'styled-components';
import { ReactComponent as EmptyState } from '../../../images/professional-list-empty.svg';
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
} from '../../../styles/globalStyles';
import { whiter } from '../../../styles/keyframes';

export const PublicListMain = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const PublicSearchContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ListProfessionalContainer = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 100%;
`;

export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 500px));
  justify-content: space-between;
  justify-items: stretch;
  column-gap: 2rem;
  row-gap: 5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  right: 1rem;
  top: auto;
  bottom: 0.7rem;
  z-index: 1000;
`;

export const FilterContainer = styled.div`
  align-items: center;
  animation: ${whiter} 0.2s ease-in;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'professionFilter sessionTypeFilter priceFilter'
    'specialitiesFilter addressFilter nameFilter';
  column-gap: 5rem;
  justify-items: center;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  row-gap: 1rem;
  width: 100%;

  .--modal-wrapper {
    align-items: flex-start;
  }

  .--modal-label,
  .--filter-input,
  .--filter-select {
    width: 100%;
  }

  .modal {
    border-radius: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    column-gap: 3rem;
    row-gap: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      'professionFilter sessionTypeFilter'
      'specialitiesFilter priceFilter'
      'addressFilter nameFilter';
    row-gap: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
    margin-top: 2rem;
    grid-template-areas:
      'professionFilter'
      'sessionTypeFilter'
      'specialitiesFilter'
      'priceFilter'
      'addressFilter'
      'nameFilter';
  }
`;

export const EmptyImg = styled(EmptyState)`
  width: 100%;
`;

export const EmptyContainer = styled.section`
  width: 100%;
  display: flex;
  padding: 20px 0 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const EmptyLabel = styled.h3`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;
