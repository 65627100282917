/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@material-ui/core';
import { FormControlLabel, Radio } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Calendar, CalendarTime } from '../../../components/EventElements';
import { ProfileReviewItemList } from '../../../components/ListItens';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api, { apiPublic } from '../../../services/api';
import { BigFont, BoxScroll } from '../../../styles/globalStyles';
import theme from '../../../theme';
import { ConsultingPackages, ConsultingPlans, Schedule } from './Schedule';
import {
  BoxWrapper,
  BoxWrapperSchedule,
  ButtonContainer,
  CloseIcon,
  EmptyRecomendations,
  EmptyRecomendationsText,
  Hr,
  IconArea,
  IconTouchableArea,
  Recomendations,
  RecomendationsNumber,
  RecomendationsTitle,
  RowContainer,
  RowIten,
  ScheduleButton,
  Square,
  ThumbsUpIconPrimary,
  WarningIcon,
} from './styles';

export function CalendarComponent({ ...props }) {
  const {
    id,
    isProfessional,
    onContinue,
    professional,
    publicP,
    setValues,
    setFieldValue,
    user,
    values,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const { user: userLogged } = useAuth();
  const { setScheduleInfo } = useEvent();
  const [workableDays, setWorkableDays] = useState([]);
  const [date, setDate] = useState(
    moment(values?.startDate).isValid()
      ? new Date(values?.startDate)
      : new Date()
  );
  const [loading, setLoading] = useState(true);
  const [timeBlocks, setTimeBlocks] = useState([]);
  const [fullTimes, setFullTimes] = useState(false);
  const [modalTime, setModalTime] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  const validateInfo = [
    userLogged?.firstName,
    userLogged?.lastName,
    userLogged?.birthday,
    userLogged?.phone,
    userLogged?.documentNumber,
  ]?.every((info) => info !== null && info !== undefined && info !== '');

  const validateAddress = [
    userLogged?.address?.id,
    userLogged?.address?.street,
    userLogged?.address?.street_number,
    userLogged?.address?.cep,
    userLogged?.address?.state,
    userLogged?.address?.country,
    userLogged?.address?.city,
    userLogged?.address?.neighborhood,
  ]?.every((info) => info !== null && info !== undefined && info !== '');

  const validateGuardian = userLogged?.needLegalGuardian
    ? [
        userLogged?.legalGuardianFullName,
        userLogged?.legalGuardianEmail,
        userLogged?.legalGuardianPhone,
        userLogged?.legalGuardianDocumentNumber,
      ]?.every((info) => info !== null && info !== undefined && info !== '')
    : true;

  const handleSchedule = () => {
    setValues({
      ...values,
      professional,
      startDate: moment(date).format('YYYY-MM-DD'),
      startTime: values.startTime,
      appointmentType: values?.appointmentType,
    });
    setModalTime(false);
    setModalSchedule(true);
  };

  const getAvaiableTimeBlocks = (data) => {
    const ADD_HOURS = 21600; //Add 6 hours in seconds
    const avaiable = data?.attributes?.appointmentTimeBlocks?.map((tb) => ({
      time: moment(tb, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
      type: 'available',
    }));

    const checkHour = (hour1, hour2) => {
      const parseHour1 = moment(hour1, 'YYYY-MM-DD HH:mm');
      const parseHour2 = moment(hour2, 'YYYY-MM-DD HH:mm');
      return parseHour1.isBefore(parseHour2);
    };

    const todayDate = moment().format('YYYY-MM-DD');
    const parseDate = moment(date).format('YYYY-MM-DD');

    if (todayDate === parseDate) {
      const parseReatroactiveSchedules = avaiable?.map((tm) => {
        const nowAddSixHours = moment()
          .add(ADD_HOURS, 'seconds')
          .format('YYYY-MM-DD HH:mm');
        const time = moment(tm.time, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm'
        );
        if (tm.type === 'available') {
          if (checkHour(time, nowAddSixHours)) {
            return { ...tm, type: 'unavailable' };
          } else {
            return tm;
          }
        } else {
          return tm;
        }
      });

      return parseReatroactiveSchedules
        .sort(
          (a, b) =>
            Number(a.time.split(':').join('')) -
            Number(b.time.split(':').join(''))
        )
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    } else {
      return avaiable
        .sort(
          (a, b) =>
            Number(a.time.split(':').join('')) -
            Number(b.time.split(':').join(''))
        )
        ?.map((tm) => ({
          ...tm,
          time: moment(tm.time, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }));
    }
  };

  const fetchTimeBlocks = async () => {
    const currentDate = moment(date).format('YYYY-MM-DD');
    const getURL = (type) => {
      if (type === 'professional') {
        return `/professionals/availabilities?date=${currentDate}`;
      }
      if (type === 'client') {
        return `/clients/professionals/${id}/availabilities?date=${currentDate}`;
      }
      return `/clinics/professionals/${id}/availabilities?date=${currentDate}`;
    };

    const URL = getURL(user?.type);

    try {
      setLoading(true);
      const { data } = await api.get(URL);
      const availableTimeBlocks = getAvaiableTimeBlocks(data.data);
      if (availableTimeBlocks?.length !== 0) {
        setTimeBlocks(availableTimeBlocks);
        setFullTimes(
          availableTimeBlocks?.every((tb) => tb.type === 'unavailable')
        );
      } else setFullTimes(true);
    } catch (error) {
      toast.error('Erro ao buscar horários disponíveis');
    } finally {
      setLoading(false);
    }
  };

  const fetchTimeBlocksPublic = async () => {
    const currentDate = moment(date).format('YYYY-MM-DD');
    try {
      setLoading(true);
      const { data } = await apiPublic.get(
        `/public/professional_availabilities?date=${currentDate}&professional=${id}`
      );
      const availableTimeBlocks = getAvaiableTimeBlocks(data.data);
      setTimeBlocks(availableTimeBlocks);
    } catch (error) {
      toast.error('Erro ao buscar horários disponíveis');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getConsultingPackage = async () => {
      try {
        const { data } = await api.get('clients/client_credits');

        if (data.data) {
          const credits = data.data
            .filter(
              (data) =>
                data.attributes.professionalId === Number(professional.id)
            )
            .reduce(
              (acc, credit) => acc + credit.attributes.remainingCredits,
              0
            );
          setFieldValue('creditClients', credits);
        }
      } catch (error) {
        toast.error('Erro ao buscar créditos');
      }
    };

    if (location.pathname.includes('publica')) return;

    getConsultingPackage();
  }, [values.consultingPackageAdd]);

  useEffect(() => {
    const updateCalendar = async () => {
      const startOfMonth = moment(date).startOf('month');
      const endOfMonth = moment(date).endOf('month');

      const getDiasMes = async () => {
        const daysWeek = [];
        while (startOfMonth.isBefore(endOfMonth)) {
          daysWeek.push(startOfMonth.format('D'));
          startOfMonth.add(1, 'days');
        }

        const days = daysWeek?.filter(
          (day) =>
            professional?.availabilityRule?.workdays?.filter(
              (item) =>
                new Date(date.getFullYear(), date.getMonth(), day)
                  .getDay()
                  .toString() === item
            )?.length === 0
        );

        const working = days?.map(
          (item) => new Date(date.getFullYear(), date.getMonth(), item)
        );

        return working;
      };

      const result = await getDiasMes();
      setWorkableDays(result);
    };

    updateCalendar();
  }, [date]);

  useEffect(() => {
    if (userLogged?.id) return fetchTimeBlocks();
    return fetchTimeBlocksPublic();
  }, [date]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loading ? (
        <LoaderSpinner />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Calendar value={date} dates={workableDays} setDate={setDate} />
          {!isProfessional && (
            <ButtonContainer>
              <ScheduleButton
                disabled={fullTimes}
                onClick={() => {
                  values?.appointmentType === null
                    ? toast.info(
                        'Não há horários disponíveis para agendamento.'
                      )
                    : setModalTime(true);
                }}
              >
                {fullTimes
                  ? 'O dia selecionado não possui horários disponíveis'
                  : 'Selecionar dia da consulta'}
              </ScheduleButton>
            </ButtonContainer>
          )}
        </div>
      )}

      {modalTime && (
        <Modal open>
          <BoxWrapper>
            <IconArea>
              <CloseIcon
                onClick={() => {
                  setModalTime(false);
                  setScheduleInfo({});
                }}
              />
            </IconArea>
            <BoxScroll>
              <CalendarTime
                date={date}
                title={moment(date, 'pt-br').format('DD [de] MMMM [de] YYYY')}
                time={timeBlocks}
                values={values}
                setValues={setValues}
                setDate={setDate}
              />
              <RowContainer style={{ justifyContent: 'flex-start' }}>
                <IconTouchableArea>
                  <Square background="#fff" />
                  <p style={{ marginLeft: '0.25rem' }}>Horários livres</p>
                </IconTouchableArea>
                <IconTouchableArea>
                  <Square background="#DDE4E8" />
                  <p style={{ marginLeft: '0.25rem' }}>
                    Horários indisponíveis
                  </p>
                </IconTouchableArea>
              </RowContainer>
              <Hr style={{ width: '100%' }} />
              <RowContainer>
                {professional?.availabilityRule?.appointmentTypes?.includes(
                  'in_person'
                ) && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: '#BCC1E0' }}
                        checked={values?.appointmentType === 'in_person'}
                        name="radio-buttons"
                        value="in_person"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Presencial"
                  />
                )}
                {professional?.availabilityRule?.appointmentTypes?.includes(
                  'online'
                ) && (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{ color: '#BCC1E0' }}
                        checked={values?.appointmentType === 'online'}
                        name="radio-buttons"
                        value="online"
                        onChange={({ target }) => {
                          setFieldValue('appointmentType', target.value);
                        }}
                      />
                    }
                    label="Online"
                  />
                )}
              </RowContainer>
            </BoxScroll>
            <Hr style={{ marginTop: '1rem' }} />
            <ButtonContainer style={{ width: '80%', marginTop: '0.5rem' }}>
              <ScheduleButton
                onClick={handleSchedule}
                disabled={!values?.startTime || !values?.appointmentType}
              >
                Agendar Consulta
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapper>
        </Modal>
      )}

      {modalSchedule && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setModalSchedule(false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <Schedule
                professional={professional}
                values={values}
                setValues={setValues}
                setFieldValue={setFieldValue}
              />
              {!values?.monthlySubscription && (
                <Fragment>
                  <Hr style={{ width: '100%' }} />
                  <ButtonContainer
                    style={{
                      width: '100%',
                      marginTop: '0.5rem',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <ScheduleButton
                      style={{ width: '25%' }}
                      onClick={() => {
                        setModalSchedule(false);
                        setModalTime(true);
                      }}
                    >
                      Voltar
                    </ScheduleButton>

                    <ScheduleButton
                      style={{ width: '50%' }}
                      onClick={() => {
                        if (publicP) {
                          setModalSchedule(false);
                          setModalAlert(true);
                        } else if (
                          validateInfo &&
                          validateAddress &&
                          validateGuardian
                        ) {
                          onContinue();
                        } else {
                          setModalSchedule(false);
                          setModalAlert(true);
                        }
                      }}
                      disabled={!values?.startTime || !values?.appointmentType}
                    >
                      Confirmar Consulta
                    </ScheduleButton>
                  </ButtonContainer>
                </Fragment>
              )}
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values.consultingPackageAdd && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setFieldValue('consultingPackageAdd', false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ConsultingPackages
                professional={professional}
                setFieldValue={setFieldValue}
              />
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values.monthlySubscriptionAdd && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon
                onClick={() => {
                  setFieldValue('monthlySubscriptionAdd', false);
                }}
              />
            </IconArea>
            <BoxScroll>
              <ConsultingPlans
                professional={professional}
                setFieldValue={setFieldValue}
                values={values}
              />
            </BoxScroll>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {values?.confirmBuyCredit && (
        <Modal open>
          <BoxWrapperSchedule>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2rem',
              }}
            >
              <WarningIcon style={{ marginRight: '0.5rem' }} />
              <BigFont
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Aviso!
              </BigFont>
            </div>

            <BigFont style={{ fontWeight: '700', margin: '1.5rem 5rem' }}>
              Você já possui créditos disponíveis. Quer continuar para a compra
              de novos créditos?
            </BigFont>

            <ButtonContainer
              style={{
                width: '70%',
                marginTop: '0.5rem',
                marginBottom: '2rem',
                justifyContent: 'space-between',
              }}
            >
              <ScheduleButton
                type="button"
                height="3rem"
                bgColor={theme.pink}
                onClick={() => setFieldValue('confirmBuyCredit', false)}
                style={{ width: '15rem' }}
              >
                Cancelar
              </ScheduleButton>
              <ScheduleButton
                type="button"
                height="3rem"
                onClick={() => {
                  setFieldValue('confirmBuyCredit', false);
                  setFieldValue('consultingPackageAdd', true);
                }}
                style={{ width: '15rem' }}
              >
                Continuar
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}

      {modalAlert && (
        <Modal open>
          <BoxWrapperSchedule>
            <IconArea style={{ padding: '1rem 1rem 0 0' }}>
              <CloseIcon onClick={() => setModalAlert(false)} />
            </IconArea>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <WarningIcon style={{ marginRight: '0.5rem' }} />
              <BigFont
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Aviso!
              </BigFont>
            </div>
            {!userLogged?.id ? (
              <BigFont style={{ fontWeight: '700', margin: '1.5rem 5rem' }}>
                Para agendar uma consulta, é necessário se cadastrar.
              </BigFont>
            ) : (
              <BigFont style={{ fontWeight: '700', margin: '1.5rem 5rem' }}>
                {`Para agendar uma consulta, é necessário ${
                  userLogged?.needLegalGuardian && !validateGuardian
                    ? 'cadastrar um responsável.'
                    : 'completar seu cadastro.'
                }`}
              </BigFont>
            )}
            <Hr />
            <ButtonContainer
              style={{
                width: '100%',
                marginTop: '0.5rem',
                marginBottom: '2rem',
                justifyContent: 'center',
              }}
            >
              <ScheduleButton
                style={{ width: '70%' }}
                onClick={() => {
                  setScheduleInfo({
                    ...values,
                    appointmentType: values?.appointmentType,
                    duration:
                      professional?.availabilityRule?.appointmentDuration,
                    startDate: date,
                    startTime: values?.startTime,
                    publicP,
                    paymentMethod: 'credit_card',
                  });
                  if (publicP && !userLogged.id)
                    return history.push('/client/sign-up');
                  return history.push('/client/config');
                }}
              >
                {publicP ? 'Continuar' : 'Completar cadastro'}
              </ScheduleButton>
            </ButtonContainer>
          </BoxWrapperSchedule>
        </Modal>
      )}
    </div>
  );
}

export default function ProfessionalReviewContainer({ ...props }) {
  const { professional } = props;
  return (
    <Recomendations>
      <RowIten>
        <RecomendationsTitle>Recomendações</RecomendationsTitle>
        <RecomendationsNumber>
          ({professional?.reviews?.length})
        </RecomendationsNumber>
        <ThumbsUpIconPrimary />
        <EmptyRecomendationsText>
          {professional?.likes || 0}
        </EmptyRecomendationsText>
      </RowIten>
      {professional?.reviews?.length > 0 ? (
        <EmptyRecomendations>
          {professional?.reviews?.map((review, index) => (
            <ProfileReviewItemList key={index} data={review} />
          ))}
        </EmptyRecomendations>
      ) : (
        <EmptyRecomendations>
          <EmptyRecomendationsText>
            Esse profissional ainda não tem recomendações...
          </EmptyRecomendationsText>
        </EmptyRecomendations>
      )}
    </Recomendations>
  );
}
