/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { DefaultVideoCard } from '../../../components/ContentsCards';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useAxiosGet from '../../../hooks/useAxiosGet';
import { BackIcon } from '../Courses/styles';
import EmptyContainer from '../EmptyContainer';
import { DefaultContainer, GridLayout } from '../styles';
import { ModalContainer, VideoContainer } from './styles';

export default function ContentsVideos({ search }) {
  const { user } = useAuth();
  const [videos, setVideos] = useState([]);
  const [selelectedVideo, setSelectedVideo] = useState({
    link: null,
    open: false,
  });
  const getVideosApi = useAxiosGet();

  const filteredVideos = videos?.filter((item) => {
    if (!search) return item;
    return item.attributes.title.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    const getVideos = () => {
      const { data, fetchData, errors } = getVideosApi;
      fetchData(`/${user?.type}s/posts/videos`);

      if (errors) toast.error('Erro ao carregar artigos');

      if (data) setVideos(data);
    };
    getVideos();
  }, [getVideosApi.loading]);

  return (
    <DefaultContainer>
      {getVideosApi.loading ? (
        <LoaderSpinner logo />
      ) : filteredVideos?.length ? (
        <GridLayout>
          {filteredVideos?.map((video) => {
            return (
              <DefaultVideoCard
                key={video.id}
                item={video.attributes}
                label="Abrir Vídeo"
                onClick={() =>
                  setSelectedVideo({
                    link: video.attributes.videoLink,
                    open: true,
                  })
                }
              />
            );
          })}
        </GridLayout>
      ) : (
        <EmptyContainer title="Você não tem videos diponíveis no momento." />
      )}

      <Modal
        open={selelectedVideo.open}
        onClose={() =>
          setSelectedVideo({
            link: null,
            open: false,
          })
        }
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <VideoContainer>
            <BackIcon
              onClick={() => setSelectedVideo({ open: false })}
              style={{ backgroundColor: 'white', alignSelf: 'flex-end' }}
            />
            <ReactPlayer
              url={selelectedVideo.link}
              controls
              width="100%"
              height="100%"
            />
          </VideoContainer>
        </ModalContainer>
      </Modal>
    </DefaultContainer>
  );
}
