/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/auth';
import useEvent from '../../hooks/event';
import useAxiosGet from '../../hooks/useAxiosGet';
import BellImg from '../../images/icon_bell.png';
import {
  EmptyContainer,
  EmptyText,
  EmptyTitle,
} from '../../screens/Client/EletronicDiary/styles';
import api from '../../services/api';
import { BREAKPOINT_MD, BoxScroll, EmptyFont } from '../../styles/globalStyles';
import useWindowSize from '../../utils/WindowSize';
import { NotificationsItemList } from '../ListItens';
import LoaderSpinner from '../LoaderSpinner';
import {
  ArrowUpCircleIcon,
  BFontBold,
  EmptyImage,
  IconAreaFixed,
  ListContainer,
  Main,
  NotiflyContainer,
  NotiflyContent,
  NotiflyWrapper,
  SFontDark,
} from './styles';

function NotiflyDetails({ ...props }) {
  const { user } = useAuth();
  const {
    data: { attributes },
    notificationEndRef,
  } = props;

  return (
    <NotiflyWrapper ref={notificationEndRef}>
      <NotiflyContent>
        <BFontBold>Mensagem: </BFontBold>
        <SFontDark> {attributes.subtitle}</SFontDark>
      </NotiflyContent>
      {attributes?.sender && (
        <NotiflyContent>
          {user.type === 'professional' ? (
            <BFontBold>Cliente: </BFontBold>
          ) : (
            <BFontBold>Profissional:</BFontBold>
          )}
          <SFontDark>{attributes?.sender?.full_name}</SFontDark>
        </NotiflyContent>
      )}
      <NotiflyContent>
        <BFontBold>Data: </BFontBold>
        <SFontDark>
          {' '}
          {moment(attributes?.createdAt).format('DD/MM/YYYY HH:ss')}
        </SFontDark>
      </NotiflyContent>
    </NotiflyWrapper>
  );
}

export default function NotificationList() {
  const { user } = useAuth();
  const { width } = useWindowSize();
  const notificationEndRef = useRef(null);
  const { notifications, setNotifications } = useEvent();
  const [notifly, setNotifly] = useState({});
  const getNotifications = useAxiosGet();

  useEffect(() => {
    const { controller, data, errors, fetchData, loading } = getNotifications;

    if (data.length === 0) fetchData(`/${user.type}s/notifications`);

    if (data.length)
      setNotifications({
        data,
        loading,
      });

    if (errors) toast.error('Erro ao carregar notificações');

    return () => controller.abort();
  }, [getNotifications.loading]);

  const sortNotifications = (notifications) =>
    notifications?.sort(
      (n1, n2) =>
        n1.attributes.readed - n2.attributes.readed ||
        n2.attributes.createdAt - n1.attributes.createdAt
    );

  const scrollToTop = () => {
    return notificationEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = async (id) => {
    try {
      const { data } = await api.get(`/${user.type}s/notifications/${id}`);
      setNotifly(data.data);
      width <= BREAKPOINT_MD && scrollToTop();
    } catch (error) {
      toast.error('Erro ao abrir notificação');
    }
  };

  return (
    <Main>
      <ListContainer>
        <BoxScroll>
          <div ref={notificationEndRef} />
          {getNotifications.loading ? (
            <LoaderSpinner logo />
          ) : notifications?.data?.length > 0 ? (
            sortNotifications(notifications?.data)?.map((item, index) => (
              <NotificationsItemList
                key={item.id + index}
                data={item}
                handleClick={handleClick}
              />
            ))
          ) : (
            <EmptyContainer>
              <EmptyImage
                atl="notificação"
                src={BellImg}
                style={{ marginTop: '10%' }}
              />
              <EmptyFont>Você ainda não tem notificações</EmptyFont>
            </EmptyContainer>
          )}
        </BoxScroll>
        <IconAreaFixed>
          <Tooltip title="Voltar o topo">
            <ArrowUpCircleIcon onClick={() => scrollToTop()} />
          </Tooltip>
        </IconAreaFixed>
      </ListContainer>
      <NotiflyContainer>
        {notifly.id ? (
          <NotiflyDetails
            data={notifly}
            notificationEndRef={notificationEndRef}
          />
        ) : (
          <EmptyContainer>
            <EmptyImage src={BellImg} atl="notificação" />
            <EmptyTitle>Você verá suas notificações aqui</EmptyTitle>
            <EmptyText>
              Selecione uma notificação e ela aparecerá desse lado da tela.
            </EmptyText>
          </EmptyContainer>
        )}
      </NotiflyContainer>
    </Main>
  );
}
