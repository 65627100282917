import { Field } from "formik";
import Select from "react-select";
import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  border-bottom-width: 1px;
  border-radius: 5px;
  border: 0 solid ${({ theme }) => theme.lightGray};
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-bottom: ${({ sign }) => (sign === "true" ? "1rem" : "0")};
  width: ${({ sign }) => (sign === "true" ? "100%" : "95%")};

  .--select {
    background-color: #f4f5fa;
    border-radius: 0.325rem;
    border: 1px solid #eaecf4;
    padding: 0;
    width: 100%;
  }
`;

export const ContainerSelect = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledOption = styled.option`
  background-color: transparent;
  border: none;
  display: flex;
  flex: 1;
`;

export const StyledField = styled(Field)`
  background-color: transparent;
  border: none;
  display: flex;
  flex: 1;
  padding: 5px 0 5px 5px;
`;

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.darkGray};
  font-size: 14px;
  line-height: 150%;
`;

export const StyledNewSelect = styled(Select)`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimaryAlt};
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme.ExtralightGray}`};
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: flex-start;
  margin: 0 0 1rem 0;
  max-width: 800px;
  width: 100%;
`;

export const FieldLabel = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.black};
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
`;
