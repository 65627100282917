/* eslint-disable react-hooks/exhaustive-deps */

import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { SMFontBackground } from "../../../../components/MessageElements/styles";
import {
  UserAvatar,
  handleUserNames,
} from "../../../../components/UserElements";
import useEvent from "../../../../hooks/event";
import ClinicLogo from "../../../../images/logo-clinic-default.png";
import Profile from "../../../../images/noAvatar.png";
import api from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import {
  ButtonScrollToTop,
  ScrollToTop,
} from "../../../../styles/globalStyles";
import convertCurrencyPtBr from "../../../../utils/convertCurrencyPtBr";
import getProfessionName from "../../../../utils/getProfessionName";
import { getProfessions } from "../../../../utils/getProfessionsFilter";
import { ProfessionalFilter } from "../../../Public/Professionals/components";
import {
  AvatarCard,
  Button,
  ColumnTextContent,
  ColumnTextTitle,
  EmptyContainer,
  EmptyImg,
  EmptyLabel,
  ListGrid,
  ListProfessionalContainer,
  LogoClinic,
  LogoImage,
  MBFontSecondary,
  MainContainer,
  MedalIcon,
  ProfessionalCard,
  PublicListBody,
  PublicSearchContainer,
  RowTextCenter,
  RowTextSpaced,
  SMFontDark,
  SMFontPrimary,
} from "./styles";
import isFilterDataInputCompare from "../../../../utils/isFilterDataInputCompare";

function EmptyList({ ...props }) {
  const { text } = props;
  return (
    <EmptyContainer>
      <EmptyLabel>{text}</EmptyLabel>
      <EmptyImg />
    </EmptyContainer>
  );
}

export function Professional({ ...props }) {
  const { scheduleInfo, setScheduleInfo, resetFilters } = useEvent();
  const { professional, id } = props;
  const history = useHistory();
  const type = localStorage.getItem("type");
  const [auth] = useState(type === "client" ? "cliente" : "publica");

  function getNextSchedule(professional) {
    let today = new Date();
    let todayWeekDay = today.getDay();
    const {
      availabilityRule: { workdays },
    } = professional;
    if (workdays) {
      let nextDay = professional.availabilityRule.workdays?.filter(
        day => parseInt(day, 10) > todayWeekDay,
      );
      if (nextDay?.length === 0) {
        nextDay = workdays[0];
        today.setDate(
          today.getDate() + (6 - todayWeekDay + (parseInt(nextDay, 10) + 1)),
        );
      } else {
        today.setDate(
          today.getDate() + (parseInt(nextDay[0], 10) - todayWeekDay),
        );
      }
    }
    return today;
  }

  return (
    <ProfessionalCard>
      <AvatarCard>
        <UserAvatar src={professional.avatar || Profile} search />
      </AvatarCard>
      {professional?.clinicId && (
        <LogoClinic>
          <LogoImage src={professional?.clinic?.avatar || ClinicLogo} />
        </LogoClinic>
      )}
      <ColumnTextTitle>
        <MBFontSecondary>{handleUserNames(professional)}</MBFontSecondary>
        <SMFontDark>{`${getProfessionName(professional.profession, "toPt")} ${
          professional.address.city ? `| ${professional.address.city}` : ""
        }`}</SMFontDark>
      </ColumnTextTitle>
      <ColumnTextContent>
        <RowTextCenter>
          <SMFontPrimary style={{ fontWeight: "400" }}>
            Próxima data:{" "}
          </SMFontPrimary>
          <SMFontPrimary>{`${
            PtBr.days_abs[getNextSchedule(professional).getDay()]
          }, ${moment(getNextSchedule(professional), "YYYY-MM-DD").format(
            "DD/MM/YYYY",
          )}`}</SMFontPrimary>
        </RowTextCenter>
        <RowTextCenter>
          {professional?.specialities?.map((item, index) =>
            index <= 3 ? (
              <SMFontBackground key={item}>{item}</SMFontBackground>
            ) : null,
          )}
          {professional?.specialities?.length > 4 && (
            <Tooltip title={professional?.specialities?.slice(4).join(", ")}>
              <SMFontBackground>
                {`+${professional?.specialities?.length - 4}`}
              </SMFontBackground>
            </Tooltip>
          )}
        </RowTextCenter>
        <RowTextSpaced>
          <RowTextCenter style={{ width: "40%" }}>
            <SMFontPrimary style={{ fontSize: "1.1rem" }}>
              {convertCurrencyPtBr(professional?.sessionPrice)}
            </SMFontPrimary>
            <SMFontDark style={{ fontSize: "1.1rem" }}>{`por ${
              professional?.availabilityRule?.appointmentDuration || "30"
            } min`}</SMFontDark>
          </RowTextCenter>
          <RowTextCenter className="evaluate">
            <SMFontDark style={{ fontSize: "1.1rem", marginRight: "0.325rem" }}>
              {professional.likes || "0"} avaliações
            </SMFontDark>
            {!professional?.clinicId && professional.acceptPromotion && (
              <Tooltip title="Esse profissional participa de promoções Allminds">
                <MedalIcon />
              </Tooltip>
            )}
          </RowTextCenter>
        </RowTextSpaced>
        {professional?.clinicId && (
          <RowTextCenter>
            <SMFontPrimary>
              Esse profissional faz parte da clinica
            </SMFontPrimary>
            <SMFontDark>{professional?.clinic?.name}</SMFontDark>
          </RowTextCenter>
        )}
      </ColumnTextContent>
      <RowTextCenter
        style={{
          justifyContent: "center",
          margin: "2rem 0 2rem 0",
          paddingLeft: "0",
        }}
      >
        <Button
          onClick={() => {
            if (scheduleInfo?.professional?.id !== id) setScheduleInfo(null);
            if (type === "client") history.push(`/client/schedule/${id}`);
            else history.push(`/${auth}/agendamento/${id}`);
            resetFilters();
          }}
        >
          Agendar
        </Button>
      </RowTextCenter>
    </ProfessionalCard>
  );
}

export default function ProfessionalListScreen() {
  const { getProfessionals, professionals, eventFilter } = useEvent();
  const [loading, setLoading] = useState(true);
  const [professions, setProfessions] = useState([]);
  const [professionalsFilter, setProfessionalsFilter] = useState(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessionals = async () => {
      try {
        await getProfessionals(controller);
      } catch (error) {
        toast.error("Não foi possível buscar lista de profissionais");
      } finally {
        setLoading(false);
      }
    };
    fetchProfessionals();

    const fetchProfessions = async () => {
      try {
        const { data } = await api.get("/professions", {
          signal: controller.signal,
        });
        const professionList = getProfessions(
          data?.data?.attributes?.professions,
        );
        setProfessions(professionList);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível buscar profissões");
      }
    };
    fetchProfessions();

    return () => controller.abort();
  }, []);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  function isEqual(a, b, type) {
    if (b?.length === 0 || b === "" || b === 0 || b === null) return true;
    if (type === 1) return a?.toLowerCase()?.includes(b?.toLowerCase());
    if (type === 2) return filterProfessions(a, b);
    return FilterArray(a, b);
  }

  function isDefault(filter) {
    let isDefaultValue = true;
    Object.keys(filter).forEach(key => {
      if (
        filter[`${key}`]?.length !== 0 &&
        filter[`${key}`] !== "" &&
        filter[`${key}`] !== 0 &&
        filter[`${key}`] !== null
      ) {
        isDefaultValue = false;
      }
    });
    return isDefaultValue;
  }

  function FilterArray(a, b) {
    let tmp = [];
    tmp = b?.filter(item => a?.includes(item));

    return tmp?.length > 0;
  }

  function filterProfessions(a, b) {
    let tmp = [];

    tmp = b?.filter(item => a?.includes(item));
    return tmp?.length > 0;
  }

  function filterProfessionalAdvisor(filter) {
    let profTmp = [];
    if (filter?.professionFilter?.includes("professional_advisor")) {
      profTmp = professionals?.filter(
        item =>
          (item.attributes?.reasons?.includes("Orientação profissional") ||
            item.attributes?.approaches?.includes("Orientação profissional") ||
            item.attributes?.specialities?.includes(
              "Orientação profissional",
            )) &&
          item,
      );
    }
    return profTmp;
  }

  function filterAddress(filter) {
    const address =
      filter.address === "todas as cidades" ||
      filter.address === undefined ||
      filter.address === null
        ? (filter.address = "")
        : filter.address;
    return address;
  }

  const filterProfessionals = async filter => {
    const address = filterAddress(filter);

    const professionalAdvisor = eventFilter.professionFilter.includes(
      "professional_advisor",
    )
      ? filterProfessionalAdvisor(filter)
      : [];

    const data = professionals?.filter(
      item =>
        isDefault(filter) ||
        (isEqual(
          `${item.attributes.firstName} ${item.attributes.lastName}`,
          filter.search,
          1,
        ) &&
          isEqual(item.attributes.profession, filter.professionFilter, 2) &&
          isEqual(
            item.attributes.availabilityRule.appointmentTypes,
            filter.sessionType,
            3,
          ) &&
          isEqual(item.attributes.address.city, address, 1) &&
          isEqual(item.attributes.specialities, filter.specialities, 0) &&
          isEqual(item.attributes.reasons, filter.reasons, 0) &&
          isEqual(item.attributes.approaches, filter.approaches, 0) &&
          parseInt(item.attributes.sessionPrice, 10) >= filter.price),
    );

    !isFilterDataInputCompare(eventFilter)
      ? setProfessionalsFilter([...data, ...professionalAdvisor])
      : setProfessionalsFilter(null);
  };

  const professionalsData = useMemo(() => {
    if (!professionalsFilter) return professionals;
    return professionalsFilter;
  }, [professionalsFilter, professionals]);

  return (
    <MainContainer>
      <PublicListBody ref={scrollRef}>
        <PublicSearchContainer>
          <ProfessionalFilter
            onSubmit={filterProfessionals}
            professions={professions}
            loading={loading}
            professionals={professionals}
            setProfessionalsFilter={setProfessionalsFilter}
          />
        </PublicSearchContainer>
        {loading ? (
          <LoaderSpinner logo />
        ) : (
          <ListProfessionalContainer>
            {professionalsData?.length === 0 ? (
              <EmptyList text="Nenhum profissional foi encontrado. Tente alterar os filtros de busca!" />
            ) : (
              <ListGrid>
                {professionalsData?.map(prof => (
                  <Professional
                    key={prof.id}
                    professional={prof.attributes}
                    id={prof.id}
                    search
                  />
                ))}
              </ListGrid>
            )}
            {!loading && (
              <Tooltip title="Voltar ao topo">
                <ButtonScrollToTop onClick={scrollToTop}>
                  <ScrollToTop />
                </ButtonScrollToTop>
              </Tooltip>
            )}
          </ListProfessionalContainer>
        )}
      </PublicListBody>
    </MainContainer>
  );
}
