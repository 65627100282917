import styled from 'styled-components';
import { BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const BankCardContainer = styled.section`
  height: 600px;
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 2em;
  }
`;
