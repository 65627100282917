export function convertKeyLabel(key, targetLang) {
  if (targetLang === 'toPt') {
    switch (key) {
      case 'revenueRealized':
        return 'Receita Realizada';
      case 'revenueForecasted':
        return 'Receita Prevista';
      case 'totalRevenue':
        return 'Receita Total';
      case 'appointmentsCompleted':
        return 'Consultas Realizadas';
      case 'appointmentsScheduled':
        return 'Consultas Agendadas';
      case 'avgValuePerAppointment':
        return 'Receita média por Consulta';
      case 'percentOnlineAppointments':
        return '% de Consultas Online';
      case 'percentRevenuePix':
        return '% de Receita por Pix';
      case 'percentRevenueCreditCard':
        return '% de Receita por Cartão';
      case 'totalClients':
        return 'Clientes Total';
      case 'reviews':
        return 'Reviews';
      case 'percentPositiveReviews':
        return '% de Reviews Positivos';
      case 'registeredProfessionals':
        return 'Profissionais Cadastrados';
      case 'avgRevenuePerProfessional':
        return 'Receita Média por Profissional';
      case 'appointmentsPerProfessional':
        return 'Consultas por Profissional';
      case 'percentRevenueExternal':
        return '% de Receita Externa';
      default:
        return 'Indefinido';
    }
  } else if (targetLang === 'toEng') {
    switch (key) {
      case 'Receita Realizada':
        return 'revenueRealized';
      case 'Receita Prevista':
        return 'revenueForecasted';
      case 'Receita Total':
        return 'totalRevenue';
      case 'Consultas Realizadas':
        return 'appointmentsCompleted';
      case 'Consultas Agendadas':
        return 'appointmentsScheduled';
      case 'Receita média por Consulta':
        return 'avgValuePerAppointment';
      case '% de Consultas Online':
        return 'percentOnlineAppointments';
      case '% de Receita por Pix':
        return 'percentRevenuePix';
      case '% de Receita por Cartão':
        return 'percentRevenueCreditCard';
      case 'Clientes Total':
        return 'totalClients';
      case 'Reviews':
        return 'reviews';
      case '% de Reviews Positivos':
        return 'percentPositiveReviews';
      case 'Profissionais Cadastrados':
        return 'registeredProfessionals';
      case 'Receita Média por Profissional':
        return 'avgRevenuePerProfessional';
      case 'Consultas por Profissional':
        return 'appointmentsPerProfessional';
      case '% de Receita Externa':
        return 'percentRevenueExternal';
      default:
        return 'Indefinido';
    }
  }
}

export const getLabelDashboardClinic = (data) => {
  const label = Object.entries(data).map((item) => ({
    label: convertKeyLabel(item[0], 'toPt'),
    value: item[1],
  }));
  return label.sort((a, b) => a.label.localeCompare(b.label));
};
