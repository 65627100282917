import styled from "styled-components";
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  SmallFont,
} from "../../../styles/globalStyles";
import { moveLeftToRight } from "../../../styles/keyframes";

export const MobileHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.primary};
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5%;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileHeaderTitle = styled(BigFont)`
  color: ${({ theme }) => theme.primaryAlt};
`;

export const ConfigMain = styled.form`
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.newPrimaryAlt} inset !important;
  }
`;

export const RowLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ExtralightGray};
  margin-top: 10px;
  margin-bottom: 17px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 1rem;
  }
`;

export const RowLineOptions = styled(RowLine)`
  margin-top: 0px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 10px;
  }
`;

export const ConfigBody = styled.section`
  animation: ${moveLeftToRight} 0.5s ease-in-out;
  padding-bottom: 3rem;
  width: 100%;
`;

export const ContentContainer = styled.section`
  width: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContentLabel = styled(SmallFont)`
  align-self: flex-start;
  color: ${({ theme }) => theme.darkGray};
`;

export const ConfigButtonContainer = styled.section`
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ConfigDataForm = styled.div`
  width: 100%;
`;

export const ConfigWrapper = styled.section`
  animation: ${moveLeftToRight} 0.5s ease-in-out;
  padding-bottom: 2rem;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const ContainerImage = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
  }
`;

export const ButtonRemoveBanner = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.pink};
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  padding: 0.8rem;
`;

export const ButtonUploadBanner = styled(ButtonRemoveBanner)`
  background-color: ${({ theme }) => theme.primary};
`;

export const ContainerBanner = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
  }
`;

export const ArtBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  width: 100%;
`;

export const PreferOwnMeetingLinkWrapper = styled(ArtBannerWrapper)`
  flex-direction: row;
`;

export const BannerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const ContainerPhoneBirth = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const ContainerTitleBank = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  width: 100%;
`;

export const Titleh1 = styled.h1`
  font-size: 1.5rem;
`;

export const CustomPlansTH = styled.th`
  padding-left: 1rem;
  text-align: left;
`;

export const TextError = styled.p`
  color: ${({ theme }) => theme.pink};
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
`;

export const ContainerAccConfig = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

export const TermDocument = styled.div`
  border: 5px solid ${({ theme }) => theme.primaryAlt};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserImage = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 10rem;
  margin-bottom: 2rem;
  overflow: hidden;
  width: 10rem;
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const RowWrapper = styled.div`
  column-gap: 5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
`;

export const MenuHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuOption = styled.button`
  background-color: ${({ active, theme }) =>
    active ? theme.secondary : theme.white};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.secondary};
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  position: relative;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 0.325rem;
    margin-right: 1rem;
  }
`;

export const OptionText = styled(BigFont)`
  color: ${({ theme, active }) => (active ? theme.white : theme.secondary)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;

export const ImagePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
`;

export const ContainerInput = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
