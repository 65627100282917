import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '../../images/leftArrow.svg';
import { ReactComponent as RightArrow } from '../../images/rightArrow.svg';
import { BREAKPOINT_SM_PX, ModalBoxWrapper } from '../../styles/globalStyles';
import { BorderButton, StaticButton } from '../Button';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ forgetPassword }) => (forgetPassword ? '3rem' : '5rem')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 5rem;
    padding: 0 1rem 0 1rem;
  }

  .-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
  }
  .-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
  }
`;

export const LeftArrowIcon = styled(LeftArrow)``;

export const RightArrowIcon = styled(RightArrow)``;

export const CancelButton = styled(BorderButton)`
  width: 48%;
`;
export const SaveButton = styled(StaticButton)``;

export const BoxWrapper = styled(ModalBoxWrapper)`
  width: 500px;
  padding: 1rem;
`;
