import { Checkbox } from "@mui/material";
import React, { Fragment } from "react";
import { PtBr } from "../../../services/pt_br";
import { SmallFont } from "../../../styles/globalStyles";
import FormikControl from "../../FormikControl";
import { ContainerLabel, GridItem, SubmitButtom } from "../styles";

export default function PaymentForm({ form, subscription = null, loading }) {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    touched,
    values,
  } = form;

  const validateEmpty = () => {
    const validateErrors = Object.values(errors)?.filter(
      value => value !== "",
    )?.length;

    if (validateErrors > 0) {
      return true;
    }

    return [
      values.cardHolderName,
      values.cardNumber,
      values.cardCvv,
      values.cardExpiration_date,
    ]?.some(value => value === "" || value === "__/__");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "2rem 0",
        width: "100%",
      }}
    >
      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.cardHolderName}
        label={PtBr.register_input_card_holder_name}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        name="cardHolderName"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.cardHolderName}
        type="text"
        upLabel
        value={values.cardHolderName}
      />

      <FormikControl
        bgColor="#fff"
        control="input"
        errors={errors.cardNumber}
        label={PtBr.register_input_card_number}
        labelFamily="Poppins"
        labelSize="0.9rem"
        labelWeight="600"
        mask="9999 9999 9999 9999"
        name="cardNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.cardNumber}
        type="text"
        upLabel
        value={values.cardNumber}
      />

      <GridItem>
        <FormikControl
          bgColor="#fff"
          className="row-item"
          control="input"
          errors={errors.cardCvv}
          label={PtBr.register_input_card_cvv}
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          mask="999"
          name="cardCvv"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.cardCvv}
          type="text"
          upLabel
          value={values.cardCvv}
        />

        <FormikControl
          bgColor="#fff"
          className="row-item"
          control="input"
          errors={errors.cardExpiration_date}
          label={PtBr.register_input_card_validade}
          labelFamily="Poppins"
          labelSize="0.9rem"
          labelWeight="600"
          mask="99/99"
          name="cardExpiration_date"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.cardExpiration_date}
          type="text"
          upLabel
          value={values.cardExpiration_date}
        />
      </GridItem>

      {subscription === null && (
        <Fragment>
          <FormikControl
            bgColor="#fff"
            control="input"
            errors={errors.cpf}
            label={PtBr.register_input_cpf_rg}
            labelFamily="Poppins"
            labelSize="0.9rem"
            labelWeight="600"
            mask="999.999.999-99"
            name="cpf"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.cpf}
            type="text"
            upLabel
            value={values.cpf}
          />

          <ContainerLabel>
            <Checkbox
              name="setDefault"
              value={values.setDefault}
              onChange={event =>
                setValues({
                  ...values,
                  setDefault: event.target.checked,
                })
              }
            />
            <SmallFont style={{ color: "#000", fontSize: "1rem" }}>
              Forma de pagamento padrão
            </SmallFont>
          </ContainerLabel>
          <SubmitButtom
            disabled={loading || validateEmpty()}
            onClick={handleSubmit}
            style={{ marginTop: "1rem" }}
          >
            {loading ? "Carregando..." : "Adicionar forma de pagamento"}
          </SubmitButtom>
        </Fragment>
      )}
    </div>
  );
}
