/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import LoaderSpinner from '../../../components/LoaderSpinner';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import api from '../../../services/api';
import { ProfileBody, ProfileInfoClient, ProfilePainel } from './components';

export default function PublicProfessionalProfile({ ...props }) {
  const location = useLocation();
  const { id } = useParams();
  const { user } = useAuth();
  const { scheduleInfo } = useEvent();
  const [professional, setProfessional] = useState({});
  const [loading, setLoading] = useState(true);

  const schema = Yup.object({
    duration: Yup.string().required('Duração do agendamento é obrigatório!'),
    startDate: Yup.string().required('Data do agendamento é obrigatório!'),
    startTime: Yup.string().required('Horário o agendamento é obrigatório!'),
    appointmentType: Yup.string().required(
      'Tipo de agendamento é obrigatório!'
    ),
  });

  const form = useFormik({
    initialValues: {
      startDate: scheduleInfo?.startDate || '',
      startTime: scheduleInfo?.startTime || '',
      appointmentType: scheduleInfo?.appointmentType || '',
    },
    validationSchema: schema,
  });

  const { values, setValues, setFieldValue } = form;

  useEffect(() => {
    const controller = new AbortController();
    const fetchProfessional = async () => {
      const type = location.pathname.includes('clinic') ? 'clinics' : 'clients';
      try {
        if (id) {
          const { data } = await api.get(
            `/${type}/professionals/${professional?.id || id}`,
            {
              signal: controller.signal,
            }
          );
          setProfessional({ ...data.data.attributes, id: data.data.id });
        } else {
          setProfessional(user);
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível carregar');
      } finally {
        setLoading(false);
      }
    };
    fetchProfessional();

    return () => controller.abort();
  }, [professional?.id, id]);

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <div
      style={{ padding: location.pathname.includes('/clinic') ? '2rem' : '0' }}
    >
      {user.id === professional.id ? (
        <ProfilePainel user={user} professional={professional} disableEdit />
      ) : (
        <ProfileInfoClient user={user} professional={professional} />
      )}
      <ProfileBody
        user={user}
        professional={professional}
        publicP={location.pathname.includes('/publica/agendamento')}
        values={values}
        setValues={setValues}
        setFieldValue={setFieldValue}
      />
    </div>
  );
}
