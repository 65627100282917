/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  ActiveCoupons,
  CouponButtonAdd,
  CouponButtonCancel,
  CouponName,
  CouponNameContainer,
  CouponStatusText,
  CouponTypeButton,
  CouponsInformationContainer,
  WrapperSelect,
} from '../../../screens/Professional/Config/AccountConfig/Coupons/styles';
import { InputWithdraw } from '../../../screens/Professional/Finance/styles';
import theme from '../../../theme';
import handleErrors from '../../../utils/getValuesErrors';
import FormikControl from '../../FormikControl';
import {
  CheckBox,
  FieldLabel,
  TextError,
} from '../../FormikControl/FormikComponents/styles';

export function CouponType({ ...props }) {
  const { title, subtitle, setSelected, selected, disabled } = props;
  return (
    <CouponTypeButton
      disabled={disabled}
      onClick={() => setSelected(title)}
      selected={selected === title}
      type="button"
    >
      <CouponNameContainer>
        <CouponName>{title}</CouponName>
        <CouponStatusText>{subtitle}</CouponStatusText>
      </CouponNameContainer>
    </CouponTypeButton>
  );
}

export default function CouponForm({ form, ...props }) {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = form;
  const { selectedCoupon, setSelectedCoupon, setShowCoupon, create } = props;
  const FieldValues = [
    'name',
    'price',
    'initialTime',
    'endTime',
    'limitOfUses',
  ];
  const [selected, setSelected] = useState(null);
  const [showing, setShowing] = useState(false);
  const [typeCoupon, setTypeCoupon] = useState(
    selectedCoupon?.attributes?.discountPrice === 0
      ? '% de desconto'
      : 'Valor de desconto'
  );

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  function onSubmit(event) {
    event.preventDefault();
    if (!handleErrors(errors, FieldValues)) {
      handleSubmit(values);
    }
  }

  useEffect(() => {
    const checkCouponCategory = (selected) => {
      if (showing === false) {
        if (values.ilimited === true) {
          setFieldValue('amountLimit', null);
          setFieldValue('dateLimitStart', null);
          setFieldValue('dateLimitEnd', null);
        } else if (selected === 'Tempo' && !values.ilimited) {
          setFieldValue('amountLimit', '');
        } else if (selected === 'Quantidade' && !values.ilimited) {
          setFieldValue('amountLimit', 0);
          setFieldValue('dateLimitStart', '');
          setFieldValue('dateLimitEnd', '');
        }
      }
    };
    if (showing === false) {
      checkCouponCategory(selected);
    }
  }, [selected, values.ilimited]);

  useEffect(() => {
    if (selectedCoupon?.id) {
      setFieldValue(
        'discountPercentage',
        parseFloat(selectedCoupon?.attributes?.discountPercentage)
      );
      setShowing(true);
      if (
        selectedCoupon?.attributes?.amountLimit ||
        selectedCoupon?.attributes?.amountLimit === 0
      ) {
        setSelected('Quantidade');
        setFieldValue('dateLimitStart', null);
        setFieldValue('dateLimitEnd', null);
      } else if (selectedCoupon?.attributes?.dateLimitEnd) {
        setSelected('Tempo');
        setFieldValue(
          'dateLimitStart',
          moment(selectedCoupon?.attributes?.dateLimitStart).format(
            'DD/MM/YYYY'
          )
        );
        setFieldValue(
          'dateLimitEnd',
          moment(selectedCoupon?.attributes?.dateLimitEnd).format('DD/MM/YYYY')
        );
        setFieldValue('amountLimit', null);
      }
    } else {
      setShowing(false);
      setFieldValue('ilimited', null);
    }
  }, [selectedCoupon]);

  return (
    <CouponsInformationContainer>
      <FormikControl
        control="input"
        errors={errors.code}
        label="Código"
        name="code"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="20%OFF"
        readOnly={showing}
        touched={touched.code}
        upLabel={true}
        value={values.code}
      />

      <FormikControl
        control="input"
        label="Descrição do cupom"
        name="title"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="20% de desconto"
        upLabel={true}
        value={values.title}
        errors={errors.title}
        readOnly={showing}
        touched={touched.title}
      />

      <WrapperSelect>
        <div style={{ marginTop: '1rem' }}>
          <FormikControl
            name="type"
            label="Tipo de cupom"
            control="select"
            options={['Valor de desconto', '% de desconto']}
            values={typeCoupon}
            defaultValue={typeCoupon}
            onChange={({ target }) => {
              setFieldValue('discountPrice', 0);
              setFieldValue('discountPercentage', 0);
              setTypeCoupon(target.value);
            }}
          />
        </div>
        {typeCoupon === 'Valor de desconto' ? (
          <div style={{ marginTop: '1rem', width: '100%' }}>
            <label htmlFor="discount">
              Valor de desconto
              <InputWithdraw
                id="discount"
                style={{ backgroundColor: theme.newPrimaryAlt }}
                currency="BRL"
                value={values.discountPrice}
                config={currencyConfig}
                onChange={(_e, value) => setFieldValue('discountPrice', value)}
              />
            </label>
            {errors.discountPrice}
          </div>
        ) : (
          <div style={{ marginTop: '1rem', width: '100%' }}>
            <FormikControl
              disabled={values.discountPrice}
              errors={errors.discountPercentage}
              touched={touched.discountPercentage}
              control="input"
              label="% de desconto"
              max={100}
              min={0}
              name="discountPercentage"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="10%"
              readOnly={showing}
              type="number"
              upLabel={true}
              value={values.discountPercentage}
            />
          </div>
        )}
      </WrapperSelect>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          margin: '1rem 0',
        }}
      >
        <CheckBox
          type="checkbox"
          name="ilimited"
          checked={values.ilimited}
          disabled={showing}
          id="ilimited"
          onChange={({ target }) => setFieldValue('ilimited', target.checked)}
        />
        <FieldLabel
          style={{
            fontSize: '0.8',
            fontFamily: 'Poppins',
            fontWeight: '600',
          }}
          htmlFor="ilimited"
        >
          Cupom por tempo ilimitado
        </FieldLabel>
        {touched.ilimited && errors.ilimited}
      </div>
      {!values.ilimited && (
        <ActiveCoupons>
          <CouponType
            title="Tempo"
            subtitle="válido por tempo"
            selected={selected}
            setSelected={setSelected}
            disabled={showing}
          />
          <CouponType
            title="Quantidade"
            subtitle="quantidade fixa de cupons"
            selected={selected}
            setSelected={setSelected}
            disabled={showing}
          />
        </ActiveCoupons>
      )}
      {selected === 'Tempo' && !values.ilimited && (
        <ActiveCoupons>
          <div>
            <FormikControl
              label="Data de início"
              name="dateLimitStart"
              control="date"
              value={values.dateLimitStart}
              onChange={(value) =>
                setFieldValue(
                  'dateLimitStart',
                  moment(value).format('DD/MM/YYYY')
                )
              }
              readOnly={showing}
            />
            <TextError>
              {touched.dateLimitStart && errors.dateLimitStart}
            </TextError>
          </div>
          <div>
            <FormikControl
              label="Data de fim"
              name="dateLimitEnd"
              control="date"
              value={values.dateLimitEnd}
              onChange={(value) =>
                setFieldValue(
                  'dateLimitEnd',
                  moment(value).format('DD/MM/YYYY')
                )
              }
              readOnly={showing}
            />
            <TextError>{touched.dateLimitEnd && errors.dateLimitEnd}</TextError>
          </div>
        </ActiveCoupons>
      )}
      {selected === 'Quantidade' && !values.ilimited && (
        <FormikControl
          control="input"
          errors={errors.amountLimit}
          label="Quantidade limite"
          min={0}
          name="amountLimit"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="0"
          readOnly={showing}
          touched={touched.amountLimit}
          type="number"
          value={values.amountLimit}
        />
      )}
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <CouponButtonCancel
          create={create ? 'true' : 'false'}
          type="button"
          onClick={() => {
            setSelectedCoupon(null);
            setShowCoupon(false);
          }}
        >
          Voltar
        </CouponButtonCancel>
        <CouponButtonAdd
          type="button"
          create={create ? 'true' : 'false'}
          onClick={onSubmit}
        >
          {selectedCoupon?.id ? 'Excluir cupom' : 'Salvar'}
        </CouponButtonAdd>
      </div>
    </CouponsInformationContainer>
  );
}
