import { Button } from '@mui/material';
import Modal from 'react-modal';
import styled from 'styled-components';
import { BorderButton, StaticButton } from '../../../components/Button';
import {
  BREAKPOINT_SM_PX,
  BigFont,
  SmallFont,
} from '../../../styles/globalStyles';

export const StyledModal = styled(Modal)`
  bottom: auto;
  display: flex;
  height: 100%;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const ModalContainer = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ModalWrapper = styled.section`
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 90%;
  }
`;

export const ModalHeader = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonRow = styled.section`
  width: 100%;
  padding: 10px 0;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const ModalTitle = styled(BigFont)`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const ModalSubTitle = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
`;

export const Escolher = styled(StaticButton)``;

export const Fechar = styled(BorderButton)``;

export const LoginWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const LabelInput = styled.label`
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
`;

export const AddButton = styled(Button)`
  background-color: ${({ theme, professional }) =>
    professional ? theme.lightBlue : theme.secondary};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  font-family: 'Poppins';
  font-size: 0.9rem;
  font-weight: 600;
  height: fit-content;
  min-height: 2.5rem;
  width: 100%;

  &:hover {
    opacity: 0.7;
  }
`;

export const SelectButton = styled.button`
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 600;
  height: fit-content;
  min-height: 2.5rem;
  transition: 0.4s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.LightWhite};
    border: 1px solid ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.secondary};
  }
`;

export const LabelContainer = styled.div`
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: none;
  border: 1px solid ${({ theme }) => theme.ExtralightGray};
  display: flex;
  margin-top: -0.5rem;
  flex-flow: row wrap;
  padding: 0.5rem;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  border-radius: 0.25rem;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 0.25rem;
  padding-top: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.label`
  background-color: ${({ theme }) => theme.primary};
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  font-family: 'Poppins';
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.15rem 0.3rem;
`;

export const ButtonLabel = styled.button`
  background-color: ${({ theme }) => theme.pink};
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  color: ${({ theme }) => theme.white};
  font-family: 'Poppins';
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.1rem 0.35rem;
`;
