import React, { Fragment } from "react";

const VimeoEmbed = ({ url }) => {
  return (
    <Fragment>
      <iframe
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        frameBorder="0"
        height="100%"
        src={url}
        title="Vimeo video player"
        width="100%"
      />
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Fragment>
  );
};

export default VimeoEmbed;
