import React from 'react';
import Modal from 'react-modal';
import LoaderSpinner from '../../LoaderSpinner';

export default function ModalLoader({ loading, setLoading }) {
  return (
    <Modal
      isOpen={loading}
      style={{
        overlay: { backgroundColor: 'rgba(255,255,255,0.7)' },
        content: { backgroundColor: 'transparent', border: 'none' },
      }}
      ariaHideApp={false}
    >
      <LoaderSpinner setLoading={setLoading} />
    </Modal>
  );
}
