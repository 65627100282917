/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { ArrowLeft, CheckSquare, MessageSquare, Square } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StaticButton } from '../../../../components/Button';
import {
  UserAvatar,
  handleUserNames,
} from '../../../../components/UserElements';
import ClinicLogo from '../../../../images/logo-clinic-default.png';
import { PtBr } from '../../../../services/pt_br';
import { MedalIcon, MediumFont } from '../../../../styles/globalStyles';
import convertCurrencyPtBr from '../../../../utils/convertCurrencyPtBr';
import getProfessionName from '../../../../utils/getProfessionName';
import ProfessionalReviewContainer, {
  CalendarComponent,
} from '../../../Client/Professionals';
import { MessageButton } from '../../../Client/Professionals/styles';
import { HeaderNav } from '../../Header/styles';
import {
  BigText,
  BlueBigText,
  BlueSmallText,
  Body,
  ClinicContainer,
  ColumnContainer,
  ContentContainer,
  EventInfoContainer,
  EventWrapper,
  ExBanner,
  LightSmallText,
  ListItem,
  MainCalendar,
  ProfileAvailability,
  ProfileBanner,
  ProfileEditButton,
  ProfileInformation,
  ProfileName,
  ProfileVideo,
  ProfileWrapper,
  SmallText,
  WrapperCalendar,
  WrapperContainer,
} from './styles';

const hasReasons = (professional) => {
  if (professional.reasons) {
    if (
      professional.reasons?.length === 0 ||
      professional.reasons?.some((item) => item === 'N/A')
    ) {
      return false;
    }
    return true;
  }
  return false;
};

const hasApproaches = (professional) => {
  if (professional.approaches) {
    if (
      professional.approaches?.length === 0 ||
      professional.approaches?.some((item) => item === 'N/A')
    ) {
      return false;
    }
    return true;
  }
  return false;
};

export function ProfileInfo({ ...props }) {
  const { professional, disableEdit, user } = props;
  const theme = useTheme();
  const history = useHistory();
  const [isProfessional] = useState(user?.id === professional.id);

  return (
    <ProfileBanner>
      <ProfileWrapper>
        <UserAvatar src={professional.avatar} />
        <WrapperContainer>
          <ProfileName style={{ fontSize: '1.5rem' }}>
            {handleUserNames(professional)}
          </ProfileName>
          <LightSmallText>
            {getProfessionName(professional?.profession, 'toPt') ||
              'Indisponível'}{' '}
            | {professional?.address?.city}
          </LightSmallText>
          <LightSmallText style={{ marginTop: '1rem' }}>
            Próxima data:{' '}
            <p
              style={{
                fontWeight: '700',
                opacity: '1',
                color: '#fff',
                marginLeft: '0.325rem',
              }}
            >
              {moment(professional?.availabilityRule?.nextAvailableTime).format(
                'MMM, DD/MM/YYYY'
              )}
            </p>
          </LightSmallText>
        </WrapperContainer>
      </ProfileWrapper>
      <EventWrapper>
        <EventInfoContainer>
          <SmallText style={{ width: '100%' }}>
            <p
              style={{
                fontSize: '1rem',
                fontWeight: '700',
                opacity: '1',
                color: '#fff',
                marginRight: '0.325rem',
              }}
            >
              {convertCurrencyPtBr(professional?.sessionPrice)}
            </p>
            {' por ' +
              professional?.availabilityRule?.appointmentDuration +
              'min'}
          </SmallText>
          <LightSmallText style={{ marginTop: '1rem', width: '100%' }}>
            {'CRP: '}
            {professional?.professionalDocumentNumber || 'Indisponível'}
          </LightSmallText>
        </EventInfoContainer>
        <EventInfoContainer>
          <LightSmallText style={{ marginBottom: '1rem' }}>
            {professional?.availabilityRule?.appointmentTypes?.includes(
              'in_person'
            ) ? (
              <CheckSquare />
            ) : (
              <Square />
            )}
            <p style={{ marginLeft: '0.5rem' }}>Presencial</p>
          </LightSmallText>
          <LightSmallText>
            {professional?.availabilityRule?.appointmentTypes?.includes(
              'online'
            ) ? (
              <CheckSquare />
            ) : (
              <Square />
            )}
            <p style={{ marginLeft: '0.5rem' }}>Online</p>
          </LightSmallText>
        </EventInfoContainer>
        <EventInfoContainer className="--section-icon">
          {isProfessional ? (
            <StaticButton
              width="200px"
              buttonColor={theme.secondary}
              title={PtBr.edit_professional_profile}
            />
          ) : (
            user && (
              <Tooltip title="Enviar mensagem">
                <MessageButton onClick={() => history.push(`/client/messages`)}>
                  <MessageSquare className="-icon" />
                </MessageButton>
              </Tooltip>
            )
          )}
          <LightSmallText
            style={{
              margin: '1rem 0',
              fontWeight: '700',
              fontSize: '1rem',
            }}
          >
            {`${
              professional.reviews?.length !== 0
                ? professional.reviews?.length
                : '0'
            } avaliações`}
          </LightSmallText>
          {disableEdit || (
            <ProfileEditButton
              onClick={() => history.push('/professional/config')}
            >
              {PtBr.edit_professional_profile}
            </ProfileEditButton>
          )}
        </EventInfoContainer>
      </EventWrapper>
    </ProfileBanner>
  );
}

export function ProfilePainel({ ...props }) {
  const { user, professional, publicP } = props;

  return (
    <ColumnContainer>
      <ProfileInfo
        professional={professional}
        disableEdit={user.id !== professional.id}
        publicP={publicP}
      />
    </ColumnContainer>
  );
}

export function ProfileBio({ ...props }) {
  const { professional } = props;
  return (
    <ExBanner>
      {professional.bio && (
        <Fragment>
          <BigText>Biografia</BigText>
          <BlueSmallText style={{ marginTop: '1rem', textAlign: 'justify' }}>
            {professional?.bio}
          </BlueSmallText>
        </Fragment>
      )}
    </ExBanner>
  );
}

export function ProfileBody({ ...props }) {
  const location = useLocation();
  const {
    professional,
    user,
    publicP,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
    profile,
  } = props;
  const address = professional?.address;

  return (
    <Body>
      <ProfileAvailability>
        {professional.presentationVideo && (
          <ProfileVideo width="100%" url={professional?.presentationVideo} />
        )}
        {professional?.clinicId && (
          <ClinicContainer href={`/${professional?.clinic?.username}`}>
            <img
              src={professional?.clinic.avatar || ClinicLogo}
              alt="Clinica"
              style={{ width: '4rem', height: '4rem', borderRadius: '1rem' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginLeft: '1rem',
              }}
            >
              <MediumFont
                style={{
                  cursor: 'pointer',
                }}
              >
                Esse profissional faz parte da clinica{' '}
              </MediumFont>
              <MediumFont
                style={{
                  cursor: 'pointer',
                  fontWeight: '700',
                }}
              >
                {professional?.clinic?.name}
              </MediumFont>
            </div>
          </ClinicContainer>
        )}
        <ProfileBio professional={professional} />
        <ContentContainer>
          <BigText style={{ marginBottom: '0.5rem' }}>
            Tipo de Agendamento
          </BigText>
          {professional?.availabilityRule?.appointmentTypes?.map((item) => (
            <ListItem key={item}>
              {item === 'online' ? 'Online' : 'Presencial'}
            </ListItem>
          ))}
        </ContentContainer>
        <ContentContainer>
          <BigText style={{ marginBottom: '0.5rem' }}>
            Endereço Comercial
          </BigText>
          {professional?.availabilityRule?.appointmentTypes?.includes(
            'in_person'
          ) && (
            <p>
              {`${address?.street}, N: ${address?.street_number},
                Complemento: ${address?.complement}, Bairro: ${address.neighborhood},
                Cidade: ${address?.city}, Estado: ${address?.state},
                País: ${address?.country}`}
            </p>
          )}
        </ContentContainer>
        <ContentContainer>
          <BigText style={{ marginBottom: '0.5rem' }}>Público</BigText>
          {professional?.ageGroups?.map((item) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </ContentContainer>
        {professional.yearsOfExperience && (
          <ContentContainer>
            <BigText style={{ marginBottom: '0.5rem' }}>
              Tempo de experiência
            </BigText>
            <ListItem>{professional?.yearsOfExperience} Anos</ListItem>
          </ContentContainer>
        )}
      </ProfileAvailability>

      <ProfileInformation>
        <ContentContainer>
          <BigText style={{ marginBottom: '0.5rem' }}>Formação</BigText>
          {professional?.academicBackground?.map((item) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </ContentContainer>
        {
          <ContentContainer>
            <BigText style={{ marginBottom: '0.5rem' }}>
              {['psychologist', 'professional_advisor'].includes(
                professional?.profession
              )
                ? 'Motivo(s)/Especialidade(s)'
                : 'Especialidade(s)'}
            </BigText>
            {professional?.specialities
              ?.filter((item) => item !== 'N/A')
              ?.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            {['psychologist', 'professional_advisor'].includes(
              professional?.profession
            ) &&
              professional?.reasons
                ?.filter((item) => item !== 'N/A')
                ?.map((item) => <ListItem key={item}>{item}</ListItem>)}
          </ContentContainer>
        }
        {hasReasons(professional) &&
          !['psychologist', 'professional_advisor'].includes(
            professional?.profession
          ) && (
            <ContentContainer>
              <BigText style={{ marginBottom: '0.5rem' }}>Motivo(s)</BigText>
              {professional?.reasons
                ?.filter((item) => item !== 'N/A')
                ?.map((item) => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
            </ContentContainer>
          )}
        {hasApproaches(professional) && (
          <ContentContainer>
            <BigText style={{ marginBottom: '0.5rem' }}>Abordagem(s)</BigText>
            {professional?.approaches
              ?.filter((item) => item !== 'N/A')
              ?.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
          </ContentContainer>
        )}
        {!professional?.clinic?.id && professional?.acceptPromotion && (
          <ContentContainer
            style={{
              flexDirection: 'row',
              padding: '0',
              paddingLeft: '1.5rem',
              width: '100%',
            }}
          >
            <MedalIcon style={{ margin: 0 }} />
            <MediumFont
              style={{
                fontWeight: 'bold',
                marginLeft: '0.5rem',
              }}
            >
              Esse profissional participa de promoções Allminds
            </MediumFont>
          </ContentContainer>
        )}

        {!profile &&
          !['professional', 'clinic'].some((item) =>
            location.pathname.includes(item)
          ) && (
            <MainCalendar>
              <WrapperCalendar>
                <BlueBigText className="--title">
                  Selecione um dia para agendar
                </BlueBigText>
                <CalendarComponent
                  professional={professional}
                  id={professional.id}
                  isProfessional={
                    user?.type === 'professional'
                      ? professional.id === user.id
                      : false
                  }
                  user={user}
                  row
                  onContinue={handleSubmit}
                  publicP={publicP}
                  values={values}
                  setValues={setValues}
                  setFieldValue={setFieldValue}
                />
              </WrapperCalendar>
            </MainCalendar>
          )}
      </ProfileInformation>

      <ProfessionalReviewContainer professional={professional} />
    </Body>
  );
}

export function ProfileInfoClient({ ...props }) {
  const { user, professional } = props;
  const theme = useTheme();
  const history = useHistory();
  const [isProfessional] = useState(user?.id === professional.id);

  return (
    <ProfileBanner>
      <HeaderNav
        onClick={() => history.go(-1)}
        disabled={history.location.pathname === '/publica/profissionais'}
      >
        <ArrowLeft style={{ marginRight: '0.5rem' }} color="#fff" size={30} />
      </HeaderNav>
      <ProfileWrapper>
        <UserAvatar src={professional.avatar} />
        <WrapperContainer>
          <ProfileName style={{ fontSize: '1.5rem' }}>
            {handleUserNames(professional)}
          </ProfileName>
          <LightSmallText>
            {getProfessionName(professional?.profession, 'toPt') ||
              'Indisponível'}{' '}
            | {professional?.address?.city}
          </LightSmallText>
          <LightSmallText style={{ marginTop: '1rem' }}>
            Próxima data:{' '}
            <p
              style={{
                fontWeight: '700',
                opacity: '1',
                color: '#fff',
                marginLeft: '0.325rem',
              }}
            >
              {moment(professional?.availabilityRule?.nextAvailableTime).format(
                'MMM, DD/MM/YYYY'
              )}
            </p>
          </LightSmallText>
        </WrapperContainer>
      </ProfileWrapper>
      <EventWrapper>
        <EventInfoContainer>
          <SmallText style={{ width: '100%' }}>
            <p
              style={{
                fontSize: '1rem',
                fontWeight: '700',
                opacity: '1',
                color: '#fff',
                marginRight: '0.325rem',
              }}
            >
              {convertCurrencyPtBr(professional?.sessionPrice)}
            </p>
            {' por ' +
              professional?.availabilityRule?.appointmentDuration +
              'min'}
          </SmallText>
          <LightSmallText style={{ marginTop: '1rem', width: '100%' }}>
            {'CRP: '}
            {professional?.professionalDocumentNumber || 'Indisponível'}
          </LightSmallText>
        </EventInfoContainer>
        <EventInfoContainer>
          <LightSmallText style={{ marginBottom: '1rem' }}>
            {professional?.availabilityRule?.appointmentTypes?.includes(
              'in_person'
            ) ? (
              <CheckSquare />
            ) : (
              <Square />
            )}
            <p style={{ marginLeft: '0.5rem' }}>Presencial</p>
          </LightSmallText>
          <LightSmallText>
            {professional?.availabilityRule?.appointmentTypes?.includes(
              'online'
            ) ? (
              <CheckSquare />
            ) : (
              <Square />
            )}
            <p style={{ marginLeft: '0.5rem' }}>Online</p>
          </LightSmallText>
        </EventInfoContainer>
        <EventInfoContainer className="--section-icon">
          {isProfessional ? (
            <StaticButton
              width="200px"
              buttonColor={theme.secondary}
              title={PtBr.edit_professional_profile}
            />
          ) : (
            user &&
            user.type !== 'clinic' && (
              <Tooltip title="Enviar mensagem">
                <MessageButton onClick={() => history.push(`/client/messages`)}>
                  <MessageSquare className="-icon" />
                </MessageButton>
              </Tooltip>
            )
          )}
          <LightSmallText
            style={{ marginTop: '1rem', fontWeight: '700', fontSize: '1rem' }}
          >
            {`${
              professional.reviews?.length !== 0
                ? professional.reviews?.length
                : '0'
            } avaliações`}
          </LightSmallText>
        </EventInfoContainer>
      </EventWrapper>
    </ProfileBanner>
  );
}
