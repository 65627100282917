function isFilterDataInputCompare(filterData) {
  // Função para verificar se o objeto de filtro está vazio
  const filterDataCopy = { ...filterData };
  delete filterDataCopy.professions;
  delete filterDataCopy.professionsFilter;
  const valuesFilter = Object.entries(filterDataCopy)
    ?.map(([_, v]) => v)
    ?.map(v =>
      [
        v?.length === 0,
        Object.keys(v)?.length === 0,
        v === "",
        v === "0",
      ].includes(true),
    );
  return !valuesFilter.includes(false);
}

export default isFilterDataInputCompare;
