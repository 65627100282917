import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SideBar from '../../components/SideNavBar';
import useAuth from '../../hooks/auth';

export function ClientRoutes({ component: Component, ...rest }) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        user.type === 'client' ? (
          <SideBar>
            <Component {...props} />
          </SideBar>
        ) : (
          <Redirect to="/conectar-se" />
        )
      }
    />
  );
}
