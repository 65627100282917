import { Slider } from "rsuite";
import styled from "styled-components";
import { ReactComponent as MedalPlanFree } from "../../../images/iconMedal.svg";
import { ReactComponent as EmptyState } from "../../../images/professional-list-empty.svg";
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  SmallFont,
} from "../../../styles/globalStyles";

export const MainContainer = styled(AnimationWrapper)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const AvatarCard = styled.div`
  background: #fff;
  border: 1px solid #eaecf4;
  border-radius: 1rem;
  position: absolute;
  right: 2rem;
  top: -2rem;
`;

export const FilterContainer = styled.form`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* padding: 0 20px; */
  width: 100%;
  margin-bottom: 10px;
`;

export const FilterSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-around;
  padding: 0px 40px 10px 40px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    flex-direction: column;
  }
`;

export const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ListContainer = styled.section`
  width: 100%;
  width: 100%;
  display: grid;
  margin-top: 3rem;
  padding: 0 5rem;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  align-items: center;
  justify-content: flex-start;
`;

export const SelectListContainer = styled.div`
  margin: 0px 10px;
  padding: 10px 0px;
`;

export const FilterHeader = styled(BigFont)`
  text-align: left;
  font-size: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const FilterLabel = styled(SmallFont)`
  text-align: left;
  align-self: flex-start;
  padding-top: 15px;
  color: ${({ theme }) => theme.darkGray};
`;
export const FilterButton = styled.button`
  width: 50%;
  height: 40px;
  border: none;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-end;

  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  background-color: transparent;
`;

export const RowLine = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const ColumnFilter = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
`;

export const SmallColumnFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmallColumnFilterPre = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
`;

// professional

export const StyledSlider = styled(Slider)`
  .-bar {
    color: blue;
    background-color: blue;
  }
`;

export const ProfessionalCard = styled.section`
  align-items: center;
  background-color: white;
  background: white;
  border-radius: 0.3rem;
  border: 1px solid #eaecf4;
  box-shadow: 0px 4px 30px rgba(12, 27, 92, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 1rem;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const RowTextCenter = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1rem 0;
  width: 100%;
`;

export const RowTextSpaced = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .evaluate {
    width: 60%;
    justify-content: flex-end;
  }
`;

export const ColumnTextTitle = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
  width: 80%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
  }
`;

export const ColumnTextSpecialty = styled.section`
  display: flex;
  flex-grow: row wrap;
  width: 80%;
`;

export const ColumnTextContent = styled.section`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const SMFontSecondary = styled(SmallFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 400;
`;

export const SMFontPrimary = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  padding-right: 5px;
`;

export const SMFontDark = styled(SmallFont)`
  color: ${({ theme }) => theme.black};
  font-weight: 400;
`;

export const MBFontSecondary = styled(BigFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 600;
  width: 60%;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.white};
  border-radius: 2rem;
  border: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  height: 3rem;
  width: 80%;
`;

export const ProfessionalPicture = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;
  height: 100px;
  overflow: hidden;
  width: 100px;
`;

export const PublicListBody = styled.section`
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const PublicSearchContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
`;

export const ListProfessionalContainer = styled.section`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
`;

export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  column-gap: 2rem;
  row-gap: 5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const EmptyContainer = styled.section`
  width: 100%;
  display: flex;
  padding: 20px 0 40px 0;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const EmptyLabel = styled.h3`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.secondary};
`;

export const EmptyImg = styled(EmptyState)`
  width: 100%;
`;

export const MedalIcon = styled(MedalPlanFree)`
  height: 20px;
  width: 20px;
  background-color: transparent;
`;

export const ProfessionalFilterButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.newGray};
  border: 1px solid ${({ theme }) => theme.newPrimary};
  border-radius: 1rem;
  color: ${({ theme }) => theme.black};
  display: flex;
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  line-height: 150%;
  margin-right: 1rem;
  text-align: left;
  width: 100%;
`;

export const ProfessionalSharedButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.white};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 10rem;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const ContainerDescription = styled.div`
  align-items: flex-start;
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin-bottom: 0.5rem;
  min-height: 8rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
`;

export const DescriptionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2rem;
  width: 70%;
`;

export const DescriptionWrapperResponsible = styled(DescriptionWrapper)`
  align-items: flex-end;
  margin-right: 2rem;
`;

export const BodyTitle = styled(BigFont)`
  color: ${({ theme }) => theme.black};
  text-align: left;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-left: 0;
    text-align: justify;
    margin-top: 1.5rem;
  }
`;

export const BodyDescription = styled(SmallFont)`
  color: #999ebc;
  text-align: left;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-left: 0;
    text-align: justify;
  }
`;

export const ContainerClinic = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 4rem;
  width: 100%;
`;

export const ContainerResponsible = styled.div`
  align-items: flex-start;
  border-radius: 1rem;
  background: linear-gradient(to bottom, #eaecf4, #fff);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4rem;
  padding: 2rem 0;
  width: 100%;
`;

export const ClinicName = styled(BigFont)`
  color: ${({ color }) => color};
  font-size: 2rem;
  left: 50%;
  line-height: 3rem;
  max-width: 35%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    font-size: 1.2rem;
    line-height: 2rem;
    max-width: 100%;
    text-align: center;
    top: 20%;
    width: 80%;
  }
`;

export const ContainerImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
`;

export const ClinicImage = styled.div`
  background-color: ${({ theme }) => theme.white};
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid ${({ theme }) => theme.newGray};
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
`;

export const ClinicImageInBanner = styled.div`
  background-color: ${({ theme }) => theme.white};
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.newGray};
  bottom: 0;
  height: 9rem;
  left: 1rem;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  width: 9rem;
`;

export const ResponsibleAvatar = styled.img`
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
`;

export const ResponsibleName = styled(BigFont)`
  color: ${({ theme }) => theme.black};
  margin-bottom: 0.5rem;
  text-align: justify;
`;

export const ResponsibleAbout = styled(SmallFont)`
  color: #999ebc;
  font-weight: 400;
  margin-left: 8rem;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin-left: 0;
    text-align: justify;
  }
`;
