import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useAuth from "../hooks/auth";
import ClientSignUp from "../screens/Client/SignUp";
import ClientSuccess from "../screens/Client/SignUp/Success";
import ClinicSignUp from "../screens/Clinic/SignUp";
import ClinicSuccess from "../screens/Clinic/SignUp/Success";
import ProfessionalSignUp from "../screens/Professional/SignUp";
import LoginScreen, {
  ClientLoginScreen,
  ClinicLoginScreen,
  ProfessionalLoginScreen,
} from "../screens/Public/Login";
import ForgetPassword from "../screens/Register/ForgetPassword";
import Success from "../screens/Register/Success";
import { PrivateRouteClient } from "./client/privateRouteClient";
import { PrivateRouteClinic } from "./clinic/privateRouteClinic";
import { PrivateRouteProfessional } from "./professional/privateRouteProfessional";
import { PublicRoutes } from "./public/publicRoutes";

export default function Routes() {
  const clinicName = window.location.pathname;
  const { user } = useAuth();

  return (
    <Switch>
      <Route exact path="/conectar-se" component={LoginScreen} />
      <Redirect exact from="/" to="/conectar-se" />

      {/* Private Routes Clientes*/}
      <Redirect exact from="/cliente" to="/cliente/conectar-se" />
      <Route exact path="/cliente/conectar-se" component={ClientLoginScreen} />
      <Route exact path="/cliente/cadastre-se" component={ClientSignUp} />
      <Route
        exact
        path="/cliente/cadastre-se/:referral"
        component={ClientSignUp}
      />
      <Route exact path="/cliente/sucesso" component={ClientSuccess} />
      <Route exact path="/cliente/recuperar-senha" component={ForgetPassword} />

      {/* Private Routes Clients*/}
      <Route path="/client" component={PrivateRouteClient} />

      {/* Private Routes Profissionais*/}
      <Redirect exact from="/profissional" to="/profissional/conectar-se" />

      <Route
        exact
        path="/profissional/conectar-se"
        component={ProfessionalLoginScreen}
      />

      <Route
        exact
        path="/profissional/cadastre-se"
        component={ProfessionalSignUp}
      />

      <Route
        exact
        path="/profissional/cadastre-se/:founder"
        component={ProfessionalSignUp}
      />

      <Route
        exact
        path="/profissional/promo=:founder"
        component={ProfessionalSignUp}
      />

      <Route exact path="/profissional/sucesso" component={Success} />

      <Route
        exact
        path="/profissional/recuperar-senha"
        component={ForgetPassword}
      />

      {/* Private Routes Professionals*/}
      <Route path="/professional" component={PrivateRouteProfessional} />

      {/* Private Routes Clinics*/}
      <Redirect exact from="/clinica" to="/clinica/conectar-se" />

      <Route exact path="/clinica/conectar-se" component={ClinicLoginScreen} />

      <Route exact path="/clinica/cadastre-se" component={ClinicSignUp} />

      <Route
        exact
        path="/clinica/cadastre-se/:referral"
        component={ClinicSignUp}
      />

      <Route exact path="/clinica/sucesso" component={ClinicSuccess} />

      <Route exact path="/clinica/recuperar-senha" component={ForgetPassword} />

      {/* Private Routes Clinics*/}
      <Route path="/clinic" component={PrivateRouteClinic} />

      {/* Public Routes */}
      <Route path="/publica" component={PublicRoutes} />

      {/* Public Routes Clinics */}
      <Route
        path={`${clinicName}`}
        component={user?.id ? PrivateRouteClient : PublicRoutes}
      />
    </Switch>
  );
}
