import { Box } from "@material-ui/core";
import { ExpandLess, Instagram, WhatsApp } from "@mui/icons-material";
import { X } from "react-feather";
import "react-toastify/dist/ReactToastify.css";
import styled, { createGlobalStyle } from "styled-components";
import { ReactComponent as DiaryEmpty } from "../images/diary-empty.svg";
import { ReactComponent as DashBoardEmpty } from "../images/empty-dashboard.svg";
import { ReactComponent as MedalPlanFree } from "../images/iconMedal.svg";
import { ReactComponent as MessageEmpty } from "../images/msgEmpty.svg";
import { ReactComponent as ProfessionalEmpty } from "../images/professional-list-empty.svg";
import { loadingAnimation, moveBottomToTop } from "./keyframes";

export const BREAKPOINT_XL = 1536;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_MD = 900;
export const BREAKPOINT_SM = 600;

export const BREAKPOINT_XL_PX = "1536px";
export const BREAKPOINT_LG_PX = "1200px";
export const BREAKPOINT_MD_PX = "900px";
export const BREAKPOINT_SM_PX = "600px";

export const CALENDAR_COLUMN_XL = 7;
export const CALENDAR_COLUMN_LG = 5;
export const CALENDAR_COLUMN_MD = 3;
export const CALENDAR_COLUMN_SM = 1;

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
    }

    html {
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    body {
        background-color: #FFFFFF;
        color: #333;
        font-size: 14px;
        height: 100%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    button {
        border: none;
        cursor: pointer;
    }

    textarea:focus, input:focus {
      box-shadow: 0 0 0 0;
      outline: 0;
    }

    #root {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.MuiPaper-root::-webkit-scrollbar {
      background-color: #eaecf4;
      border-radius: 5px;
      width: 10px;
    }
    &.MuiPaper-root::-webkit-scrollbar-track {
      background-color: #eaecf4;
      border-radius: 5px;
    }
    &.MuiPaper-root::-webkit-scrollbar-thumb {
      background: #999ebc;
      border-radius: 5px;
    }

    .Toastify__toast-container {
      font-size: 1rem;
      font-weight: 700;
      max-width: 600px;
      width: auto;
    }

    .Toastify__toast-theme--light {
      background: rgba(255, 255, 255, 0.95);
      color: #0C1B5C;
    }

    .Toastify__close-button {
      display: none;
    }

    .Toastify__toast-container--top-right {
      right: 0.5rem;
      top: 0.5rem;
    }
`;

export const ScrollPageStyle = styled.section`
  animation: ${moveBottomToTop} 0.3s ease-in-out;
  &::-webkit-scrollbar {
    background-color: ${({ primary }) => (primary ? "#eaecf4" : "#ced0db")};
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ primary }) => (primary ? "#eaecf4" : "#ced0db")};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
    height: 2.5rem;
  }
`;

export const ScrollCardStyle = styled.div`
  animation: ${moveBottomToTop} 0.3s ease-in-out;
  &::-webkit-scrollbar {
    background-color: #ced0db;
    border-radius: 5px;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
  }
`;

export const AnimationWrapper = styled.section`
  animation: ${moveBottomToTop} 0.3s ease-in-out;
`;

export const SmallFont = styled.label`
  font-size: 14px;
  line-height: 21px;
`;

export const MediumFont = styled.label`
  font-size: 16px;
  line-height: 24px;
`;

export const BigFont = styled.h2`
  font-size: 18px;
  line-height: 27px;
`;

export const MediumBigFont = styled.h2`
  font-size: 24px;
  font-weight: 150%;
`;

export const ExtrBigFont = styled.h1`
  font-size: 32px;
  line-height: 150%;
`;

export const ScrollToTop = styled(ExpandLess)``;

export const EmptyProfessional = styled(ProfessionalEmpty)`
  display: flex;
  margin: 0 0 15px 0;
  width: 100%;
`;

export const EmptyDashBoard = styled(DashBoardEmpty)`
  display: flex;
  margin: 0 0 15px 0;
  width: 100%;
`;

export const EmptyFont = styled(BigFont)`
  color: ${({ theme }) => theme.secondary};
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
`;

export const EmptySpan = styled.span`
  color: ${({ theme }) => theme.secondary};
  font-weight: 700;
`;

export const EmptyMessage = styled(MessageEmpty)`
  display: flex;
  margin: 0 0 15px 0;
  width: 100%;
`;

export const EmptyTransaction = styled(DiaryEmpty)`
  display: flex;
  margin: 0 0 15px 0;
  width: 100%;
`;

export const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 100%;
  }
`;

export const ScrollWrapper = styled(ScrollPageStyle)`
  animation: ${moveBottomToTop} 0.5s ease-in-out;
  height: 100vh;
  overflow: auto;
  padding: 1rem;
  width: 100%;
`;

export const BoxScroll = styled(ScrollCardStyle)`
  animation: none;
  height: calc(100vh - 17vh);
  padding: 1rem;
  overflow-y: auto;
  width: 100%;
`;

export const BoxScrollNavBar = styled(ScrollCardStyle)`
  animation: none;
  height: calc(100vh - 9vh);
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding-right: ${({ padding }) => padding};
  }
`;

export const ModalBoxWrapper = styled(Box)`
  background-color: #f4f5fa;
  border-radius: 1rem;
  border: 1px solid #bcc1e0;
  box-shadow: 24;
  left: 50%;
  max-height: 70%;
  max-width: 60%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    max-height: 85%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    max-width: 85%;
  }
`;

export const ModalReport = styled(ModalBoxWrapper)`
  min-height: 50%;
`;

export const ModalAddress = styled(ModalBoxWrapper)`
  padding: 1rem;
  width: fit-content;

  .--modal-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 1rem 5rem;
    width: 100%;
  }

  .--title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }

  .--text {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
  }

  .--end {
    margin-bottom: 2rem;
  }
`;

export const IconAreaModal = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
`;

export const MedalIcon = styled(MedalPlanFree)`
  height: 20px;
  margin-left: 0.5rem;
  width: 20px;
`;

export const WhatsappIcon = styled(WhatsApp)``;

export const InstagramIcon = styled(Instagram)``;

export const Reticence = styled.span`
  display: inline-block;
  animation: ${loadingAnimation} 1.5s infinite;
  animation-delay: ${props => props.delay}s;
`;

export const ListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem 0.7rem;
  position: relative;
  width: 100%;
`;

export const ButtonScrollToTop = styled.button`
  background-color: ${({ theme }) => theme.color10};
  border-radius: 50%;
  bottom: -2.5rem;
  cursor: pointer;
  left: auto;
  outline: none;
  padding: 0.25rem 0.4rem;
  position: absolute;
  right: 0rem;
  top: auto;
  transition: 0.3s;
  z-index: 99;
`;

export const TextError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
`;
