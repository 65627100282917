/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, FormControl } from "@material-ui/core";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormikProvider, useFormik } from "formik";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { InputText } from "../../../../components/FormikControl/FormikComponents";
import { CreditCardListItem } from "../../../../components/ListItens";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import ModalLoader from "../../../../components/Modals/ModalLoader";
import useAuth from "../../../../hooks/auth";
import useEvent from "../../../../hooks/event";
import api from "../../../../services/api";
import { PtBr } from "../../../../services/pt_br";
import convertCurrencyPtBr from "../../../../utils/convertCurrencyPtBr";
import handleErrors from "../../../../utils/getValuesErrors";
import { ListCardPayment } from "../../../Professional/ProfessionalUpdate/Subscribe";
import {
  PaymentContainer,
  PaymentMethod,
  SMFontGray,
  WrapperTitle,
} from "../Payment/styles";
import { CupomWrapper, RowLine, TypeContainer } from "../Schedules/styles";
import {
  BodyWrapper,
  ButtonAddCreditCard,
  ButtonCoupon,
  CardButtonMethod,
  ContainerCardPayment,
  FlexStartContainer,
  FlexStartText,
  FlexWrapper,
  HeaderWrapperResum,
  Hr,
  PaymentButton,
  PaymentCreditCard,
  PaymentWrapper,
  SMFontBlack,
  WrapperPayment,
  WrapperPaymentPix,
  WrapperTitleResum,
} from "./styles";

export function PayEvent({ form, next, professional, pixData }) {
  const { values, errors, setValues } = form;
  const FieldValues = ["paymentMethod", "creditCardId", "couponId"];
  const history = useHistory();
  const { infoCardCredit } = useEvent();
  const { user } = useAuth();
  const [code, setCode] = useState("");
  const [coupon, setCoupon] = useState("");
  const [creditCards, setCreditCards] = useState([]);
  const [date] = useState(new Date(values.startDate));
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [validated, setValidated] = useState(false);

  async function handleSubmit() {
    if (!handleErrors(errors, FieldValues)) {
      if (values.paymentMethod === "credit_card") {
        if (values.paymentMethod && values.creditCardId) {
          await handleValues(values);
          next();
        } else {
          toast.error("As informações do cartão estão incompletas!");
        }
      } else {
        await handleValues(values);
        next();
      }
    }
  }

  const validateCoupon = async () => {
    try {
      const { data } = await api.post("clients/validate-coupon", {
        data: {
          attributes: {
            professional_id: professional.id,
            code: code,
          },
        },
      });
      toast.success("Cupom disponível!");
      setCoupon(data.data);
      setValidated(true);
    } catch (error) {
      setCoupon("");
      switch (error?.response?.data?.error) {
        case "Coupon already used":
          toast.error("Cupom já utilizado!");
          break;
        case "Coupon ja ultilizado":
          toast.error("Cupom já utilizado!");
          break;
        case "Coupon not found":
          toast.error("Cupom inválido!");
          break;
        case "Professional not available for coupond":
          toast.error("Cupom indisponível para este profissional!");
          break;
        case "Coupon expired":
          toast.error("Cupom expirado!");
          break;
        default:
          toast.error("Erro desconhecido ao tentar validar cupom");
          break;
      }
      setValidated(true);
    }
  };

  const handleChangeCode = event => {
    setCode(event.target.value);
    setValidated(false);
  };

  const handleValues = async values => {
    if (coupon && validated) {
      setValues({ ...values, couponId: coupon.id });
    }
  };

  const handleContinue = () => {
    if (validated) {
      handleSubmit();
    } else if (validated && code === !"") {
      toast.error(
        "Valide o cupom ou deixe o campo em branco antes de prosseguir!",
      );
    } else {
      handleSubmit();
    }
  };

  const handleDeleteCard = async id => {
    try {
      await api.delete(`${user.type}s/credit_cards/${id}`);
      toast.success("Cartão deletado com sucesso.");
      setCreditCards(creditCards?.filter(card => card.id !== id));
    } catch (error) {
      toast.error("Não foi possivel deletar o cartão");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getCreditCards = async () => {
      try {
        const { data } = await api.get(`${user.type}s/credit_cards`, {
          signal: controller.signal,
        });
        setCreditCards(data.data);
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Erro ao buscar histórico de cartões");
      } finally {
        setLoading(false);
      }
    };
    getCreditCards();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const creditCard = creditCards?.find(card => card?.attributes?.default);
    const creditCardId = infoCardCredit || creditCard?.id;
    setValues({
      ...values,
      creditCardId,
      paymentMethod: values.paymentMethod || "credit_card",
    });
  }, [creditCards, infoCardCredit]);

  useEffect(() => {
    if (pixData !== null) {
      setLoading(false);
    }
  }, [pixData]);

  const handleCouponType = (cp, sessionP) => {
    if (cp?.discountPercentage !== "0.0") {
      return (sessionP * cp.discountPercentage) / 100;
    } else {
      return cp.discountPrice;
    }
  };

  return (
    <PaymentContainer>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PaymentWrapper>
          <HeaderWrapperResum>
            <WrapperTitleResum>Resumo do Agendamento</WrapperTitleResum>
            <FlexWrapper>
              <SMFontBlack>{`${values.duration} min`}</SMFontBlack>
              <SMFontBlack>
                {`${PtBr.days_name[date.getDay()]},
                ${date.getDate()} de ${PtBr.months_name[date.getMonth()]}
                às ${moment(values.startTime, "HH:mm:ss").format("HH:mm")}`}
              </SMFontBlack>
              <FlexStartContainer>
                <FlexStartText style={{ marginRight: "0.325rem" }}>
                  Valor da consulta:
                </FlexStartText>
                <FlexStartText bold>
                  {` ${convertCurrencyPtBr(values.sessionPrice)}`}
                </FlexStartText>
              </FlexStartContainer>
            </FlexWrapper>
            <TypeContainer>
              <FormControl style={{ width: "100%" }}>
                <CupomWrapper>
                  <InputText
                    upLabel
                    bgColor="#fff"
                    placeholder="Código do Cupom"
                    value={code}
                    label="Cupom de desconto"
                    onChange={handleChangeCode}
                  />
                  <ButtonCoupon onClick={validateCoupon}>
                    Validar cupom
                  </ButtonCoupon>
                </CupomWrapper>
              </FormControl>
            </TypeContainer>
            <Hr />
            <Container style={{ padding: "0px" }}>
              <FlexWrapper>
                {coupon && (
                  <FlexStartContainer>
                    <FlexStartText style={{ marginRight: "0.325rem" }}>
                      Valor do desconto:{" "}
                    </FlexStartText>
                    <FlexStartText bold>
                      {`${convertCurrencyPtBr(
                        handleCouponType(
                          coupon.attributes,
                          values.sessionPrice,
                        ),
                      )}`}
                    </FlexStartText>
                  </FlexStartContainer>
                )}
                <FlexStartContainer>
                  <FlexStartText style={{ marginRight: "0.325rem" }}>
                    Cobrança total:
                  </FlexStartText>
                  <FlexStartText bold>
                    {coupon
                      ? `${convertCurrencyPtBr(
                          values.sessionPrice -
                            handleCouponType(
                              coupon.attributes,
                              values.sessionPrice,
                            ) <=
                            0
                            ? 0
                            : values.sessionPrice -
                                handleCouponType(
                                  coupon.attributes,
                                  values.sessionPrice,
                                ),
                        )}`
                      : `${convertCurrencyPtBr(values.sessionPrice)}`}
                  </FlexStartText>
                </FlexStartContainer>
              </FlexWrapper>
            </Container>
          </HeaderWrapperResum>
          <PaymentCreditCard
            pix={values.paymentMethod === "pix" && !pixData ? "true" : "false"}
          >
            <PaymentMethod>
              <WrapperTitle>Selecione a forma de pagamento</WrapperTitle>
              <RowLine style={{ justifyContent: "center" }}>
                <CardButtonMethod
                  checked={values.paymentMethod === "credit_card"}
                  onClick={() =>
                    setValues({
                      ...values,
                      paymentMethod: "credit_card",
                    })
                  }
                >
                  Cartão de crédito
                </CardButtonMethod>
                <CardButtonMethod
                  checked={values.paymentMethod === "pix"}
                  onClick={() =>
                    setValues({
                      ...values,
                      paymentMethod: "pix",
                    })
                  }
                >
                  PIX
                </CardButtonMethod>
              </RowLine>
              {values.paymentMethod !== "pix" ? (
                <Fragment>
                  <Hr />
                  <WrapperPayment>
                    <ContainerCardPayment>
                      {loading ? (
                        <LoaderSpinner />
                      ) : (
                        <ListCardPayment
                          data={creditCards}
                          renderItem={(item, index) => (
                            <CreditCardListItem
                              key={index}
                              data={item}
                              selected={selected}
                              setSelected={setSelected}
                              handleDeleteCard={handleDeleteCard}
                              exclude
                            />
                          )}
                        />
                      )}
                    </ContainerCardPayment>
                    <ButtonAddCreditCard
                      secondary={"true"}
                      onClick={() =>
                        history.push({
                          pathname: `/${user.type}/subscription/add_new_payment`,
                          state: { id: professional.id },
                        })
                      }
                    >
                      Adicionar forma de pagamento
                    </ButtonAddCreditCard>
                  </WrapperPayment>
                  <Hr />
                  <PaymentButton
                    onClick={() => {
                      if (values.creditCardId === "") {
                        toast.error("Cadastre um cartão");
                      } else {
                        handleContinue();
                      }
                    }}
                    checked={values.paymentMethod}
                  >
                    Realizar pagamento
                  </PaymentButton>
                </Fragment>
              ) : (
                <Fragment>
                  <Hr />
                  {pixData ? (
                    <PayPix data={pixData} />
                  ) : (
                    <PaymentButton
                      onClick={() => {
                        handleContinue();
                        setLoading(true);
                      }}
                      checked={values.paymentMethod}
                    >
                      Realizar pagamento com pix
                    </PaymentButton>
                  )}
                </Fragment>
              )}
            </PaymentMethod>
          </PaymentCreditCard>
        </PaymentWrapper>
      )}
    </PaymentContainer>
  );
}

export function PayPix({ data }) {
  const [values, setValues] = useState({});
  const [icon, setIcon] = useState(<ContentCopyRoundedIcon />);
  const [copyLabel, setCopyLabel] = useState("PIX Copia e Cola");
  const theme = useTheme();

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRoundedIcon />);
    setCopyLabel("Copiado");

    await delay(10000);

    setIcon(<ContentCopyRoundedIcon />);
    setCopyLabel("PIX Copia e Cola");
  };

  useEffect(() => {
    setValues(data.attributes);
  }, [data]);

  return (
    <WrapperPaymentPix>
      <BodyWrapper>
        <WrapperPayment style={{ width: "70%" }}>
          <SMFontGray
            style={{
              alignSelf: "center",
              marginBottom: "10px",
              color: theme.pink,
              textAlign: "justify",
              fontSize: "0.8rem",
            }}
          >
            <ErrorOutlineIcon
              style={{
                color: theme.pink,
                marginRight: "5px",
                marginBottom: "-5px",
              }}
            />
            {
              'Para efetuar o pagamento via PIX, escaneie o QR Code abaixo com a câmera do seu celular ou clique na opção "PIX Copia e Cola"'
            }
          </SMFontGray>
          <QRCodeSVG
            style={{ alignSelf: "center", width: "70%", height: "70%" }}
            size={300}
            value={values?.pixQrCode}
          />
          <WrapperTitle
            style={{
              alignSelf: "center",
              marginTop: "5px",
              fontSize: "0.8rem",
              fontWeight: "300",
              width: "100%",
            }}
          >
            Escaneie o QrCode acima para realizar o pagamento
          </WrapperTitle>
        </WrapperPayment>
        <PaymentMethod style={{ paddingTop: "0", paddingBottom: "2%" }}>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              height: "2.5rem",
              fontSize: "medium",
              bgcolor: "#426AFF",
              color: "#fff",
              borderRadius: "2rem",
            }}
            endIcon={icon}
            onClick={() => {
              handleClick();
              navigator.clipboard.writeText(values.payment.pixQrCode);
            }}
          >
            {copyLabel}
          </Button>
        </PaymentMethod>
      </BodyWrapper>
    </WrapperPaymentPix>
  );
}

export default function PaymentScreen() {
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [schedule, setSchedule] = useState([]);
  const [scheduleId, setScheduleId] = useState([]);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pixData, setPixData] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchSchedule = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/clients/schedules/${id}`, {
          signal: controller.signal,
        });
        setSchedule(data.data.attributes);
        setScheduleId(data.data.id);
        setDate(moment(data.data.attributes.startDate).toDate());
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error("Não foi possível carregar esse agendamento");
      } finally {
        setLoading(false);
      }
    };
    fetchSchedule();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (schedule) {
      form.setValues({
        professionalId: schedule?.professional?.id,
        duration: schedule?.duration,
        startDate: moment(schedule?.startDate).toDate(),
        startTime: schedule?.startTime,
        sessionPrice: schedule?.paymentAmount,
        appointmentType: schedule?.appointmentType,
        paymentMethod: "credit_card",
        creditCardId: "",
        couponId: "",
      });
    }
  }, [schedule]);

  const submitForm = async formValues => {
    try {
      if (formValues.paymentMethod === "pix") {
        const { data } = await api.post("/clients/payments", {
          data: {
            type: "payment",
            attributes: {
              scheduleId: scheduleId,
              couponId: formValues.couponId,
              paymentMethod: "pix",
            },
          },
        });
        setPixData(data.data);
      } else {
        await api.post("/clients/payments", {
          data: {
            type: "payment",
            attributes: {
              scheduleId: scheduleId,
              paymentMethod: formValues.paymentMethod,
              couponId: formValues.couponId,
              creditCardId: formValues.creditCardId,
            },
          },
        });
        toast.success("Pagamento realizado!");
        setTimeout(() => {
          history.push("/client/dashboard");
        }, 3000);
      }
    } catch (error) {
      toast.error("Erro ao realizar pagamento");
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      professionalId: schedule?.professional?.id,
      duration: schedule?.duration,
      startDate: schedule?.startDate,
      startTime: schedule?.startTime,
      sessionPrice: schedule?.paymentAmount,
      appointmentType: schedule?.appointmentType,
      paymentMethod: "credit_card",
      creditCardId: "",
      couponId: "",
    },
    onSubmit: submitForm,
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      {schedule.professional && (
        <PayEvent
          form={form}
          next={handleSubmit}
          professional={schedule?.professional}
          date={date}
          pixData={pixData}
        />
      )}
      {loading && <ModalLoader setLoading={setLoading} />}
    </FormikProvider>
  );
}
