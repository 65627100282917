import { Bell, MessageSquare } from "react-feather";
import Modal from "react-modal";
import styled from "styled-components";
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ExtrBigFont,
  ModalBoxWrapper,
  ScrollWrapper,
  SmallFont,
} from "../../../../styles/globalStyles";

export const Container = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const WrapperNotification = styled.section`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary_blue};
  border-radius: 2rem;
  cursor: ${({ message }) => (message === "true" ? "pointer" : "default")};
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  order: ${({ order }) => order};
  padding: 4rem;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    padding: 2rem;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 3rem;
  justify-content: center;
`;

export const WrapperFinances = styled.div`
  align-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    padding: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 2rem;
  }
`;

export const Main = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ProfileBanner = styled(Container)`
  align-items: center;
  background-color: ${({ theme }) => theme.offWhite};
  border-radius: 0px 0px 20% 20% / 0px 0px 15px 15px;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  width: 30%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 100%;
    justify-content: center;
  }
`;

export const MainDashboard = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: max(450px) 1fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    grid-template-columns: max(300px) 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);
    gap: 2rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const ScheduleWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    gap: 1rem;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
  }
`;

export const Finances = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
`;

export const MonthlyConsultations = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.325rem;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
`;

export const GrossMonthlyRevenue = styled(MonthlyConsultations)``;

export const ContainerImage = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
`;

export const ContainerImageLogo = styled(ContainerImage)`
  border-radius: 1rem;
  max-width: 10rem;
  max-height: 5rem;
`;

export const EventWrapper = styled(Wrapper)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const QueriesWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  padding: 1.5rem 0;
  width: 100%;

  .query-title {
    font-size: 1rem;
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
`;

export const WrapperContainer = styled.div`
  width: 60%;
  padding-left: 40px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const EventContainer = styled.div`
  width: 100%;
  display: flex;
  padding-left: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ProfileName = styled(ExtrBigFont)`
  text-align: left;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.secondary};
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 20px;
  }
`;

export const TextNotification = styled(BigFont)`
  color: ${({ theme }) => theme.white};
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 1.3rem;
    width: 100%;
  }
`;

export const SeeMyProfile = styled(SmallFont)`
  text-align: left;
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;

export const IconTouchableArea = styled.button`
  border: none;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const BellIcon = styled(Bell)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.primaryAlt};
`;

export const MessageSquareIcon = styled(MessageSquare)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.primaryAlt};
`;

export const Image = styled.img`
  border-radius: 0.325rem;
  height: 100%;
  width: 100%;
`;

// body

export const Body = styled.div`
  width: 100%;
  padding: 0 50px;
  align-self: center;
  flex-direction: column;
`;

export const NotificateAvatarModal = styled(Modal)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  position: absolute;
  display: flex;
  width: 70%;
  height: 80%;

  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

export const ModalContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContainerDelete = styled(ModalBoxWrapper)`
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

export const TextAlert = styled.h1`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 15px;
  padding-inline: 5px 0px;
`;

export const ScrollScheduleList = styled(ScrollWrapper)`
  border: 1px solid ${({ theme }) => theme.gray};
  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    height: 80vh;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    height: 60vh;
  }
`;

export const CardInfoClinic = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`;
