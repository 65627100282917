import React from "react";

export function MeetingDetailsScreen({ onClickJoin }) {
  return (
    <div className={`w-full absolute bottom-0 md:flex md:mt-3 md:relative`}>
      <button
        type="button"
        className="w-full text-white px-2 py-3 rounded-xl bg-[#0C1B5C] text-lg font-bold cursor-pointer hover:bg-[#426AFF]"
        onClick={onClickJoin}
      >
        Entrar na Reunião
      </button>
    </div>
  );
}
