import { MeetingProvider } from '@videosdk.live/react-sdk';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { JoiningScreen } from '../../components/ScreensSDK/JoiningScreen';
import { LeaveScreen } from '../../components/ScreensSDK/LeaveScreen';
import { MeetingAppProvider } from '../../providers/MeetingAppContext';
import './index.css';
import { MeetingContainer } from './Meeting/MeetingContainer';

export function VideoSDK({ ...props }) {
  const [token, setToken] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(false);
  const [customAudioStream, setCustomAudioStream] = useState(null);
  const [customVideoStream, setCustomVideoStream] = useState(null);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const {
    location: { state },
  } = props;
  const { schedule } = state;

  const meetingId = useMemo(
    () => schedule?.videoSkdRoom?.roomId || '',
    [schedule]
  );

  const isMobile = window.matchMedia(
    'only screen and (max-width: 768px)'
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return 'Tem certeza de que deseja sair?';
      };
    }
  }, [isMobile]);

  return (
    <Fragment>
      <MeetingAppProvider>
        {isMeetingStarted ? (
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : 'TestUser',
              multiStream: true,
              customCameraVideoTrack: customVideoStream,
              customMicrophoneAudioTrack: customAudioStream,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken('');
                setParticipantName('');
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
            />
          </MeetingProvider>
        ) : isMeetingLeft ? (
          <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
        ) : (
          <JoiningScreen
            participantName={participantName}
            setParticipantName={setParticipantName}
            setToken={setToken}
            micOn={micOn}
            nvm
            setMicOn={setMicOn}
            webcamOn={webcamOn}
            setWebcamOn={setWebcamOn}
            customAudioStream={customAudioStream}
            setCustomAudioStream={setCustomAudioStream}
            customVideoStream={customVideoStream}
            setCustomVideoStream={setCustomVideoStream}
            onClickStartMeeting={() => {
              setMeetingStarted(true);
            }}
            startMeeting={isMeetingStarted}
            setIsMeetingLeft={setIsMeetingLeft}
          />
        )}
      </MeetingAppProvider>
    </Fragment>
  );
}
