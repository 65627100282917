import React from "react";
import { PtBr } from "../../../services/pt_br";
import handleErrors from "../../../utils/getValuesErrors";
import FormikControl from "../../FormikControl";
import { ButtonInvite, Main } from "../styles";

export default function InviteForm({ form, type }) {
  const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
    form;

  const FieldValues = ["city", "email", "message", "name", "phone"];

  function onSubmit() {
    if (!handleErrors(errors, FieldValues)) {
      handleSubmit(values);
    }
  }

  return (
    <Main>
      <section className="--input-wrapper-invite">
        <h3 style={{ color: "#fff", marginBottom: "1rem" }}>
          {`Convide um novo ${
            type === "professional" ? "profissional" : "cliente"
          }`}
        </h3>

        <p style={{ color: "#fff", marginBottom: "1rem" }}>
          {`Preencha os dados do ${
            type === "professional" ? "profissional" : "cliente"
          } que você quer convidar para a plataforma`}
        </p>

        <FormikControl
          autoFocus
          bgColor="#fff"
          color="#fff"
          control="input"
          errorStyle={{ color: "#fff" }}
          errors={errors.name}
          label={PtBr.register_input_firstname}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.name}
          upLabel
          value={values.name}
        />

        <FormikControl
          bgColor="#fff"
          color="#fff"
          control="input"
          errorStyle={{ color: "#fff" }}
          errors={errors.email}
          label={PtBr.register_input_email}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.email}
          upLabel
          value={values.email}
        />

        <FormikControl
          bgColor="#fff"
          color="#fff"
          control="input"
          errorStyle={{ color: "#fff" }}
          errors={errors.phone}
          label={PtBr.register_input_phone}
          mask="(99) 99999-9999"
          name="phone"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.phone}
          upLabel
          value={values.phone}
        />

        <FormikControl
          bgColor="#fff"
          color="#fff"
          control="input"
          errorStyle={{ color: "#fff" }}
          errors={errors.city}
          label={PtBr.register_input_city}
          name="city"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.city}
          upLabel
          value={values.city}
        />

        <FormikControl
          bgColor="#fff"
          color="#fff"
          control="textarea"
          errorStyle={{ color: "#fff" }}
          errors={errors.message}
          label="Mensagem"
          name="message"
          onBlur={handleBlur}
          onChange={handleChange}
          rows={5}
          touched={touched.message}
          upLabel
          value={values.message}
        />

        <ButtonInvite onClick={onSubmit} type="button">{`Convidar ${
          type === "professional" ? "profissional" : "cliente"
        }`}</ButtonInvite>
      </section>
    </Main>
  );
}
