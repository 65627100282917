import styled from 'styled-components';
import {
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  ScrollWrapper,
} from '../../styles/globalStyles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerSection = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerContent = styled(ScrollWrapper)`
  display: flex;
  width: 100%;
  padding: 0 0.5rem;
`;

export const ContainerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1.5rem;
  width: 100%;
`;

export const ContentWrapper = styled.div``;

export const BoxSection = styled.button`
  background-color: ${({ selected, theme }) =>
    selected ? theme.secondary : theme.white};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.secondary};
  cursor: pointer;
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  position: relative;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-bottom: 0.325rem;
    margin-right: 1rem;
  }
`;

export const TextLabel = styled(BigFont)`
  color: ${({ theme, selected }) => (selected ? theme.white : theme.secondary)};
  font-size: 14px;
  font-size: medium;
  font-weight: 200px;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    font-size: 12px;
  }
`;

export const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 1fr));
  grid-gap: 2rem;
  padding-top: 1rem;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
`;
