import { Grid, Modal } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import {
  BoxWrapper,
  CancelButton,
  LeftArrowIcon,
  Main,
  RightArrowIcon,
  SaveButton,
} from "./styles";

export function FooterButtons({ ...props }) {
  const {
    goBack,
    next,
    disableGoBack = false,
    disableNext = false,
    forgetPassword,
    maxWidth,
  } = props;

  return (
    <Main forgetPassword={forgetPassword} maxWidth={maxWidth}>
      {(disableGoBack === false || disableGoBack === null) && (
        <div>
          <div className="-left" onClick={goBack}>
            <LeftArrowIcon />
          </div>
        </div>
      )}

      {(disableNext === false || disableNext === null) && (
        <div className="-right" onClick={next}>
          <RightArrowIcon />
        </div>
      )}
    </Main>
  );
}

export function SaveButtons({ ...props }) {
  const {
    goBack,
    next,
    cancelText,
    nextText,
    deleteButton,
    warning = false,
  } = props;
  const theme = useTheme();
  const [warn, setWarn] = useState(false);

  const checkWarning = () => {
    if (warning === true) {
      setWarn(true);
    } else {
      goBack();
    }
  };

  return (
    <Main>
      <CancelButton
        title={cancelText || "Cancelar"}
        onClick={checkWarning}
        brdColor={theme.primary}
      />

      <div>
        {warning === true && warn === true && (
          <Modal open>
            <BoxWrapper>
              <Typography variant="h6" sx={{ textAlign: "center", mb: 1 }}>
                {" "}
                As alterações serão perdidas, deseja continuar?{" "}
              </Typography>

              <Grid
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setWarn(false);
                  }}
                  size="small"
                  variant="contained"
                >
                  Não
                </Button>
                <Button
                  sx={{ m: 1 }}
                  onClick={goBack}
                  size="small"
                  variant="contained"
                >
                  Sim
                </Button>
              </Grid>
            </BoxWrapper>
          </Modal>
        )}
      </div>

      <SaveButton
        title={nextText || (deleteButton ? "Apagar" : "Salvar")}
        onClick={next}
        reverse
        buttonColor={deleteButton && "#FF3F56"}
        width="48%"
      />
    </Main>
  );
}
