/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormikControl from '../../../components/FormikControl';
import LoaderSpinner from '../../../components/LoaderSpinner';
import api from '../../../services/api';
import {
  ButtonWrapper,
  ContinueButton,
  Hr,
  InputContainer,
  ReviewContainer,
  ThumbDownIcon,
  ThumbsButton,
  ThumbsButtonContainer,
  ThumbsContainer,
  ThumbsTitle,
  ThumbUpIcon,
} from './styles';

export default function ReviewProfessional({ ...props }) {
  const { eventId } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [evaluateId] = useState(id || eventId);
  const schema = Yup.object({
    anonymous: Yup.boolean().required('Campo requerido para envio.'),
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchSchedule = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/clients/schedules/${evaluateId}`, {
          signal: controller.signal,
        });
        if (data.data) {
          setSchedule(data.data.attributes);
        } else {
          throw Error('Agendamento vazio');
        }
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível encontrar o agendamento escolhido');
      } finally {
        setLoading(false);
      }
    };
    fetchSchedule();

    return () => controller.abort();
  }, [evaluateId]);

  const handleContinue = async (values) => {
    try {
      setLoading(true);
      await api.post(
        `/clients/professionals/${schedule?.professional?.id}/reviews`,
        {
          data: { attributes: { ...values, scheduleId: evaluateId } },
        }
      );
      setLoading(false);
      toast.success('Avaliação cadastrada');
    } catch (error) {
      setLoading(false);
      toast.error('Não foi possivel avaliar esse profissional');
    }
  };
  return loading ? (
    <LoaderSpinner />
  ) : (
    <Formik
      initialValues={{
        liked: null,
        comment: '',
        anonymous: false,
      }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleContinue(values);
        }, 400);
      }}
    >
      {({ values, setValues, handleChange, handleSubmit, handleBlur }) => (
        <ReviewContainer>
          <ThumbsContainer>
            <ThumbsTitle>O que achou do especialista?</ThumbsTitle>
            <ThumbsButtonContainer>
              <ThumbsButton
                checked={values.liked === true}
                onClick={() => setValues({ ...values, liked: true })}
              >
                <ThumbUpIcon />
              </ThumbsButton>
              <ThumbsButton
                checked={values.liked === false}
                onClick={() => setValues({ ...values, liked: false })}
              >
                <ThumbDownIcon />
              </ThumbsButton>
            </ThumbsButtonContainer>
          </ThumbsContainer>
          <InputContainer>
            <FormikControl
              style={{ width: '100%', backgroundColor: '#fff' }}
              rows={5}
              control="textarea"
              name="comment"
              onChange={handleChange}
              onBluer={handleBlur}
              label="Deixe seu comentário"
              placeholder="Escreva sobre a sua experiência durante o atendimento."
            />
            <FormikControl
              control="checkbox"
              name="anonymous"
              label="Quero que a avaliação seja anônima"
            />
          </InputContainer>
          <Hr />
          <ButtonWrapper>
            <ContinueButton onClick={() => handleSubmit()} title="Enviar" />
          </ButtonWrapper>
        </ReviewContainer>
      )}
    </Formik>
  );
}
