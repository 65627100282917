import React from 'react';
import {
  RowLine,
  SyledRange,
} from '../../../../components/FormikControl/FormikComponents/styles';
import { SelectList, SelectModal } from '../../../../components/SelectList';
import {
  CheckBox,
  CheckMark,
  InputContainer,
  InputText,
  OptionButton,
  OptionButtonGrid,
  OptionCheckBoxGrid,
} from './styles';

export default function handleTypeComponent(type, props) {
  switch (type) {
    case 'input':
      return <InputContainer className="--filter-input" {...props} />;
    case 'text':
      return <InputText className="--filter-input" {...props} />;
    case 'buttons':
      return (
        <OptionButtonGrid>
          {props?.options?.map((button) => (
            <OptionButton
              key={button.label}
              type="button"
              checked={props.checked(button)}
              onClick={() => props.onClick(button)}
            >
              <label>{button.label}</label>
            </OptionButton>
          ))}
        </OptionButtonGrid>
      );

    case 'checkbox':
      return (
        <OptionCheckBoxGrid secondary={props?.row}>
          {props?.options?.map((button) => (
            <CheckBox
              key={button.label}
              checked={props.checked(button)}
              onClick={() => props.onClick(button)}
              disabled={props.disabled}
            >
              <div>
                <CheckMark checked={props.checked(button)} />
              </div>
              <label>{button.label ? button.label : button}</label>
            </CheckBox>
          ))}
        </OptionCheckBoxGrid>
      );

    case 'range':
      return (
        <RowLine>
          <SyledRange
            type="range"
            name={props.name}
            max={props.max}
            value={props.value}
            id={props.name}
            {...props}
          />
          <label>{`${props.beforeValue} ${props.value},00`}</label>
        </RowLine>
      );
    case 'select':
      return <SelectList {...props} />;
    case 'selectMult':
      return (
        <OptionCheckBoxGrid secondary={props?.row}>
          {props?.options?.map(({ name, value }) => (
            <CheckBox
              key={value}
              checked={props.checked(value)}
              onClick={() => props.onClick(value, name)}
            >
              <div>
                <CheckMark checked={props.checked(value)} />
              </div>
              <label>{name}</label>
            </CheckBox>
          ))}
        </OptionCheckBoxGrid>
      );
    case 'selectModal':
      return <SelectModal {...props} />;
    default:
      return null;
  }
}
