import ReactPixel  from 'react-facebook-pixel'

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const facebookPixelId = "1518592121968469";

export function pageViewEvent() {
  ReactPixel.init(facebookPixelId, options);
  ReactPixel.pageView();
}