import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from 'styled-components';
import {
  AnimationWrapper,
  BREAKPOINT_LG_PX,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BigFont,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
  ModalBoxWrapper,
} from '../../../styles/globalStyles';

export const BodyContainer = styled(AnimationWrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MenuHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem 1rem 2rem;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ButtonsContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
    max-width: 350px;
  }
`;

export const CalendarButton = styled.button`
  border: none;
  display: flex;
  padding: 5px;
  margin: 0 5px;
  background-color: ${({ theme }) => theme.newPrimary};
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  justify-content: space-between;
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.black};
`;

export const ArrowRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.black};
`;

export const DateInfo = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const InfoMonth = styled.h1`
  font-weight: 900;
  color: ${({ theme }) => theme.black};
`;

export const InfoDay = styled.h1`
  font-weight: 400;
  color: ${({ theme }) => theme.secondary};
`;

export const CalendarContainer = styled.section`
  align-items: flex-start;
  column-gap: 0.7rem;
  display: grid;
  grid-template-columns: repeat(${CALENDAR_COLUMN_XL}, 1fr);
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_LG_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_LG}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_MD}, 1fr);
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(${CALENDAR_COLUMN_SM}, 1fr);
  }
`;

export const WeekItemList = styled.section`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.darkBlue : theme.white};
  border-radius: ${({ current }) => (current === 'true' ? '0.325rem' : '0')};
  align-items: ${({ active }) => (active === 'true' ? 'stretch' : 'center')};
  display: flex;
  flex-direction: column;
  justify-content: ${({ active }) =>
    active === 'true' ? 'center' : 'flex-start'};
  max-width: 350px;
  padding: ${({ current }) =>
    current === 'true' ? '0 0.5rem 0.5rem 0.5rem' : '0'};
  width: 100%;
`;

export const WeekList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    margin-top: 1rem;
  }
`;

export const WeekText = styled(BigFont)`
  background-color: ${({ theme, current }) =>
    current === 'true' ? theme.darkBlue : 'transparent'};
  color: ${({ theme, current }) =>
    current === 'true' ? theme.white : theme.black};
  font-weight: ${({ active }) => (active === 'true' ? '700' : '400')};
  padding: ${({ active }) => (active === 'true' ? '0' : '25px 25px 0 10px')};
  text-align: center;
  width: 100%;
`;

export const DefaultContainer = styled.div`
  width: 100%;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.primaryAlt};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerSchedule = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 13px;
  padding: 10px;
  width: 100%;
`;

export const TextSchedule = styled.div`
  color: white;
  font-weight: ${({ primary }) => (primary ? '700' : '400')};
  font-size: ${({ primary }) => (primary ? '1.2rem' : '1rem')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const IconsContainer = styled.div`
  margin-top: 11px;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
`;

export const IconContainer = styled.button`
  cursor: pointer;
  width: 100%;
  border: 1px solid #999ebc;
  border-radius: 0.5rem;
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledList = styled.ul`
  border: 1px solid ${({ theme }) => theme.primaryAlt};
  border-radius: 0.5rem;
  margin: 1rem 0;
  width: 100%;

  li {
    border: 1px solid ${({ theme }) => theme.primaryAlt};
    list-style-type: none;
    padding: 0.5rem 0.25rem;
  }

  .--title {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .--content {
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const WrapperList = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const BoxWrapper = styled(ModalBoxWrapper)`
  width: 600px;
  padding: 2rem;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0.5rem;
    width: 85%;
  }
`;

export const ContainerImg = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 0.5rem;
  width: 100%;
`;
