import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAuth from '../../../hooks/auth';
import api from '../../../services/api';
import isSameBankAccount from '../../../utils/isSameBankAccount';
import BankAccount from '../../Register/BankAccount';

export function addLeftZeros(num, len) {
  let numberWithZeroes = String(num);
  let counter = numberWithZeroes?.length;

  while (counter < len) {
    numberWithZeroes = '0' + numberWithZeroes;

    counter++;
  }

  return numberWithZeroes;
}

export default function BankAccountForm() {
  const { setAuthData, user } = useAuth();
  const history = useHistory();
  const schema = Yup.object({
    bankCode: Yup.string().required('Banco é obrigatório'),
    bankAg: Yup.string().required('Agência do conta é obrigatório'),
    agenciaDv: Yup.string(),
    bankCc: Yup.string().required('Número da conta é obrigatório'),
    contaDv: Yup.string(),
    accountType: Yup.string().required('Tipo da conta é obrigatório'),
    name: Yup.string().required('Nome Completo é obrigatório'),
  });

  const handleValues = async (values) => {
    try {
      if (!isSameBankAccount(values, user.paymentAccount)) {
        const request = {
          attributes: {
            ...values,
            agenciaDv: values.agenciaDv || null,
          },
        };

        delete request.attributes['bankName'];
        request.attributes.bankCode = addLeftZeros(values.bankCode, 3);
        if (user.paymentAccount.id) {
          const { data: account } = await api.put(
            `/professionals/payment_accounts/${user.paymentAccount.id}`,
            {
              data: request,
            }
          );
          let newUser = user;
          newUser.paymentAccount = {
            ...values,
            id: account.data.id,
            professionalId: user?.id,
          };
          setAuthData(newUser);
        } else {
          const { data: account } = await api.post(
            `/professionals/payment_accounts`,
            {
              data: request,
            }
          );
          let newUser = user;
          newUser.paymentAccount = {
            ...values,
            id: account.data.id,
            professionalId: user?.id,
          };
          setAuthData(newUser);
        }

        toast.success('Conta cadastrada com sucesso!');
        history.push('/professional/dashboard');
      } else {
        toast.error(
          'Os dados informados são os mesmos da sua conta já cadastrada'
        );
      }
    } catch (error) {
      toast.error('Erro ao cadastrar valores!');
    }
  };

  function cancelForm() {
    history.push('/professional/update');
  }

  const form = useFormik({
    initialValues: {
      name: user.paymentAccount.name || '',
      bankAg: user.paymentAccount.bankAg || '',
      accountType: user.paymentAccount.accountType || '',
      bankCc: user.paymentAccount.bankCc || '',
      bankCode: user.paymentAccount.bankCode || '',
      agenciaDv: user.paymentAccount.agenciaDv || '',
      contaDv: user.paymentAccount.contaDv || '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        handleValues(values);
      }, 400);
    },
  });

  const { handleSubmit } = form;
  return (
    <FormikProvider value={form}>
      <BankAccount update form={form} next={handleSubmit} goBack={cancelForm} />
    </FormikProvider>
  );
}
