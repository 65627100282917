import styled from 'styled-components';
import { ReactComponent as Empty } from '../../../images/msgEmpty.svg';
import {
  AnimationWrapper,
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
} from '../../../styles/globalStyles';

export const Main = styled(AnimationWrapper)`
  align-items: flex-start;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1.3fr 2fr;
  justify-items: center;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    justify-items: center;
    row-gap: 2rem;
  }
`;

export const ListContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MessageContainer = styled.section`
  align-items: center;
  align-self: flex-start;
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.color10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
  width: 100%;
`;

export const EmptyImage = styled(Empty)`
  height: 250px;
  padding: 30px 0;
`;

export const ButtonWrapper = styled.section`
  display: flex;
  width: 200px;
  padding: 10px 0;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
`;
