import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { FooterButtons } from '../../../components/FooterButtons';
import PaymentForm from '../../../components/FormikElements/PaymentForm';
import {
  ButtonWrapper,
  CheckBoxWrapper,
  InputWrapper,
  ItemContent,
  ItemDescription,
  ItemName,
  ItemPrice,
  LinkWrapper,
  RowItem,
} from '../../../components/FormikElements/styles';
import { PtBr } from '../../../services/pt_br';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import { LoginTitle } from '../../Public/Login/styles';
import { LabelInput } from '../PublicProfile/styles';
import { MainWrapper, SubscribeCardWrapper, SubscribeForm } from './styles';
import { MedalIcon } from '../../../styles/globalStyles';

export function SubscribeCard({ ...props }) {
  const { item, values, founder } = props;
  const theme = useTheme();

  return (
    <CheckBoxWrapper
      full={values.planName === (item.value?.data || item.title)}
      {...props}
    >
      <ButtonWrapper
        type="button"
        full={values.planName === item.value?.data}
        {...props}
      >
        <ItemContent>
          <ItemName full={values.planName === item.value?.data}>
            {item.title}
          </ItemName>
          {item?.value && (
            <ItemPrice full={values.planName === item.value?.data}>
              {convertCurrencyPtBr(item.value.price) +
                ' /' +
                item.value.unidade}
            </ItemPrice>
          )}
          {founder && (
            <div
              style={{
                marginTop: '10px',
                marginBottom: '-10px',
                color: theme.darkGray,
              }}
            >
              Após 3 meses gratuitos
            </div>
          )}
        </ItemContent>
      </ButtonWrapper>
      <LinkWrapper
        full={values.planName === item.value?.data}
        href="https://allminds.app/para-especialista#planos"
        target="_blank"
      >
        saiba mais
      </LinkWrapper>
    </CheckBoxWrapper>
  );
}

export function SubscribeFormik({ form, handleContinue, goBack, founder }) {
  const theme = useTheme();

  const handleValues = (values) => {
    values.cardNumber = values.cardNumber.replace(/\s+/g, '');
    values.cardNumber = values.cardNumber.replace(/_/g, '');
    values.cardExpiration_date = values.cardExpiration_date.replace(/[/]/g, '');
    values.cardExpiration_date = values.cardExpiration_date.replace(/_/g, '');
    handleContinue(values);
  };

  const { values, setFieldValue } = form;

  return (
    <Fragment>
      <LoginTitle>Planos de assinatura</LoginTitle>
      <MainWrapper>
        <SubscribeForm>
          <LabelInput>{PtBr.register_input_subscribe}</LabelInput>
          <RowItem>
            {founder
              ? PtBr.register_input_subscribe_methods_founder?.map((item) => (
                  <SubscribeCardWrapper key={item.title}>
                    <SubscribeCard
                      item={item}
                      color={theme.offWhite}
                      activedColor={theme.primary}
                      values={values}
                      founder={founder}
                      onClick={() =>
                        setFieldValue('planName', item.value?.data)
                      }
                    />
                  </SubscribeCardWrapper>
                ))
              : PtBr.register_input_subscribe_methods?.map((item) => (
                  <SubscribeCardWrapper key={item.title}>
                    <SubscribeCard
                      item={item}
                      color={theme.offWhite}
                      activedColor={theme.primary}
                      values={values}
                      onClick={() =>
                        setFieldValue('planName', item.value?.data)
                      }
                    />
                  </SubscribeCardWrapper>
                ))}
          </RowItem>
          <ItemDescription>
            <MedalIcon
              style={{
                color: theme.white,
                backgroundColor: theme.white,
              }}
            />{' '}
            Um mês de teste grátis
          </ItemDescription>
          {values.planName ===
            PtBr.register_input_subscribe_free_trial.value.data || (
            <Fragment>
              <LabelInput>{PtBr.register_input_payment.title}</LabelInput>
              <PaymentForm form={form} subscription={values.planName} />
            </Fragment>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FooterButtons
              goBack={goBack}
              next={() => handleValues(form.values)}
            />
          </div>
        </SubscribeForm>
      </MainWrapper>
    </Fragment>
  );
}

export default function SubscribeScreen({ form, next, goBack }) {
  const handleContinue = async (values) => {
    try {
      const { planName, cardNumber, cardCvv, cardExpiration_date } = values;
      if (
        (planName === 'gold' || planName === 'platinum') &&
        (cardNumber?.length < 16 ||
          cardCvv?.length < 3 ||
          cardExpiration_date?.length < 4)
      ) {
        toast.error('Dados do cartão são requeridos.');
      } else if (!values.planName) {
        toast.error('Selecione um plano para continuar.');
      } else {
        next();
      }
    } catch (error) {
      toast.error('Não foi possível completar sua assinatura');
    }
  };

  return (
    <InputWrapper>
      <SubscribeFormik
        goBack={goBack}
        form={form}
        handleContinue={handleContinue}
      />
    </InputWrapper>
  );
}
