import { ContentPasteSearch } from '@mui/icons-material';
import styled from 'styled-components';
import { BREAKPOINT_MD_PX, SmallFont } from '../../styles/globalStyles';

export const Container = styled.form`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.ExtralightGray};

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    width: 100%;
  }
`;
export const DefaultInputContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled(InputContainer)`
  padding: 30px 0 10px 0;
  border-bottom: none;
  justify-content: flex-start;
`;

export const InputText = styled.input`
  flex: 1;
  height: 40px;
  border: none;
  display: flex;
  font-size: 18px;
  line-height: 27px;
  background-color: transparent;
`;

export const SearchIcon = styled(ContentPasteSearch)`
  margin-right: 0.7rem;
  color: ${({ theme, bdcolor }) => bdcolor || theme.DarkGray};
`;

export const Buttons = styled.button`
  border: none;
  display: flex;
  padding: 10px 15px;
  margin-right: 5px;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary : theme.secondary};

  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.primaryAlt};
`;

export const ButtonTitle = styled(SmallFont)`
  color: ${({ theme }) => theme.primaryAlt};
`;

export const DefaultContainer = styled.form`
  align-items: center;
  background-color: ${({ theme }) => theme.newPrimary};
  border-radius: 0.312rem;
  border: 1px solid ${({ theme, bdcolor }) => bdcolor || theme.color10};
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: flex-start;
  padding: 0 0.7rem 0 0.7rem;
  width: 100%;
`;
