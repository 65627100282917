import styled from "styled-components";
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BigFont,
  SmallFont,
} from "../../../../styles/globalStyles";

export const PaymentContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const MainWrapper = styled.section`
  width: 400px;
  display: flex;
  padding-top: 20px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 280px;
  }
`;

export const HeaderWrapper = styled.section`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.color11};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
`;

export const WrapperTitle = styled(SmallFont)`
  align-items: center;
  color: ${({ theme }) => theme.black};
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 1rem;
  padding-bottom: 10px;
  width: 100%;
`;

export const WrapperSubTitle = styled(WrapperTitle)`
  font-size: 1rem;
  margin-top: 1rem;
`;

export const SMFontGray = styled(SmallFont)`
  text-align: left;
  color: ${({ theme }) => theme.darkGray};
`;

export const BodyWrapper = styled.section`
  align-items: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const FlexEndContainer = styled.section`
  width: 100%;
  display: flex;
  padding: 5px 0;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FlexEndText = styled(SmallFont)`
  color: ${({ theme }) => theme.darkGray};
`;

export const FlexEndTextPrimary = styled(SmallFont)`
  color: ${({ theme }) => theme.primary};
`;

export const PaymentMethod = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const WeeklyAppointmentTimes = styled(PaymentMethod)``;

export const CardButton = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary : theme.color10};
  border-radius: 5px;
  color: ${({ theme, checked }) =>
    checked ? theme.offWhite : theme.secondary};
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  line-height: 150%;
  width: 100%;
`;

export const PaymentWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    height: auto;
  }
`;

export const PaymentCreditCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.newSecondary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  padding: 2rem;
  width: 100%;
  max-height: ${({ pix }) => (pix === "true" ? "15rem" : "100%")};
`;

export const HeaderWrapperResum = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.newSecondary};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  min-height: 25rem;
  max-height: 25rem;
  width: 100%;
`;

export const WrapperTitleResum = styled(BigFont)`
  color: ${({ theme }) => theme.black};
  font-size: 1.3rem;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: left;
`;

export const SMFontBlack = styled(SmallFont)`
  text-align: left;
  color: ${({ theme }) => theme.black};
`;

export const FlexStartText = styled(SmallFont)`
  color: ${({ theme }) => theme.black};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`;

export const FlexStartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px 0;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
`;

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color12};
  margin: 1.5rem 0;
  width: 100%;
`;

export const ButtonCoupon = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.secondary_blue};
  color: ${({ theme }) => theme.secondary_blue};
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2.5rem;
  justify-content: center;
  padding: 0.7rem 0.5rem;
  width: 50%;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const ContainerCardPayment = styled.div`
  width: 100%;
  height: 40vh;
  overflow-y: scroll;
`;

export const ButtonAddCreditCard = styled.button`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme, secondary }) => secondary && theme.primary_blue};
  border-radius: 2rem;
  color: ${({ theme }) => theme.primary_blue};
  font-weight: 700;
  height: 2.3rem;
  margin: ${({ secondary }) => (secondary ? "2rem 0 0 0" : "0 2.5rem")};
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    margin: ${({ secondary }) => (secondary ? "2rem 0 0 0" : "0 1.5rem")};
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;

export const WrapperPayment = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const WrapperPaymentPix = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const CardButtonMethod = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.secondary_blue : theme.white};
  border-radius: 2rem;
  color: ${({ theme, checked }) =>
    checked ? theme.white : theme.secondary_blue};
  display: flex;
  font-size: 0.8rem;
  height: 2.5rem;
  font-weight: 700;
  justify-content: center;
  width: 40%;
  margin: 0 1rem;
`;

export const PaymentButton = styled.button`
  align-items: center;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primary_blue : theme.white};
  border-radius: 2rem;
  color: ${({ theme, checked }) =>
    checked ? theme.white : theme.primary_blue};
  display: flex;
  font-size: 0.8rem;
  height: 2.5rem;
  font-weight: 700;
  justify-content: center;
  width: 60%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    width: 70%;
  }

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    width: 100%;
  }
`;
