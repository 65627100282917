import styled from 'styled-components';
import { ModalBoxWrapper, ScrollPageStyle } from '../../../styles/globalStyles';

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const InputWrapper = styled(ScrollPageStyle)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: flex-start;
  margin-top: 3rem;
  width: 80%;
`;

export const BoxWrapper = styled(ModalBoxWrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;
  padding: 2rem;
`;

export const TermContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ primary }) => (primary ? '#eaecf4' : '#ced0db')};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999ebc;
    border-radius: 5px;
    height: 2.5rem;
  }
`;

export const ButtonWrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.black};
  font-family: 'Poppins';
  font-size: 1.5rem;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
`;

export const IconArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
`;

export const Icon = styled.img`
  height: 2rem;
  width: 2rem;
`;
