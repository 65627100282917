/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flatlist } from '../../../components/List';
import { MessageItemList } from '../../../components/ListItens';
import MessageChat from '../../../components/MessageElements';
import useAuth from '../../../hooks/auth';
import api from '../../../services/api';
import abortControllerTimeOut from '../../../utils/abortControllerTimeOut';
import {
  EmptyContainer,
  EmptyText,
  EmptyTitle,
} from '../../Client/EletronicDiary/styles';
import { EmptyImage, ListContainer, Main, MessageContainer } from './styles';

export default function MessageList({ ...props }) {
  const {
    location: { state },
  } = props;
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPatients = async () => {
      try {
        const { data: lastData } = await api.get(
          `/${user?.type}s/last_messages`,
          {
            signal: abortControllerTimeOut(20000),
          }
        );
        const { data } = await api.get(`/${user?.type}s/messages`, {
          signal: abortControllerTimeOut(20000),
        });
        const sortMessages = [...data.data, ...lastData.data].sort((a, b) => {
          if (
            a.attributes.lastMessage.createdAt <
            b.attributes.lastMessage.createdAt
          )
            return 1;
          if (
            a.attributes.lastMessage.createdAt >
            b.attributes.lastMessage.createdAt
          )
            return -1;
          return 0;
        });
        setMessages(sortMessages);
      } catch (error) {
        toast.error('Não foi possível carregar seu histórico de mensagens');
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();

    const getNotificationsMessage = async () => {
      try {
        const { data } = await api.get(`/${user.type}s/notifications`, {
          signal: controller.signal,
        });
        setNotifications(
          data.data?.filter(
            (item) =>
              item.attributes?.notificationType === 'message' &&
              item.attributes?.readed === null
          )
        );
      } catch (error) {
        if (!controller.signal.aborted)
          toast.error('Não foi possível listar as notificações');
      }
    };
    getNotificationsMessage();

    const intervalId = setInterval(() => {
      getNotificationsMessage();
    }, 30000);

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, []);

  return (
    <Main>
      <ListContainer>
        <Flatlist
          type="message"
          data={messages}
          filterObject={(item) => item.attributes.client.firstName}
          placeholder="Buscar Contato"
          renderItem={(item, index) => (
            <MessageItemList
              key={index}
              data={item}
              clientPath={state?.client}
              onClick={(user) => setSelected(user)}
              selected={selected}
              notifications={notifications?.filter(
                (notification) =>
                  notification?.attributes?.sender?.id ===
                  item?.attributes?.client?.id
              )}
            />
          )}
          loading={loading}
        />
      </ListContainer>
      <MessageContainer>
        {selected ? (
          <MessageChat myself={user} user={selected} />
        ) : (
          <EmptyContainer>
            <EmptyImage />
            <EmptyTitle>Você verá suas mensagens aqui</EmptyTitle>
            <EmptyText>
              Inicie uma conversa e ela aparecerá desse lado da tela.
            </EmptyText>
          </EmptyContainer>
        )}
      </MessageContainer>
    </Main>
  );
}
