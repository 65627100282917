/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, X } from "react-feather";
import useAuth from "../../../../hooks/auth";
import useEvent from "../../../../hooks/event";
import useWindowSize from "../../../../utils/WindowSize";
import camalize from "../../../../utils/camalize";
import getProfessionName from "../../../../utils/getProfessionName";
import isFilterDataInputCompare from "../../../../utils/isFilterDataInputCompare";
import { FilterContainer } from "../styles";
import handleTypeComponent from "./handleComponents";
import {
  BoxFilter,
  BoxLabel,
  BoxModalWrapper,
  BoxWrapper,
  ButtonFilter,
  ButtonFilterApply,
  ButtonFilterWrapper,
  ButtonWrapper,
  EmptyContainerFilter,
  EmptyFilter,
  FilterWrapper,
  FilterWrapperModal,
  FilterWrapperModalProfession,
  Hr,
  IconArea,
  LabelFilter,
  LabelsWrapper,
  ScrollBar,
} from "./styles";
import { debounce } from "lodash";

function filterArray(array, value) {
  return array?.includes(value)
    ? array?.filter(e => e !== value)
    : [...array, value];
}

function FilterComponent({ ...props }) {
  const { type, label, style, ...rest } = props;
  return (
    <BoxFilter className="--modal-wrapper" style={style}>
      <BoxLabel className="--modal-label">{label}</BoxLabel>
      {handleTypeComponent(type, { ...rest })}
    </BoxFilter>
  );
}

export function ProfessionalFilter({ ...props }) {
  const { user } = useAuth();
  const { onSubmit, professions, loading } = props;
  const { cities, eventFilter, setEventFilter, resetFilters } = useEvent();
  const { width } = useWindowSize();
  const [modalSpecialty, setModalSpecialty] = useState(false);
  const [valuesModal, setValuesModal] = useState(eventFilter);
  const LABEL_WIDTH_SPECIALTY = width <= 1366 ? 1 : 2;

  useEffect(() => {
    setValuesModal({
      ...eventFilter,
      ...valuesModal,
      professions,
    });
  }, [professions]);

  useEffect(() => {
    debounce(() => {
      onSubmit(eventFilter);
    }, 500)();
  }, [eventFilter]);

  const handleClick = professionFilter => {
    setModalSpecialty(false);
    setEventFilter({
      ...eventFilter,
      ...valuesModal,
      professions,
      professionFilter,
    });
  };

  const handleClickModal = value => {
    const professionFilter = filterArray(eventFilter?.professionFilter, value);
    handleClick(professionFilter);
  };

  const hasReasons = professional => {
    if (["psychologist", "professional_advisor"].includes(professional?.name)) {
      return false;
    }
    if (professional?.reasons) {
      if (
        professional?.reasons?.some(item => item === "N/A") ||
        professional?.reasons?.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const hasSpecialities = professional => {
    if (["psychologist", "professional_advisor"].includes(professional)) {
      return false;
    }
    if (professional?.specialities) {
      if (
        professional?.specialities?.some(item => item === "N/A") ||
        professional?.specialities?.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const hasApproaches = professional => {
    if (professional?.approaches) {
      if (
        professional?.approaches?.some(item => item === "N/A") ||
        professional?.approaches?.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const citys = cities?.map(city => ({
    value: city,
    label: camalize(city),
  }));

  return (
    <FilterContainer marginTop={user.type === "clinic" && "0.5rem"}>
      <ButtonWrapper style={{ gridArea: "professionFilter" }}>
        <FilterComponent
          modal
          type="selectMult"
          row={"true"}
          options={professions?.map(prof => ({
            name: getProfessionName(prof.name, "toPt"),
            value: prof.name,
          }))}
          checked={value => eventFilter?.professionFilter?.includes(value)}
          onClick={value => handleClickModal(value)}
          label="Tipo de profissional"
        />
      </ButtonWrapper>

      <FilterComponent
        disabled={loading}
        name="type"
        type="checkbox"
        row={"true"}
        options={[
          { label: "Presencial", value: "in_person" },
          { label: "Online", value: "online" },
        ]}
        checked={value => eventFilter?.sessionType?.includes(value.value)}
        onClick={value =>
          setEventFilter({
            ...eventFilter,
            sessionType: filterArray(eventFilter?.sessionType, value.value),
          })
        }
        label="Modalidade"
        style={{ gridArea: "sessionTypeFilter" }}
      />

      <FilterComponent
        disabled={loading}
        name="type"
        type="range"
        value={eventFilter?.price}
        max={1000}
        beforeValue="R$"
        onChange={value =>
          setEventFilter({ ...eventFilter, price: value.target.value })
        }
        label="Faixa de Preço: Até..."
        style={{ gridArea: "priceFilter" }}
      />

      {eventFilter?.professionFilter?.length > 0 && (
        <ButtonWrapper style={{ gridArea: "specialitiesFilter" }}>
          <BoxLabel>Especialidades</BoxLabel>
          <ButtonFilter selected={eventFilter?.labels?.length > 0}>
            <LabelsWrapper>
              {eventFilter?.labels?.map((label, index) =>
                index < LABEL_WIDTH_SPECIALTY ? (
                  <Tooltip key={index} title={label?.length > 15 ? label : ""}>
                    <LabelFilter>
                      {label?.length > 15 ? `${label.slice(0, 15)}...` : label}
                      <X
                        className="--icon"
                        onClick={() => {
                          const approaches = eventFilter?.approaches.filter(
                            approache => approache !== label,
                          );
                          const reasons = eventFilter?.reasons.filter(
                            reason => reason !== label,
                          );
                          const specialities = eventFilter?.specialities.filter(
                            specialitie => specialitie !== label,
                          );
                          const values = {
                            approaches,
                            reasons,
                            specialities,
                            labels: [
                              ...approaches,
                              ...reasons,
                              ...specialities,
                            ],
                          };
                          setEventFilter({
                            ...eventFilter,
                            ...values,
                          });
                          setValuesModal({
                            ...valuesModal,
                            ...values,
                          });
                        }}
                      />
                    </LabelFilter>
                  </Tooltip>
                ) : null,
              )}
              {eventFilter?.labels?.length > LABEL_WIDTH_SPECIALTY && (
                <Tooltip
                  title={eventFilter?.labels
                    ?.slice(LABEL_WIDTH_SPECIALTY)
                    .join(", ")}
                >
                  <LabelFilter>{`+${
                    eventFilter?.labels?.length - LABEL_WIDTH_SPECIALTY
                  }`}</LabelFilter>
                </Tooltip>
              )}
            </LabelsWrapper>
            {modalSpecialty ? (
              <ChevronUp />
            ) : (
              <ChevronDown
                className="--arrows"
                onClick={() => {
                  eventFilter?.professionFilter?.length > 0 &&
                    setModalSpecialty(true);
                  setValuesModal({ ...eventFilter });
                }}
              />
            )}
          </ButtonFilter>
        </ButtonWrapper>
      )}

      <FilterComponent
        isDisabled={loading}
        isClearable={
          eventFilter?.address === "todas as cidades" ||
          eventFilter?.address === ""
            ? false
            : true
        }
        label="Localidade"
        onChange={value => {
          if (value !== null) {
            setEventFilter({ ...eventFilter, address: value?.value });
          } else {
            setEventFilter({ ...eventFilter, address: "todas as cidades" });
          }
        }}
        name="address"
        options={citys}
        type="select"
        value={{
          label:
            eventFilter?.address === "" ||
            eventFilter?.address === "todas as cidades"
              ? "Todas as Cidades"
              : camalize(eventFilter?.address),
          value:
            eventFilter?.address === ""
              ? "todas as cidades"
              : eventFilter?.address,
        }}
        style={{ gridArea: "addressFilter" }}
      />

      <FilterComponent
        disabled={loading}
        name="type"
        type="input"
        onChange={({ target }) =>
          setEventFilter({ ...eventFilter, search: target.value })
        }
        placeholder="Ex.: João Silva"
        label="Buscar por nome"
        style={{ gridArea: "nameFilter" }}
        value={eventFilter?.search}
      />

      {!isFilterDataInputCompare(eventFilter) && (
        <EmptyContainerFilter>
          <EmptyFilter onClick={resetFilters}>Limpar Filtros</EmptyFilter>
        </EmptyContainerFilter>
      )}

      {modalSpecialty && (
        <Modal open>
          <BoxModalWrapper>
            <IconArea style={{ cursor: "default" }}>
              <X
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                onClick={() => setModalSpecialty(false)}
              />
            </IconArea>
            <ScrollBar speed={0.8} horizontal={false}>
              <BoxWrapper>
                {eventFilter?.professions
                  ?.filter(profession =>
                    eventFilter?.professionFilter?.includes(profession.name),
                  )
                  .map(profession => (
                    <FilterWrapper key={profession.name}>
                      <Typography
                        sx={{
                          fontSize: "2rem",
                          fontWeight: "700",
                          marginBottom: "1rem",
                        }}
                      >
                        {getProfessionName(profession.name, "toPt")}
                      </Typography>
                      <FilterWrapperModalProfession>
                        {hasSpecialities(profession) && (
                          <FilterWrapperModal>
                            <FilterComponent
                              modal
                              type="checkbox"
                              options={profession.specialities}
                              checked={value =>
                                valuesModal?.specialities?.includes(value)
                              }
                              onClick={value => {
                                const specialities = filterArray(
                                  valuesModal?.specialities,
                                  value,
                                );
                                setValuesModal({
                                  ...valuesModal,
                                  specialities,
                                  labels: [
                                    ...specialities,
                                    ...valuesModal?.reasons,
                                    ...valuesModal?.approaches,
                                  ],
                                });
                              }}
                              label={
                                [
                                  "psychologist",
                                  "professional_advisor",
                                ].includes(profession?.name)
                                  ? "Motivo(s) & Especialidade(s)"
                                  : "Especialidade(s)"
                              }
                            />
                          </FilterWrapperModal>
                        )}
                        {hasReasons(profession) && (
                          <FilterWrapperModal>
                            <FilterComponent
                              modal
                              type="checkbox"
                              options={profession.reasons || []}
                              checked={value =>
                                valuesModal?.reasons?.includes(value)
                              }
                              onClick={value => {
                                const reasons = filterArray(
                                  valuesModal?.reasons,
                                  value,
                                );
                                setValuesModal({
                                  ...valuesModal,
                                  reasons,
                                  labels: [
                                    ...reasons,
                                    ...valuesModal?.specialities,
                                    ...valuesModal?.approaches,
                                  ],
                                });
                              }}
                              label={
                                profession.name === "professor"
                                  ? "Matéria(s)"
                                  : "Motivo(s)"
                              }
                            />
                          </FilterWrapperModal>
                        )}
                        {hasApproaches(profession) && (
                          <FilterWrapperModal>
                            <FilterComponent
                              modal
                              type="checkbox"
                              options={profession.approaches || []}
                              checked={value =>
                                valuesModal?.approaches?.includes(value)
                              }
                              onClick={value => {
                                const approaches = filterArray(
                                  valuesModal?.approaches,
                                  value,
                                );
                                setValuesModal({
                                  ...valuesModal,
                                  approaches,
                                  labels: [
                                    ...approaches,
                                    ...valuesModal.reasons,
                                    ...valuesModal.specialities,
                                  ],
                                });
                              }}
                              label="Abordagem(s)"
                            />
                          </FilterWrapperModal>
                        )}
                      </FilterWrapperModalProfession>
                    </FilterWrapper>
                  ))}
              </BoxWrapper>
            </ScrollBar>
            <ButtonFilterWrapper>
              <Hr />
              <ButtonFilterApply
                className="secondary"
                onClick={() => handleClick(eventFilter?.professionFilter)}
              >
                Filtrar
              </ButtonFilterApply>
            </ButtonFilterWrapper>
          </BoxModalWrapper>
        </Modal>
      )}
    </FilterContainer>
  );
}
