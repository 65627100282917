import styled from 'styled-components';
import { BREAKPOINT_SM_PX } from '../../../styles/globalStyles';

export const MainContainer = styled.section`
  width: 90%;
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    padding: 0;
  }
`;
