import React from "react";
import useAuth from "../../../hooks/auth";
import DashboardPlatinum from "./DashboardPlatinum";
import DashboardFree from "./DashboardFree";

export default function Dashboard() {
  const { user } = useAuth();
  const isPlatinum = user.subscription.pagarmePlan === "platinum";
  return isPlatinum ? <DashboardPlatinum /> : <DashboardFree />;
}
