import { ChevronLeft } from "react-feather";
import styled from "styled-components";
import {
  BREAKPOINT_MD_PX,
  BREAKPOINT_SM_PX,
  BREAKPOINT_XL_PX,
  BoxScroll,
} from "../../styles/globalStyles";

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`;

export const ListContainer = styled(BoxScroll)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 77vh;
  justify-content: flex-start;
  padding: 0;
  width: 100%;

  @media only screen and (max-width: ${BREAKPOINT_XL_PX}) {
    height: 70vh;
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SearchWrapper = styled.section`
  flex: 1;
  height: 100%;
  display: flex;
  padding: 50px 0;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
`;

export const LibraryWrapper = styled.section`
  display: grid;
  align-items: center;
  justify-content: center;

  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NotificationWrapper = styled.section`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: ${BREAKPOINT_MD_PX}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${BREAKPOINT_SM_PX}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PostListWrapper = styled.section`
  flex: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const NotiflyListWrapper = styled.section`
  flex: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const LibraryBanner = styled.section`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  background-image: ${({ image }) => `url(${image})`};
`;

export const Button = styled.button`
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.darkGray};
  background-color: ${({ theme }) => theme.color10};
`;

export const ArrowLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.darkGray};
`;

export const Line = styled.hr`
  border: 1px solid ${({ theme }) => theme.color10};
  height: 0.1rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
`;
